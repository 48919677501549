import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import { StyledPageLayout, StyledTab, StyledTabBox } from "../../theme/StyledEle";
import { Breadcrumb } from "rsuite";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import ListServicePlans from "./ListAllServices";
import { useLocation, useNavigate } from "react-router-dom";
import FormDialog from "../../Utils/FormDialog";
import { useUpdateServicePlanMutation } from "../../store/slice/ApiSlice";
import ListServiceRemainder from "./ListRemainder";
import ServiceViewOneTime from "./ServiceViewOneTime";
import RecurrenceViewService from "./RecurrenceServiceView";
import { Box, Tabs } from "@mui/material";
import { ServicePlanTabs } from "../../Utils/Constans";
import PropTypes from "prop-types";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box >{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const ServicePlan = () => {

  const [tabShow, setTabShow] = React.useState("0");         
  const [create, setCreate] = useState("Create Service Plan");  
  const [commonFilter, setCommonFilter] = useState({ create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,});   
  const [tempcommonFilter, setTempcommonFilter] = useState({ create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,}); 
  const [menuUser, setMenuUser] = useState([]); 
  const tabsOpt = useSelector((state) => state.controls.customizer.currentPage.buttonOpt);
  
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [serviceData, setServiceData] = useState();
  const [updateServicePlan, {data:updateServiceData, isLoading:updateServiceLoading, isSuccess:updateServiceSuccess}] = useUpdateServicePlanMutation();
      const isSuccess = useSelector((state) => state.controls.alert);
      const dispatch = useDispatch();
      useEffect(() => {
        if (isSuccess.isSuccess) {
          setTimeout(() => dispatch(snackService([null, null, null])), 6000);
        }
      }, [isSuccess.isSuccess]);

      useEffect(() => {
        if(tabsOpt?.includes(112)){
          setValue(112);
        }
        else if (tabsOpt?.includes(113)){
          setValue(113);
        }
      },[tabsOpt])
      const handleCancel = () => {
          setCreate("Create Service Plan");
        
          // No need to check conditions, always show Tab 1
          setTabShow("0");
        
          // Set the default tab value (Tab 1 is likely index 0)
          setTabValue(0);
        };

        const handleOrgView = (serviceData) => {
          setServiceData(serviceData);
          navigate("#view");
        };
      
        const callfun = () => {
          if (
            "null" !== JSON.parse(localStorage.getItem("menuList")) &&
            null !== JSON.parse(localStorage.getItem("menuList"))
          ) {
            if (JSON.parse(localStorage.getItem("menuList")) !== null) {
              for (
                let i = 0;
                i < JSON.parse(localStorage.getItem("menuList")).length;
                i++
              ) {
                if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
                  for (
                    let j = 0;
                    j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
                    j++
                  ) {
                    if (
                      JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                      "ServicePlan"
                    ) {
                      setMenuUser(
                        JSON.parse(localStorage.getItem("menuList"))[i].sub[j].button
                      );
                      var dataStr = {};
                      dataStr.create = false;
                      dataStr.edit = false;
                      dataStr.delete = false;
                      dataStr.view = false;
                      dataStr.recentActivation = false;
                      dataStr.importfunction = false;
                      dataStr.excel = false;
                      dataStr.pdf = false;
                      dataStr.tab1 = false;
                      dataStr.tab2 = false;
                      dataStr.completeService = false;
                      var strval;
                      strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                        j
                      ].button.split(",");
                      var edit = false;
                      for (var k = 0; k < strval.length; k++) {
                        if (parseInt(strval[k]) === 1) {
                          dataStr.create = true;
                        }
                        if (parseInt(strval[k]) === 2) {
                          dataStr.view = true;
                        }
                        if (parseInt(strval[k]) === 4) {
                          dataStr.edit = true;
                        }
                        if (parseInt(strval[k]) === 3) {
                          dataStr.delete = true;
                        }
                        if (parseInt(strval[k]) === 5) {
                          dataStr.excel = true;
                        }
                        if (parseInt(strval[k]) === 12) {
                          dataStr.completeService = true;
                        }
                        if (parseInt(strval[k]) === 112) {
                          dataStr.tab1 = true;
                        }
                        if (parseInt(strval[k]) === 113) {
                          dataStr.tab2 = true;
                        }
                       
                      }
                      setCommonFilter(dataStr);
                      setTempcommonFilter(dataStr);
                      break;
                    }
                  }
                }
              }
            }
          }

    var displaydefaulttab = 1;

     if (
      dataStr.tab1 === true && dataStr.tab2 === true
    ) 
    {
      setTabShow("1");
      displaydefaulttab = 1;
    } 
    else if (dataStr.tab1 === true) {
      setTabShow("2");
      displaydefaulttab = 1;
    }
    else if (dataStr.tab2 === true) {
      setTabShow("3");
      displaydefaulttab = 1;
    }
    return displaydefaulttab;
  };
  
  const [tabValue, setTabValue] = useState(() => callfun()); 
  


  const handleChange = async (event, newValue) => {
    setValue(newValue);
     }

  return (
          <StyledPageLayout>
            {/* <Breadcrumb mainDiv="Admin" subDiv="Service Plan"></Breadcrumb> */}
<StyledTabBox>

<Tabs
value={value}
onChange={handleChange}
indicatorColor="secondary"
>
  {
    ServicePlanTabs?.map((item) => {
    
      if(tabsOpt?.includes(item.value)){
        
        return( <StyledTab value={item.value} label={item.label}/> )
      } 
    })
  }

</Tabs>

</StyledTabBox>
            <CustomTabPanel value={value} index={112}>
              <ListServicePlans onViewOrg={handleOrgView} menuUser={menuUser} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={113}>
              <ListServiceRemainder/>
            </CustomTabPanel>
                {isSuccess.isSuccess && (
              <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
            )}
         {location.hash == '#view'  &&   <FormDialog title={'View Service'} padding={false} size="md">
                {serviceData.recurrenceType == 1 ? <ServiceViewOneTime data={serviceData}/> : <RecurrenceViewService data={serviceData}/> }
              </FormDialog>
          }
          </StyledPageLayout>


        )
          
        
      
  }

  export default ServicePlan;