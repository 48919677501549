import Service from "./service";

const vehicleServices = {
  createVehicle: (data) => {
    return Service.post("vehicle/createVehicle", data, null);
  },

  listVehicle: (resource) => {
    return Service.post("vehicle/listVehicle", resource, null);
  },
  updateVehicle: (data) => {
    return Service.post("vehicle/updateVehicle", data, null);
  },
  getVehicle: (id) => {
    return Service.post("vehicle/getVehicle", { id });
  },
  deleteVehicle: (id) => {
    return Service.post("vehicle/deleteVehicle", { id });
  },
  getVehicleHistory: function (resource) {
    return Service.post("/vehicle/listVehicleHistory", resource, null);
  },
  checkVinNoExists: function (resource) {
    return Service.post("/vehicle/checkVinNoExists", resource, null);
  },
  chkFleetName: function (resource) {
    return Service.post("/vehicle/chkFleetName", resource, null);
  },
  validateVehicleList: function (data) {
    return Service.post("/vehicle/ValidateExcel", data, null, {
      "Content-Type": "multipart/form-data",
      accept: "*/*",
    });
  },
  uploadVehicleList: function (data) {
    return Service.post(
      "/vehicle/processExcel",
      data,
      null,

      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },
  getVehicleModel: function (data) {
    return Service.post("/vehicle/getVehicleModel", data, null);
  },
  getVehicleType: function (data) {
    return Service.post("/vehicle/getVehTypeByReselId", data, null);
  },
  getVehicleColor: function (data) {
    return Service.post("/vehicle/getVehColorByReselId", data, null);
  },
  getVehicleMake: function (data) {
    return Service.post("/vehicle/getVehMakeByReselId", data, null);
  }
};

export default vehicleServices;
