import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SearchIcon from "@mui/icons-material/Search";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  FormHelperText,
  CardActions,
  CardHeader,
  Box,
  Grid,
  CardContent,
  InputAdornment,
  useTheme,
  FormControlLabel,
} from "@mui/material";
import {
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledFilterSearch,
  StyledFormButtonsRow,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTableTitle,
  StyledTextField,
} from "../../theme/StyledEle";
import DeviceService from "../../services/deviceService";
import DraggableDialog from "../shared/DailogDrag";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { BackDropLoader } from "../shared/StyledLoader";
import {
  useAuditLogMutation,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
} from "../../store/slice/ApiSlice";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import { PulseLoader } from "react-spinners";
import Checkbox from '@mui/material/Checkbox';
import { Typography } from "antd";


function FormPropsTextFields({ deviceToEdit, onCancel }) {
  const [licensekeyLst, setLicensekeyLst] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [devicePlanList, setDevicePlanList] = useState([]);
  const [mfgsitecodeList, setMfgsitecodeList] = useState([]);
  const [partNumberList, setPartNumberList] = useState([]);
  const [deviceModelNameList, setDeviceModelNameList] = useState([]);
  const [deviceFrameWareList, setDeviceFrameWareList] = useState([]);
  const [deviceHardWareList, setDeviceHardWareList] = useState([]);
  const [simPlanStatusList, setSimPlanStatusList] = useState([]);
  const [deviceStatusList, setDeviceStatusList] = useState([]);
  const [deviceSimNumberList, setDeviceSimNumberList] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);
  const [assignedobj, setAssignedobj] = useState({});
  const [circularOpen, setCircularOpen] = React.useState(false);
  const [clickedval, setClickedval] = React.useState(false);
  const [auditLog] = useAuditLogMutation();
  const [obdCheck, setObdCheck] = useState(false);


  const monthList = [
    {
      id: 1,
      title: "Jan",
      value: 1,
    },
    {
      id: 2,
      title: "Feb",
      value: 2,
    },
    {
      id: 3,
      title: "Mar",
      value: 3,
    },
    {
      id: 4,
      title: "Aprl",
      value: 4,
    },
    {
      id: 5,
      title: "May",
      value: 5,
    },
    {
      id: 6,
      title: "June",
      value: 6,
    },
    {
      id: 7,
      title: "July",
      value: 7,
    },
    {
      id: 8,
      title: "Aug",
      value: 8,
    },
    {
      id: 9,
      title: "Sept",
      value: 9,
    },
    {
      id: 10,
      title: "Oct",
      value: 10,
    },
    {
      id: 11,
      title: "Nov",
      value: 11,
    },
    {
      id: 12,
      title: "Dec",
      value: 12,
    },
  ];

  const yearList = [
    {
      id: 24,
      title: "2024",
      value: "2024",
    },
    {
      id: 23,
      title: "2023",
      value: "2023",
    },
    {
      id: 2,
      title: "2022",
      value: "2022",
    },
    {
      id: 3,
      title: "2021",
      value: "2021",
    },
    {
      id: 4,
      title: "2020",
      value: "2020",
    },
    {
      id: 5,
      title: "2019",
      value: "2019",
    },
    {
      id: 6,
      title: "2018",
      value: "2018",
    },
    {
      id: 7,
      title: "2017",
      value: "2017",
    },
    {
      id: 8,
      title: "2016",
      value: "2016",
    },
    {
      id: 9,
      title: "2015",
      value: "2015",
    },
  ];

  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;

  var distanceDisplayUnit = globalJson1?.distanceDisplayUnit;
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues:{
      listofPlans:null,
      licenseKey:null,
      partNumber:null,
      mfgSiteCode:null,
      deviceModel:null,
      devicePlan:null,
      deviceStatus:null,
      fameWare:0,
      hardware:'0',
    }
  });

 

  var setValuearry={};

  useEffect(async () => {
    if (deviceToEdit) {
      if (deviceToEdit.clickedval === "view") {
        setClickedval(true);
      }
      const responseData = (await DeviceService.editOrgById(deviceToEdit.id)).data;
      reset({
        imei: responseData.formData.imei,
        deviceSerialNo: responseData.formData.deviceSerialNo,
      });
      var payload = {};
      payload.resellerId = globalJson1 != null ? globalJson1.resellerId : "-1";
      payload.userid = globalJson1 != null ? globalJson1.id : "-1";
      payload.orgId = globalJson1 != null ? globalJson1.orgId : "-1";
      payload.orgType = globalJson1 != null ? globalJson1.userOrgType : "-1";
      payload.subresellerOrgId =
        globalJson1.userOrgType === 3
          ? globalJson1.orgId
          : globalJson1.userOrgType;
      payload.type = "Edit";
      payload.id = deviceToEdit.id;
      payload.search = "";
      payload.sessionOrgType =
        globalJson1 != null ? globalJson1.userOrgType : "-1";
      payload.createdBy = globalJson1 != null ? globalJson1.id : "-1";
      const deviceSimNumberLists = (
        await DeviceService.getDeviceSimNumberLists(payload)
      ).data;
      setDeviceSimNumberList(deviceSimNumberLists);
      payload.orgId = globalJson1 != null ? globalJson1.orgId : "-1";
      if (
        2 !== globalJson1.userOrgType &&
        1 !== globalJson1.userOrgType &&
        1 !== globalJson1.roleId
      ) {
        payload.orgType = 2;
        payload.sessionOrgType = 2;
        payload.subresellerOrgId = 2;
      }
      var licensekeyList = [];
      var licensekeyListData = [];
      var appendLicenseKey = {};
      appendLicenseKey.title = responseData.formData.licenseDetailName;
      appendLicenseKey.value = responseData.formData.licenseKey;
      licensekeyListData.push(appendLicenseKey);
      licensekeyList = (await DeviceService.getLicenseKeyList(payload)).data;

      setLicensekeyLst([...licensekeyListData, ...licensekeyList]);

      
      const planLists = (await DeviceService.getlistofPlansList(payload)).data;
      setPlanList(planLists);
      const devicePlanLists = (
        await DeviceService.getlistofDevicePlans(payload)
      ).data;
      setDevicePlanList(devicePlanLists);
      const mfgsitecodeLists = (
        await DeviceService.getlistofMFGSiteCode(payload)
      ).data;
      setMfgsitecodeList(mfgsitecodeLists);
      const partNumberLists = (
        await DeviceService.getlistofPartNumberList(payload)
      ).data;
      setPartNumberList(partNumberLists);

      payload.type = "modelname";
      const deviceModelNameLists = (
        await DeviceService.getDeviceModelNameList(payload)
      ).data;
      setDeviceModelNameList(deviceModelNameLists);
      payload.type = "frameware";
      const deviceFrameWareLists = (
        await DeviceService.getDeviceModelNameList(payload)
      ).data;
      setDeviceFrameWareList(deviceFrameWareLists);
      payload.type = "hardware";
      const deviceHardWareLists = (
        await DeviceService.getDeviceModelNameList(payload)
      ).data;
      setDeviceHardWareList(deviceHardWareLists);
      const simPlanStatusLists = (
        await DeviceService.getSimPlanStatusList(payload)
      ).data;
      setSimPlanStatusList(simPlanStatusLists);
      const deviceStatusLists = (
        await DeviceService.getDeviceStatusList(payload)
      ).data;
      
      setDeviceStatusList(deviceStatusLists);
      getSimDetails(deviceToEdit.simId);

       //Reset data
       const editReset = {
        deviceStatus:responseData.formData.deviceStatus, 
        devicePlan: responseData.formData.devicePlan,
        mfgSiteCode:responseData.formData.mfgSiteCode,
        listofPlans: responseData.formData.listofPlans,
        partNumber:responseData.formData.partNumber,
        deviceModel:responseData.formData.deviceModel,
        licenseKey:responseData.formData.licenseKey,
        fameWare:responseData.formData.fameWare,
        hardware:responseData.formData.hardware,
      }
      reset(editReset);
    } else {
      var payload1 = {};
      payload1.resellerId = globalJson1 != null ? globalJson1.resellerId : "-1";
      payload1.userid = globalJson1 != null ? globalJson1.id : "-1";
      payload1.orgId = globalJson1 != null ? globalJson1.orgId : "-1";
      payload1.orgType = globalJson1 != null ? globalJson1.userOrgType : "-1";
      payload1.subresellerOrgId =
        globalJson1.userOrgType === 3
          ? globalJson1.orgId
          : globalJson1.userOrgType;
      payload1.type = "Create";
      payload1.search = "";
      // payload1.id = deviceToEdit.id;
      payload1.sessionOrgType =
        globalJson1 != null ? globalJson1.userOrgType : "-1";
      payload1.createdBy = globalJson1 != null ? globalJson1.id : "-1";
      if (2 !== globalJson1.userOrgType) {
        payload1.orgType = 2;
        payload1.sessionOrgType = 2;
        payload1.subresellerOrgId = 2;
        payload1.orgId = globalJson1 != null ? globalJson1.resellerId : "-1";
      }
      const licensekeyList = (await DeviceService.getLicenseKeyList(payload1))
        .data;


      setLicensekeyLst(licensekeyList);
      const planLists = (await DeviceService.getlistofPlansList(payload1)).data;
      setPlanList(planLists);

      
      const devicePlanLists = (
        await DeviceService.getlistofDevicePlans(payload1)
      ).data;
     
      setDevicePlanList(devicePlanLists);
      const mfgsitecodeLists = (
        await DeviceService.getlistofMFGSiteCode(payload1)
      ).data;

      setMfgsitecodeList(mfgsitecodeLists);
      const partNumberLists = (
        await DeviceService.getlistofPartNumberList(payload1)
      ).data;

      setPartNumberList(partNumberLists);
      const deviceSimNumberLists = (
        await DeviceService.getDeviceSimNumberLists(payload1)
      ).data;

      setDeviceSimNumberList(deviceSimNumberLists);
      payload1.type = "modelname";
      const deviceModelNameLists = (
        await DeviceService.getDeviceModelNameList(payload1)
      ).data;
  
      setDeviceModelNameList(deviceModelNameLists);
      payload1.type = "frameware";
      const deviceFrameWareLists = (
        await DeviceService.getDeviceModelNameList(payload1)
      ).data;
      setDeviceFrameWareList(deviceFrameWareLists);
      payload1.type = "hardware";
      const deviceHardWareLists = (
        await DeviceService.getDeviceModelNameList(payload1)
      ).data;
      setDeviceHardWareList(deviceHardWareLists);
      const simPlanStatusLists = (
        await DeviceService.getSimPlanStatusList(payload1)
      ).data;
      setSimPlanStatusList(simPlanStatusLists);

      //setValue('listofPlans', )
      const deviceStatusLists = (
        await DeviceService.getDeviceStatusList(payload1)
      ).data;
      var deviceStatusLst = [];
      if (deviceStatusLists.length > 1) {
        deviceStatusLst.push(deviceStatusLists[0]);
        deviceStatusLst.push(deviceStatusLists[8]);
        deviceStatusLst.push(deviceStatusLists[9]);
        deviceStatusLst.push(deviceStatusLists[10]);
        deviceStatusLst.push(deviceStatusLists[11]);
      }

      setDeviceStatusList([...deviceStatusLst]);

      handleOBDCheck(deviceModelNameLists[0].value);
      
      //Reset data
      const onLoadReset = {
        deviceStatus:deviceStatusLists[0].value, 
        devicePlan: devicePlanLists[0].value,
        mfgSiteCode:mfgsitecodeLists[0].value,
        listofPlans: planLists[0].value,
        partNumber:partNumberLists[0].value,
        deviceModel:deviceModelNameLists[0].value,
        licenseKey:licensekeyList[0].value,
        fameWare:'0',
        hardware:'0',
      }
      reset(onLoadReset);

        var request = {};
        request.message = "Admin > Device > Create Device";
        request.action = "View";
        request.featureName = "Device";
        request.customerName = "All";
        request.fleetName = "All";
        request.result = "Success";
        request.access = "Portal";
        request.severity = "INFO";
        request.orgId = globalOrgId;
        request.userId = globaluserId;
        request.userName = globalUserName;
    
        auditLog(request);
    }


  }, []);

  const getSimDetails = async (val) => {
    var obj = {};
    var payload = {};
    payload.simId = val;
    const simDetailsLists = (await DeviceService.getDeviceSimDetails(payload))
      .data;

    if (simDetailsLists !== undefined && simDetailsLists.length > 0) {
      obj.firstMobileNum = simDetailsLists[0].mobileNumber;
      obj.secondMobileNum = simDetailsLists[0].mobileNumber1;
      obj.simStatus = simDetailsLists[0].simStatus.toString(16);
      obj.firstServiceProvider = simDetailsLists[0].simProviderName;
      obj.simProviderName1 = simDetailsLists[0].simProviderName1;
      obj.simStatusName = simDetailsLists[0].simStatusName;
    } else {
      obj.firstMobileNum = "NA";
      obj.secondMobileNum = "NA";
      obj.simStatus = "0";
      obj.firstServiceProvider = "NA";
      obj.simProviderName1 = "NA";
    }
    setAssignedobj(obj);
  };

  const onSubmit = (data) => {
    if (!deviceToEdit) {
      saveDeviceDetails(data);
      setCircularOpen(true);
    } else {
      updateDeviceDetails(data);
      setCircularOpen(true);
    }
  };

  const globalJson2 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globaldisplayview = "";
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalUserName = "";

  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globaldisplayview = globalJson.displayView;
    globalUserName = globalJson.userName;
  }

  //Device Update and Create
  const theme = useTheme();
  const dispatch = useDispatch();
  const [
    deviceUpdate,
    {
      data: deviceUpdateData,
      isloading: updateDeviceLoading,
      isSuccess: deviceUpdateSuccess,
    },
  ] = useUpdateDeviceMutation();
  const [
    deviceCreate,
    {
      data: deviceCreateData,
      isloading: createDeviceLoading,
      isSuccess: deviceCreateSuccess,
    },
  ] = useCreateDeviceMutation();

  const saveDeviceDetails = async (obj) => {
    obj.orgId = globalJson1 != null ? globalJson1.orgId : "-1";
    obj.resellerId = globalJson1 != null ? globalJson1.resellerId : "-1";
    obj.dealerId = globalJson1 != null ? globalJson1.dealerId : "-1";
    obj.createdBy = globalJson1 != null ? globalJson1.id : "-1";

    for (let i = 0; i < licensekeyLst.length; i++) {
      if (licensekeyLst[i].value === parseInt(obj.licenseKey)) {
        obj.licensekeyText = licensekeyLst[i].key;
      }
    }
    obj.simId = obj.simNumber;
    for (var j = 0; j < deviceSimNumberList.length; j++) {
      if (deviceSimNumberList[j].value === parseInt(obj.simNumber)) {
        obj.simNmbr = deviceSimNumberList[j].title;
        obj.simNumber = deviceSimNumberList[j].title;
        break;
      }
    }
    /*
    if(obj.licenseKeyId===undefined || obj.licenseKeyId!=0){
      obj.licenseKeyId=1;
    }
    if(obj.licensekey===0){
      obj.licensekey=1;
    }
    if(obj.plan===0){
      obj.plan=1;
    }
    */
    let simStatus;
    switch (obj.simStatus) {
      case "INACTIVE":
        simStatus = 1;
        break;
      case "TEST":
        simStatus = 2;
        break;
      case "ACTIVE":
        simStatus = 3;
        break;
      case "SUSPENDED":
        simStatus = 4;
        break;
      case "REPAIR":
        simStatus = 5;
        break;
      case "CANCELLED":
        simStatus = 6;
        break;
      case "EXPIRED":
        simStatus = 7;
        break;
      case "DEACTIVATED":
        simStatus = 8;
    }
    obj.simStatus = simStatus;
    obj.deviceModelId = obj.deviceModel;
    obj.isObd = 1;
    //const responseData = await DeviceService.saveDeviceDetails(obj);
    deviceCreate(obj);

  };

  const updateDeviceDetails = async (obj) => {
    obj.orgId = globalJson1 != null ? globalJson1.orgId : "-1";
    obj.resellerId = globalJson1 != null ? globalJson1.resellerId : "-1";
    obj.createdBy = globalJson1 != null ? globalJson1.id : "-1";
    obj.licenseKey = deviceToEdit.licenseKey;
    for (let i = 0; i < licensekeyLst.length; i++) {
      if (licensekeyLst[i].value === parseInt(obj.licenseKey)) {
        obj.licensekeyText = licensekeyLst[i].key;
      }
    }
    obj.simId = obj.simNumber;
    for (let j = 0; j < deviceSimNumberList.length; j++) {
      if (deviceSimNumberList[j].value === parseInt(obj.simNumber)) {
        obj.simNmbr = deviceSimNumberList[j].title;
        obj.simNumber = deviceSimNumberList[j].title;
        break;
      }
    }
    let simStatus;
    switch (obj.simStatus) {
      case "INACTIVE":
        simStatus = 1;
        break;
      case "TEST":
        simStatus = 2;
        break;
      case "ACTIVE":
        simStatus = 3;
        break;
      case "SUSPENDED":
        simStatus = 4;
        break;
      case "REPAIR":
        simStatus = 5;
        break;
      case "CANCELLED":
        simStatus = 6;
        break;
      case "EXPIRED":
        simStatus = 7;
        break;
      case "DEACTIVATED":
        simStatus = 8;
    }
    obj.simStatus = simStatus;
    obj.id = deviceToEdit.id;
    obj.isObd = 1;
    //const responseData = await DeviceService.updateDeviceDetails(obj);
    deviceUpdate(obj);

  };

  useEffect(() => {
    if (deviceUpdateSuccess) {
      dispatch(
        snackService([
          deviceUpdateData?.resultMessage,
          deviceUpdateData?.resultText,
          deviceUpdateSuccess,
        ])
      );
      if (deviceUpdateData?.resultText !== "Failed") {
        onCancel();
      }
    } else if (deviceCreateSuccess) {
      dispatch(
        snackService([
          deviceCreateData?.resultMessage,
          deviceCreateData?.resultText,
          deviceCreateSuccess,
        ])
      );
      if (deviceCreateData?.resultText !== "Failed") {
        onCancel();
      }
    }
  }, [deviceUpdateSuccess, deviceCreateSuccess]);

  const handleCancelClick = () => {
    onCancel();
  };

  const [date, setDate] = useState();
  const handleDateChange = (newValue) => {
    setDate(newValue);

    setValue("planActDate", moment(newValue).format("DD/MM/yyyy"));
  };
   
  
 
  const handleSearch = async (searchText) => {
    var payload = {};
    payload.resellerId = globalJson1 != null ? globalJson1.resellerId : "-1";
    payload.userid = globalJson1 != null ? globalJson1.id : "-1";
    payload.type = "Create";
    payload.id = "";
    payload.search = searchText;

    const deviceSimNumberLists = (
      await DeviceService.getDeviceSimNumberLists(payload)
    ).data;
    setDeviceSimNumberList(deviceSimNumberLists);
  };
  const re = /^[0-9\b]+$/;

  const handleOBDCheck = (value) => {
    let modelIdarray = [12,13,17,19,20,21,22,23];
    if (modelIdarray.includes(value)){
       setObdCheck(true);
       }
    else {
      setObdCheck(false);
    }
  }


  return (
    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <StyledCardShadow elevation={0} sx={{ mt: 2 }}>
        <CardHeader
          title={<StyledTableTitle>Device Detail</StyledTableTitle>}
        />
        <CardContent>
          <Grid container>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Device IMEI Number 
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.imei && true}
                    size="small"
                    disabled={clickedval}
                    fullWidth
                    {...register("imei", {
                      required: {
                        value: true,
                        message: "Device IMEI Required",
                      },
                      maxLength: {
                        value: 15,
                        message: "Device IMEI no must be 15 Character ",
                      },
                      minLength: {
                        value: 15,
                        message: "Device IMEI no must be 15 Character",
                      },
                    })}
                    onChange={(event) =>
                      event.target.value === "" || re.test(event.target.value)
                        ? setValue("imei", event.target.value)
                        : setValue("imei", "")
                    }
                    helperText={errors.imei && errors.imei.message}
                    inputProps={{ maxLength: "15" }}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Device Status
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.deviceStatus && true}
                    {...register("deviceStatus", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },

                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(event) =>
                      event.target.value === "" || re.test(event.target.value)
                        ? setValue("deviceStatus", event.target.value)
                        : setValue("deviceStatus", "")
                    }
                    defaultValue={
                      deviceToEdit !== null ? deviceToEdit.deviceState : "1"
                    }
                    size="small"
                    disabled={clickedval}
                    value={watch('deviceStatus')}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {deviceStatusList.length > 0 &&
                      deviceStatusList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>

                  {errors.deviceStatus && (
                    <FormHelperText error={errors.deviceStatus && true}>
                      {errors.deviceStatus.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Device Plan
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.devicePlan && true}
                    {...register("devicePlan", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "-1" || "Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("devicePlan", e.target.value);
                    }}
                    defaultValue={
                      deviceToEdit !== null ? deviceToEdit.devicePlan : "0"
                    }
                    size="small"
                    disabled={clickedval}
                    value={watch('devicePlan')}
                  >
                    <MenuItem value="-1">Select</MenuItem>
                    {devicePlanList.length > 0 &&
                      devicePlanList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>

                  {errors.devicePlan && (
                    <FormHelperText error={errors.devicePlan && true}>
                      {errors.devicePlan.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              {deviceToEdit != null && (<Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Device Serial Number
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.deviceSerialNo && true}
                    {...register("deviceSerialNo", {
                      required: {
                        value: true,
                        message: "Device Serial Required Field",
                      },
                      maxLength: {
                        value: 6,
                        message: "Device Serial no must be 6 Character ",
                      },
                      minLength: {
                        value: 6,
                        message: "Device Serial no must be 6 Character",
                      },
                    })}
                    onChange={(event) =>
                      event.target.value === "" || re.test(event.target.value)
                        ? setValue("deviceSerialNo", event.target.value)
                        : setValue("deviceSerialNo", "")
                    }
                    helperText={
                      errors.deviceSerialNo && errors.deviceSerialNo.message
                    }
                    inputProps={{ maxLength: "6" }}
                    disabled={clickedval}
                  />
                </StyledFormControl>
              </Grid>)}

              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Device Model
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.deviceModel}
                    {...register("deviceModel", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("deviceModel", e.target.value);
                      //handleOBDCheck(e.target.value)
                    }}
                    size="small"
                    defaultValue={
                      deviceToEdit !== null ? deviceToEdit.deviceModel : "0"
                    }
                    disabled={clickedval}
                    value={watch('deviceModel')}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {deviceModelNameList.length > 0 &&
                      deviceModelNameList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  {errors.deviceModel && (
                    <FormHelperText error={errors.deviceModel}>
                      {errors.deviceModel.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>

              {obdCheck && (
                <Grid item lg={4} >
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel sx={{ opacity:0}}>
                    OBD Enabled
                  </StyledFormControlLabel>
                  <FormControlLabel control={<Checkbox  color="primary" checked={true} disabled={true} />} label="OBD Enabled"/>
                </StyledFormControl>
              </Grid>
              )}
            </Grid>
            {/* ////////////// */}
            <Grid container spacing={3} sx={{ marginTop: "0px" }}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Site code
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.mfgSiteCode}
                    {...register("mfgSiteCode", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("mfgSiteCode", e.target.value);
                    }}
                    size="small"
                    defaultValue={
                      deviceToEdit !== null ? deviceToEdit.mfgSiteCodeId : "5"
                    }
                    disabled={clickedval}
                    value={watch('mfgSiteCode')}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {mfgsitecodeList.length > 0 &&
                      mfgsitecodeList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  {errors.mfgSiteCode && (
                    <FormHelperText error={errors.mfgSiteCode}>
                      {errors.mfgSiteCode.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    MFG Month 
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.mfgMnth}
                    {...register("mfgMnth", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("mfgMnth", e.target.value);
                    }}
                    size="small"
                    defaultValue={
                      deviceToEdit !== null ? deviceToEdit.mfgMonth : moment().format("M")
                    }
                    disabled={clickedval}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {monthList.length > 0 &&
                      monthList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  {errors.mfgMnth && (
                    <FormHelperText error={errors.mfgMnth}>
                      {errors.mfgMnth.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Year 
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.year}
                    {...register("year", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("year", e.target.value);
                    }}
                    size="small"
                    defaultValue={
                      deviceToEdit !== null ? deviceToEdit.mfgYear :  moment().format("YYYY")
                    }
                    disabled={clickedval}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {yearList.length > 0 &&
                      yearList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  {errors.year && (
                    <FormHelperText error={errors.year}>
                      {errors.year.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
            </Grid>
            {/* ////////////// */}
            <Grid container spacing={3} sx={{ marginTop: "0px" }}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Part Number 
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.partNumber}
                    {...register("partNumber", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("partNumber", e.target.value);
                    }}
                    defaultValue={
                      deviceToEdit !== null ? deviceToEdit.partNumberId : "0"
                    }
                    size="small"
                    disabled={clickedval}
                    value={watch('partNumber')}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {partNumberList.length > 0 &&
                      partNumberList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  {errors.partNumber && (
                    <FormHelperText error={errors.partNumber}>
                      {errors.partNumber.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    firmware
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.fameWare}
                    {...register("fameWare", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("fameWare", e.target.value);
                    }}
                    size="small"
                    defaultValue={
                      deviceToEdit !== null ? deviceToEdit.firmware : "0"
                    }
                    disabled={clickedval}
                    value={watch('fameWare')}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {deviceFrameWareList.length > 0 &&
                      deviceFrameWareList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  {errors.fameWare && (
                    <FormHelperText error={errors.fameWare}>
                      {errors.fameWare.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Hardware
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.hardware}
                    {...register("hardware", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("hardware", e.target.value);
                    }}
                    size="small"
                    defaultValue={
                      deviceToEdit !== null
                        ? deviceToEdit.hardwareVersionId
                        : "0"
                    }
                    disabled={clickedval}
                    value={watch('hardware')}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {deviceHardWareList.length > 0 &&
                      deviceHardWareList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  {errors.hardware && (
                    <FormHelperText error={errors.hardware}>
                      {errors.hardware.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCardShadow>
      <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
        <CardHeader
          title={<StyledTableTitle>License key Details</StyledTableTitle>}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item lg={3}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>Plan</StyledFormControlLabel>
                <StyledDropDown
                  error={errors.listofPlans}
                  {...register("listofPlans", {
                    required: {
                      value: deviceToEdit !== null ? false : true,
                      message: "Required Field",
                    },
                    validate: (fieldValue) => {
                      return fieldValue !== "0" || " Required Field";
                    },
                  })}
                  onChange={(e) => {
                    setValue("listofPlans", e.target.value);
                  }}
                  defaultValue={
                    deviceToEdit !== null ? deviceToEdit.listofPlans : "141"
                  }
                  size="small"
                  disabled={ deviceToEdit !== null ? true:false}
                  value={watch('listofPlans')}
                >
                  <MenuItem value="0">Select</MenuItem>
                  {planList.length > 0 &&
                    planList.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
                {errors.listofPlans && (
                  <FormHelperText error={errors.listofPlans}>
                    {errors.listofPlans.message}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Grid>
            <Grid item lg={3}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  License Key
                </StyledFormControlLabel>
                <StyledDropDown
                  error={errors.licenseKey}
                  {...register("licenseKey", {
                    required: {
                      value: deviceToEdit !== null ? false : true,
                      message: "Required Field",
                    },
                    validate: (fieldValue) => {
                      return fieldValue !== "0" || " Required Field";
                    },
                  })}
                  onChange={(e) => {
                    setValue("licenseKey", e.target.value);
                  }}
                  defaultValue={
                    deviceToEdit !== null ? deviceToEdit.licenseKey : "0"
                  }
                  size="small"
                  disabled={ deviceToEdit !== null ? true:false}
                  value={watch('licenseKey')}
                >
                  <MenuItem value="0">Select</MenuItem>
                  {licensekeyLst.length > 0 &&
                    licensekeyLst.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
                {errors.licenseKey && (
                  <FormHelperText error={errors.licenseKey}>
                    {errors.licenseKey.message}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Grid>
            <Grid item lg={3}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  LicenseKey Activation date
                </StyledFormControlLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat={distanceDisplayUnit === "mi" ? "yyyy/MM/dd": "dd/MM/yyyy"}
                    value={  deviceToEdit !== null ?  deviceToEdit?.licenseActivationDate : date}
                    onChange={handleDateChange}
                    disabled={ deviceToEdit !== null ? true:clickedval}
                    renderInput={(params) => (
                      <StyledTextField
                        fullWidth
                        size="small"
                        {...params}
                        error={errors.planActDate && true}
                        {...register("planActDate", {
                          required: {
                            value: deviceToEdit !== null ? false : true,
                            message: "Required Field",
                          },
                        })}
                        helperText={
                          errors.planActDate && errors.planActDate.message
                        }
                        disabled={ deviceToEdit !== null ? true:clickedval}
                      />
                    )}
                  />
                </LocalizationProvider>
              </StyledFormControl>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCardShadow>
      <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
        <CardHeader title={<StyledTableTitle>Sim Details</StyledTableTitle>} />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel>Sim Number</StyledFormControlLabel>
                <StyledDropDown
                  error={errors.simNumber}
                  {...register("simNumber", {
                    required: {
                      value: false,
                      message: "Required Field",
                    },
                    // 3
                  })}
                  onChange={(e) => {
                    getSimDetails(e.target.value);
                    setValue("simNumber", e.target.value);
                  }}
                  defaultValue={
                    deviceToEdit !== null ? deviceToEdit.simId : "0"
                  }
                  size="small"
                  disabled={clickedval}
                >
                  <StyledFilterSearch
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon sx={{ fontSize: "inherit" }} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => handleSearch(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                  <MenuItem value="0">Select</MenuItem>
                  {deviceSimNumberList.length > 0 &&
                    deviceSimNumberList.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
                {errors.simNumber && (
                  <FormHelperText error={errors.simNumber}>
                    {errors.simNumber.message}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel>Sim Status </StyledFormControlLabel>
                <StyledTextField
                  error={errors.simStatus && true}
                  {...register("simStatus", {
                    // required: {
                    //   value: true,
                    //   message: "Required Field",
                    // },
                  })}
                  onChange={(e) => {
                    setValue("simStatus", e.target.value);
                  }}
                  value={assignedobj.simStatusName}
                  size="small"
                  helperText={errors.simStatus && errors.simStatus.message}
                  disabled
                />
                {/* <MenuItem value="102">India(IND)</MenuItem>
                        {simPlanStatusList.length > 0 &&
                          simPlanStatusList.map((option, index) => {
                            return (
                              <MenuItem key={index} value={option.value}>
                                {option.title}
                              </MenuItem>
                            );
                          })}
                      </StyledDropDown> */}
                {/* {errors.simStatus && (
                        <FormHelperText>
                          {errors.simStatus.message}
                        </FormHelperText>
                      )} */}
              </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel>
                  First Mobile Number
                </StyledFormControlLabel>
                <StyledTextField
                  error={errors.firstMobileNum && true}
                  size="small"
                  fullWidth
                  {...register("firstMobileNum", {
                    // required: {
                    //   value: true,
                    //   message: "Required Field",
                    // },
                  })}
                  onChange={(e) => setValue("firstMobileNum", e.target.value)}
                  value={assignedobj.firstMobileNum}
                  helperText={
                    errors.firstMobileNum && errors.firstMobileNum.message
                  }
                  disabled
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel>
                  Service Provider(APN)
                </StyledFormControlLabel>
                <StyledTextField
                  error={errors.firstServiceProvider && true}
                  size="small"
                  fullWidth
                  {...register("firstServiceProvider", {
                    // required: {
                    //   value: true,
                    //   message: "Required Field",
                    // },
                  })}
                  onChange={(e) =>
                    setValue("firstServiceProvider", e.target.value)
                  }
                  value={assignedobj.firstServiceProvider}
                  helperText={
                    errors.firstServiceProvider &&
                    errors.firstServiceProvider.message
                  }
                  disabled
                />
              </StyledFormControl>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCardShadow>
      <CardActions>
        <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
       <SoftButton
            className="error"
            size="small"
            onClick={handleCancelClick}
            color="error"
          >
            Cancel
          </SoftButton>
          {!clickedval &&  <SoftButton
            size="small"
            className="success"
            color="success"
            type="submit"
          > 
            {updateDeviceLoading || createDeviceLoading ? (
              <PulseLoader
                color={theme.palette.success.contrastText}
                size={6}
              />
            ) : deviceToEdit ? (
              "Update"
            ) : (
              "Submit"
            )}
          </SoftButton> }
        </StyledFormButtonsRow>
      </CardActions>
    </Box>
  );
}

export default FormPropsTextFields;
