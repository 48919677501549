import { Box, Grid, Paper, Stack, Tooltip, Typography, alpha, useTheme } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoSpeedometerOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import reportServices from '../../../services/reportServices';
import GetLocation from '../../Location';

function VehicleDetails({data, address, allData,startLocation,endLocation}) {
    console.log("address===>",startLocation,endLocation);
    const theme = useTheme();
    const date = useSelector((state) => state.controls.treeview.date);
    const [isToday, setIsToday] = useState(true);
    let userData = JSON.parse(localStorage.getItem("userData"));
    let distanceUnit = userData.distanceDisplayUnit ;
  
    useEffect(() => {
        if (date !== null) {
          if (
            date[0] === moment().format("YYYY-MM-DD 00:00:00") &&
            moment(date[1]).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          ) {
            setIsToday(true);
          } else {
            setIsToday(false); // already false changed for demo purpose
          }
        }
      }, [date]);


    const Statstic = ({label, value, icon, color}) => {
        return(
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box sx={{fontSize:'16px'}}>{icon}</Box>
                <Box>
                    <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px'}}>{label}</Typography>
                    <Typography variant='subtitle2' color={color}>{value}</Typography>
                </Box>
            </Stack>
        )
    }

    const TodayData = () => {
        return (
            <Box sx={{p:1, minHeight:'100%', height:'auto', pb:1.2}}>
                <Grid container spacing={1} rowGap={1} sx={{mt:1, mb:1.5}}> 
                        <Grid item lg={6}>
                            <Statstic label={'Last Connected'} value={data?.lastDate}/>
                        </Grid>
                        <Grid item lg={6}>
                            <Statstic label={'Vehicle Status'} value={data?.propVehicleStatus}/>
                        </Grid>
                        {/* <Grid item lg={6}>
                            <Statstic label={'Vehicle Model'} value={data?.vehicleModel}/>
                        </Grid> */}
                        <Grid item lg={6}>
                            <Statstic label={'IMEI'} value={data?.imei}/>
                        </Grid>
                        <Grid item lg={12}>
                            {/* <Statstic label={'Address'} value={address} icon={<HiOutlineLocationMarker />}/> */}
                            <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px'}}>Address</Typography>
                            <Typography variant='body2'  component={"a"} href={`https://www.google.com/maps/search/?api=1&query=${address}`}
        target="_blank"  sx={{fontSize:'12px', color:"blue"}}>{address}</Typography>
                        </Grid>
                    </Grid>
                <Paper sx={{p:1.5, mt:1, boxShadow:`0px 0px 5px ${alpha(theme.palette.info.main, .1)} !important`, border:`.5px solid ${alpha(theme.palette.info.main, .5)}`, background:`${alpha(theme.palette.info.main, .05)} !important`}}>
                    <Grid container spacing={1} rowGap={1.2}> 
                        <Grid item lg={6}>
                            <Statstic label={'Odometer ('+distanceUnit+')'} value={data?.odometerReading} icon={<IoSpeedometerOutline />} color={'success'}/>
                        </Grid>
                        {/* <Grid item lg={4}>
                            <Statstic label={'SOC (%)'} value={data?.soc} icon={<TbBattery2 />} color={'success'}/>
                        </Grid> */}
                        <Grid item lg={6}>
                            <Statstic label={'Speed ('+distanceUnit+'/h)'} value={data?.speed} icon={<IoSpeedometerOutline />} color={'success'}/>
                        </Grid>
                    </Grid>
                </Paper>
                {/* <Grid container spacing={1} rowGap={1.2} sx={{my:1}}> 
                        {/* <Grid item lg={4}>
                            <Statstic label={'Internal Battery (V)'} value={data?.interBatt}/>
                        </Grid> 
                        <Grid item lg={6}>
                            <Statstic label={'Battery Temp (°C)'} value={data?.batterytemp}/>
                        </Grid>
                        <Grid item lg={6}>
                            <Statstic label={'Battery Voltage (V)'} value={data?.batteryvolt}/>
                        </Grid>
                </Grid> */}
            </Box>
        )
    }

    const PastDay = () => {
        const refAddres = React.useRef();
        var getAddressBtn = React.useRef();
        const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              const responseData = (
                await reportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refAddres.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
              }
            }
          };
        return(
            <Box sx={{p:1, height:'100%'}}>
        <Grid container spacing={1} rowGap={1.2} sx={{mt:1, mb:1.5}}>
                <Grid item lg={6}>
                    <Statstic label={'Vehicle No'} value={allData.commonData[0]?.vehicleNo}/>
                </Grid>
                <Grid item lg={6}>
                    <Statstic label={'VIN'} value={allData.commonData[0]?.vinNumber}/>
                </Grid> 
                <Grid item lg={6}>
                    <Statstic label={'Start Date'} value={allData.commonData[0]?.startDate}/>
                </Grid>
                <Grid item lg={6}>
                    <Statstic label={'End Date'} value={allData.commonData[0]?.endDate}/>
                </Grid>
                <Grid item lg={6}>
                    <Box sx={{px:1}}>
                    <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px'}}>Start Location</Typography>
                    <Typography variant='body2'  component={"a"} href={`https://www.google.com/maps/search/?api=1&query=${startLocation}`}
        target="_blank"  sx={{fontSize:'12px', color:"blue"}}>{startLocation}</Typography>
                    {/* <Tooltip title={'Click to view'}> */}
                    {/* <GetLocation row={""} columnName={`${allData.vehicleTrails[0]?.lat},${allData.vehicleTrails[0]?.lng}`}/> */}
                    {/* </Tooltip> */}

                    {/* <Statstic  value={startLocation} icon={<HiOutlineLocationMarker />}/> */}
                    </Box>
                </Grid>
                <Grid item lg={6}>
                    <Box sx={{px:1}}>
                    <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px'}}>End Location</Typography>
                    <Typography variant='body2'  component={"a"}     href={`https://www.google.com/maps/search/?api=1&query=${endLocation}`}
        target="_blank" sx={{fontSize:'12px', color:"blue"}}>{endLocation}</Typography>
                    {/* <Statstic  value={endLocation} icon={<HiOutlineLocationMarker />}/> */}
                    {/* <Tooltip title={'Click to view'}> */}
                    {/* <GetLocation row={""} columnName={`${allData?.vehicleTrails[
                                allData?.vehicleTrails?.length - 1
                              ]?.lat},${allData?.vehicleTrails[
                                allData?.vehicleTrails?.length - 1
                              ]?.lng}`}/> */}
                    {/* </Tooltip> */}
                    </Box>
                </Grid>
            </Grid>
        {/* <Paper sx={{p:1.5, mt:1, boxShadow:`0px 0px 5px ${alpha(theme.palette.info.main, .1)} !important`, border:`.5px solid ${alpha(theme.palette.info.main, .5)}`, background:`${alpha(theme.palette.info.main, .05)} !important`}}>
            <Grid container spacing={1} rowGap={1.2}> 
                <Grid item lg={4}>
                    <Statstic label={'Odometer (km)'} value={data?.odometerReading} icon={<IoSpeedometerOutline />} color={'success'}/>
                </Grid>
                <Grid item lg={4}>
                    <Statstic label={'SOC (%)'} value={data?.soc} icon={<TbBattery2 />} color={'success'}/>
                </Grid>
                <Grid item lg={4}>
                    <Statstic label={'Speed (km/h)'} value={data?.speed} icon={<IoSpeedometerOutline />} color={'success'}/>
                </Grid>
            </Grid>
        </Paper>
        <Grid container spacing={1} rowGap={1.2} sx={{my:1}}> 
                <Grid item lg={4}>
                    <Statstic label={'Internal Battery (V)'} value={data?.interBatt}/>
                </Grid>
                <Grid item lg={4}>
                    <Statstic label={'Battery Temp (°C)'} value={data?.batterytemp}/>
                </Grid>
                <Grid item lg={4}>
                    <Statstic label={'Battery Voltage (V)'} value={data?.batteryvolt}/>
                </Grid>
        </Grid> */}
        </Box>
        )
    }


  return ( isToday ? <TodayData /> : <PastDay /> )
}

export default VehicleDetails