import React, { Fragment, useMemo } from 'react'
import { DataTable } from '../../shared/data_table/DataTable';
import { Box, Chip, Stack, Typography, useTheme } from '@mui/material';
import moment from "moment";

function TicketList(props) {
    const {isLoading, data, editTicket, totalElements, totalPages, newPage, rowsPerPage, pageChange, handleSearch, onChangeXcl } = props;
  const theme = useTheme();
    const statusColors = [
      {
          'id':1,
          'status':'Open',
          'colorCode':'#d93f4f'
      },
      {
          'id':2,
          'status':'In Progress',
          'colorCode':'#0077e6'
      },
      {
          'id':2,
          'status':'Resolved',
          'colorCode':'#93d544'
      },
      {
          'id':2,
          'status':'Closed',
          'colorCode':'#ff1a1a'
      },
      {
          'id':2,
          'status':'Reopen',
          'colorCode':'#951d29'
      },
      {
          'id':2,
          'status':'Clarification',
          'colorCode':'#5f9221'
      },
      {
          'id':2,
          'status':'Rejected',
          'colorCode':'#400d12'
      }
  ]

    const columns = useMemo(
        () => [
          {
            Header: "Ticket Id",
            accessor: "ticketno",
            width: 100,
            Cell: ({ row }) => (
              <Typography
                id={row?.original.id}
                onClick={() => editTicket(row?.original.id)}
                sx={{width:'100%'}}
              >
                {row?.original.ticketno}
              </Typography>
            ),
          },
          {
            Header: "Ticket Title",
            accessor: "title",
            width: 300,
            show:true,
            Cell:({row}) => (
              <Stack direction={'row'} justifyContent={'space-between'} sx={{width:'100%', '&:hover .MuiChip-root':{visibility:'visible'}}} onClick={() => editTicket(row?.original.id)}>
                  <Typography>{row.original.title}</Typography>
                  <Chip label={'View'} size='small' color='secondary' variant='outlined' sx={{visibility:'hidden', '&:hover':{background:theme.palette.secondary.main, color:theme.palette.secondary.contrastText}}}/>
              </Stack>
            ),
          },
          {
            Header: "Category",
            accessor: "categoryval",
            width: 120,
          },
          {
            Header: "Status",
            accessor: "statusval",
            width: 120,
            Cell:({row}) => {
              let statusCode = statusColors.filter((item) => item.status === row?.original?.statusval);
              return(
                <Typography variant='body2' sx={{color:statusCode[0]?.colorCode !== undefined ? statusCode[0]?.colorCode : 'error', fontWeight:600, borderRadius:'50px', padding:'3px 10px', width:'fit-content'}}>{row?.original.statusval}</Typography>
              )
            }
          },
          {
            Header: "Severity",
            accessor: "servityval",
          },
          {
            Header: "Vehicle",
            accessor: "vehicleNo",
            Cell:({ row }) =>(
                <Typography variant='body2'>{row?.original?.vehicleNo}</Typography>
            )
          },
          // {
          //   Header: "Vehicle ORG",
          //   accessor: "vehicleOrg",
          // },
          {
            Header: "Reporter",
            accessor: "reporter",
          },
          {
            Header: "Reporter ORG",
            accessor: "reporterOrg",
          },
          {
            Header: "Created Date",
            accessor: "createdDate",
          },
          {
            Header: "Assignee",
            accessor: "assignedTo",
          },
          {
            Header: "Assignee Org Name",
            accessor: "assigneeOrgName",
          },
          {
            Header: "Last Modified Date",
            accessor: "lastModifiedDate",
          },
          {
            Header: "Closed Date",
            accessor: "closedate",
          },
          // {
          //   Header: "Incident Time",
          //   accessor: "incidentDateTime",
          //   Cell: ({ row }) => (
          //     <span             
          //     >
          //       {row?.original.incidentDateTime===null?"-":moment(row?.original.incidentDateTime).format("DD-MM-yyyy HH:mm") }
          //     </span>
          //   ),
          // },
          // {
          //   Header: "Incident Location",
          //   accessor: "incidentLocation",
          //   Cell: ({ row }) => (
          //     <span             
          //     >
          //       {row?.original.incidentLocation===null?"-":row?.original.incidentLocation }
          //     </span>
          //   ),
          // },
          // {
          //   Header: "Incident Close Time",
          //   accessor: "incidentCloseDateTime",
          //   Cell: ({ row }) => (
          //     <span             
          //     >
          //       {row?.original.incidentCloseDateTime===null?"-":moment(row?.original.incidentCloseDateTime).format("DD-MM-yyyy HH:mm") }
          //     </span>
          //   ),
          // },
          // {
          //   Header: "Incident Difference",
          //   accessor: "vnumber",
          //   Cell: ({ row }) => (
          //     <span>
          //     {(row?.original.incidentCloseDateTime!==null && row?.original.incidentDateTime!==null) ?(moment.utc(moment(row?.original.incidentCloseDateTime,"DD-MM-YYYY HH:mm").diff(moment(row?.original.incidentDateTime,"DD-MM-YYYY HH:mm"))).format("HH:mm")==="Invalid date"?moment.utc(moment(row?.original.incidentCloseDateTime,"DD/MM/YYYY HH:mm").diff(moment(row?.original.incidentDateTime,"DD/MM/YYYY HH:mm"))).format("HH:mm"):moment.utc(moment(row?.original.incidentCloseDateTime,"DD-MM-YYYY HH:mm").diff(moment(row?.original.incidentDateTime,"DD-MM-YYYY HH:mm"))).format("HH:mm") ):"-" } 
          //     </span>
          //   ),
          // },
          // {
          //   Header: "Driver Mobile Number",
          //   accessor: "driverNumber",
          //   Cell: ({ row }) => (
          //     <span             
          //     >
          //       {row?.original.driverNumber===null?"-":row?.original.driverNumber }
          //     </span>
          //   ),
          // },
          {
            Header: "Time Taken",
            accessor: "timeTaken",
          },
        ],
    );

   



  return (
    <Fragment>
        <DataTable
            includeSearchBox={true}
            includeExportButton={true}
            onChangeXcl={onChangeXcl}
            columns={columns}
            data={data}
            isLoading={isLoading}
            totalElements={totalElements}
            totalPages={totalPages}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            handleChange={handleSearch}
            //onEdit={editTicket}
        />
    </Fragment>
  )
}

export default TicketList