import React from 'react'
import { StyledAccordion } from '../../theme/StyledEle'
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Grid, Stack, Tooltip, Typography, alpha, styled, useTheme } from '@mui/material'
import { BiDownArrowCircle } from 'react-icons/bi'

function Summary(props) {
    
    const themes = useTheme();
    const { data, mapRef } = props;
    const { summaryData } = data;
    let userData = JSON.parse(localStorage.getItem("userData"));
    let distanceUnit = userData.distanceDisplayUnit ;
    const StyledAccordion = styled(Accordion)(({theme}) => ({
        boxShadow:'none !important',
        position:'sticky',
        top:'0px',
        '&.export':{
            '& .MuiAccordionSummary-root':{
                background:`${alpha(theme.palette.success.main, .08)} !important`,
                
            }
        },
        '& .MuiAccordionSummary-root':{
            background:`${alpha(theme.palette.info.main, .08)} !important`,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper': {
                marginRight: `${theme.spacing(1)} !important`,
            },
            '&.Mui-expanded':{
                minHeight:'auto !important'
            }
        },
        '& .Mui-expanded':{
            //margin:'0px !important',
        },
        '& .MuiAccordionSummary-content':{
            '&.Mui-expanded':{
                margin:'12px 0px !important',
            }
        },
        '& .MuiAccordionDetails-root':{
            padding:'10px 10px 10px'
        }
    }))


    const Statstic = ({label, value, icon, color}) => {
        return(
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box sx={{fontSize:'16px'}}>{icon}</Box>
                <Box>
                    <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px'}}>{label}</Typography>
                    <Typography variant='subtitle2' color={color}>{value}</Typography>
                </Box>
            </Stack>
        )
    }

    //Vehicle Summary
    const VehicleSum = () => {
        return(
           <Box sx={{p:.5}}>
                <StyledAccordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<BiDownArrowCircle />}
                    >
                            <Typography>Trip Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{background:''}}>
                        <Grid container rowSpacing={1}>
                            <Grid item lg={6}>
                                <Statstic label={'Start Date'} value={data?.tripStartDate}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'End Date'} value={data?.tripEndDate}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Trip Duration'} value={data?.tripDuration}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Start Odometer ('+distanceUnit+')'} value={summaryData[0]?.startOdometer}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'End Odometer ('+distanceUnit+')'} value={summaryData[0]?.endOdometer}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Distance Traveled ('+distanceUnit+')'} value={summaryData[0]?.distanceTraveled}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Average Speed ('+distanceUnit+'/h)'} value={summaryData[0]?.avgSpeed}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Max Speed ('+distanceUnit+'/h)'} value={summaryData[0]?.maxSpeed}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Moving (hh:mm)'} value={summaryData[0]?.moveMinutes} color={'success'}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Stop (hh:mm)'} value={summaryData[0]?.stopHours} color={'error'}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Idle (hh:mm)'} value={summaryData[0]?.idleMinutes} color={'error'}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Offline (hh:mm)'} value={summaryData[0]?.offlineHours} color={'error'}/>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </StyledAccordion>
           </Box>
        )
    }

    //UtilizationSum Summary
    const UtilizationSum = () => {
        return(
           <Box sx={{p:.5}}>
                <StyledAccordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<BiDownArrowCircle />}
                    >
                            <Typography>Utilization Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{background:''}}>
                        <Grid container rowSpacing={1}>
                            <Grid item lg={6}>
                                <Statstic label={'Energy Consumed (kWh)'} value={summaryData[0]?.energyConsumed}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Energy Efficiency (kWh)'} value={summaryData[0]?.efficiencyPerKM}/>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </StyledAccordion>
           </Box>
        )
    }

    //Charging Summary
    const ChargingSum = () => {
        return(
           <Box sx={{p:.5}}>
                <StyledAccordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<BiDownArrowCircle />}
                    >
                            <Typography>Charging Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{background:''}}>
                    <Grid container rowSpacing={1}>
                            {/* <Grid item lg={6}>
                                <Statstic label={'No Charge'} value={'--'}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Count'} value={summaryData[0]?.totalChargingCountPerDay}/>
                            </Grid> */}
                            <Grid item lg={6}>
                                <Statstic label={'Start SOC (%)'} value={summaryData[0]?.startSoc}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'End SOC (%)'} value={summaryData[0]?.endSoc}/>
                            </Grid>
                            <Grid item lg={12}>
                                <Statstic label={'Duration (hh:mm)'} value={summaryData[0]?.calculatedChargingMins}/>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </StyledAccordion>
           </Box>
        )
    }

    //Driver Summary
    const DriverSum = () => {
        return(
           <Box sx={{p:.5}}>
                <StyledAccordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<BiDownArrowCircle />}
                    >
                            <Typography>Driver Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{background:''}}>
                    <Grid container rowSpacing={1}>
                            <Grid item lg={12}>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant='body2' gutterBottom sx={{p:.4, fontWeight:500}} color={'error'}>Overspeed</Typography>
                                    <Tooltip title='count' arrow>
                                        <Chip color='error' label={summaryData[0]?.overSpeedCount} size='small' sx={{fontSize:'12px', height:'20px'}}/>
                                    </Tooltip>
                                </Stack>
                            </Grid>

                            <Grid item lg={6}>
                                <Statstic label={'Distance (km)'} value={summaryData[0]?.overSpeedDistance}/>
                            </Grid>

                            <Grid item lg={6}>
                                <Statstic label={'Duration (hh:mm) '} value={summaryData[0]?.overSpeedDuration}/>
                            </Grid>

                            {/* <Grid item lg={12}>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant='body2' gutterBottom sx={{p:.4, fontWeight:500}} color={'error'}>Harsh Alerts</Typography>
                                    <Tooltip title={'Total count'}>
                                        <Chip color='error' label={summaryData[0]?.harshAlertCount} size='small' sx={{fontSize:'12px', height:'20px'}}/>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            
                            <Grid item lg={4}>
                                <Statstic label={'Accelerating'} value={summaryData[0]?.harshAccelerationCount}/>
                            </Grid>
                            <Grid item lg={4}>
                                <Statstic label={'Braking'} value={summaryData[0]?.harshbrakeCount}/>
                            </Grid>
                            <Grid item lg={4}>
                                <Statstic label={'Cornering'} value={'--'}/>
                            </Grid> */}
                        </Grid>
                    </AccordionDetails>
                </StyledAccordion>
           </Box>
        )
    }

  return (
    <Box sx={{height:'90%', overflowY:'scroll'}} >
        <VehicleSum />
        {/* <UtilizationSum />
        <ChargingSum />
        <DriverSum /> */}
    </Box>
  )
}

export default Summary