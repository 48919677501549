import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Icon,
  InputBase,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  MapTopControls,
  SoftButton,
  StyledFilterMenuItem,
  StyledFiterButton,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../theme/StyledEle";
import { RxReload, RxShare1 } from "react-icons/rx";
import { StyledMapDatePiker } from "./Treeview/TreeviewStyledEle";
import { HiOutlineCalendar } from "react-icons/hi2";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import {
  ChargingMarkers,
  StopPageMarkers,
  getOtherMarkers,
  isLoadingState,
  selectedVehicleTrailsData,
  trailsTime,
  treviewDate,
  customDateDiff,
} from "../../store/slice/ControlsSlice";
import { useGetVehicleDetailsMutation, useAuditLogMutation, useChargingMutation, useStopPageMutation } from "../../store/slice/ApiSlice";
import { FaLocationDot, FaPlug, FaStopwatch } from "react-icons/fa6";
import { MdOutlineDoNotDisturb, MdTimer } from "react-icons/md";
import { BsLightningCharge, BsLightningChargeFill } from "react-icons/bs";
import { RiTimerLine } from "react-icons/ri";
import { HiLocationMarker, HiOutlineLocationMarker } from "react-icons/hi";
import VehicleShare from "../../Utils/VehicleShare";
import { TbAirConditioning } from "react-icons/tb";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";

function MapControls(props) {
  const theme = useTheme();
  const {
    handleZoomLevel,
    mapzoom,
    mapRefetch,
    min,
    sec,
    autoRefresh,
    refetchSingleVehicle,
    UserData,
    activeFeature,
    time = true,
    timeHide = true,
    allowDays = 3,
    allowedMonth = 3,
    handleMarkerShow,
    type,
    showStop,
    showCharging,
    showTrails,
    handleExtramarkers,
    handleTrafficeLayer = { handleTrafficeLayer },
    handleCaz,
    caz,
    showTraffic,
    airData,
    handleBridge = { handleBridge },
    bridge = { bridge },
    handleWeightBridge = { handleWeightBridge },
    weightBridge = { weightBridge },
    handleToll = { handleToll },
    toll = { toll },
    handleFitRoad = { handleFitRoad },
    ftr,
    snapedTrail
  } = props;

  const [menuUser, setMenuUser] = useState(null);
  
  // useEffect(() => {
  //   const storedMenuList = JSON.parse(localStorage.getItem("menuList"));
  //   console.log("storedMenuList",storedMenuList);
  //   if (storedMenuList && storedMenuList.length > 0) {
  //     for (let i = 0; i < storedMenuList.length; i++) {
  //       if (storedMenuList[i].sub && storedMenuList[i].sub.length > 0) {
  //         for (let j = 0; j < storedMenuList[i].sub.length; j++) {
  //           if (storedMenuList[i].sub[j].route === "mapviews") {
  //             setMenuUser(storedMenuList[i].sub[j].button); 
  //             break;  
  //           }
  //         }
  //       }
  //     }
  //   }
  // }, []);
 // callfun ();
 
  // let buttons = menuUser?.split(",");
  // console.log("buttons"+buttons);
  //Date picker
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  //Select from date & To date
  let [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD 00:00:00"));
  let [toDate, setTodate] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  let [label, setLabel] = useState("Today");



  // Slice - Reducx Selector
  const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);
  const date = useSelector((state) => state.controls.treeview.date);
  const selectVehicleData = useSelector(
    (state) => state.controls.singleVehicleData
  );
  const dispatch = useDispatch();


  const [getCharging, { data: chargingData, isLoading: chargingLoading, isSuccess: chargingSuccess }] = useChargingMutation();
  const [getStoppage, { data: stoppageData, isLoading: stoppageLoading, isSuccess: stoppageSuccess }] = useStopPageMutation();



  let chargingPayload = {

    vehicleId: selectVehicleData?.vehicleId,
    startDate: selectVehicleData?.strDate,
    endDate: selectVehicleData?.endDate,
    //orgId:fleetItem.orgId,
    page: 0,
    size: 100,
    "resellerId": UserData.resellerId,
    "regionId": "0",
    "dealerId": "0",
    "custId": "0",
    "orgId": "-1",
    "search": "",
    "orderDir": "desc",
    "chargingType": '-1'
  }


  const getChargingData = async (checked) => {
    if (!checked) {
      let chargingData = await getCharging(chargingPayload)
      dispatch(ChargingMarkers([chargingData?.data?.data?.content]))
    }
  }

  const getStoppageData = async (checked) => {
    if (!checked) {
      let stopPageData = await getStoppage(chargingPayload)
      dispatch(StopPageMarkers([stopPageData?.data?.data?.content]))
    }
  }

  useEffect(() => {
    if (selectVehicleData !== null) {
      //getChargingData(false);
      //getStoppageData(false);
    }
  }, [selectVehicleData])


  //Dispatch

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: UserData.orgId,
    userId: UserData.userId,
    orgName: UserData.orgName,
    userName: UserData.userName,
  };
  //open custom calendar
  let [openCustomCal, setOpenCustomCal] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (openCustomCal) {
      setOpenCustomCal(false);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //selectDate
  const selectDate = (event) => {
    //ranges
    if (event.target.id === "today") {
      setFromDate(moment().format("YYYY-MM-DD 00:00:00"));
      setTodate(moment().format("YYYY-MM-DD 23:59:59"));
      setLabel(event.target.innerText);
      dispatch(
        treviewDate([
          moment().format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ])
      );
      dispatch(customDateDiff(1));
      handleClose();
    } else if (event.target.id === "yesterday") {
      setFromDate(moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"));
      setTodate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
      dispatch(
        treviewDate([
          moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"),
          moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"),
        ])
      );
      dispatch(customDateDiff(1));
      setLabel(event.target.innerText);
      handleClose();
    } else if (event.target.id === "last7days") {
      setFromDate(moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00"));
      setTodate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
      dispatch(
        treviewDate([
          moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00"),
          moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"),
        ])
      );
      dispatch(customDateDiff(0));
      setLabel(event.target.innerText);
      handleClose();
    } else if (event.target.id === "lastmonth") {
      setFromDate(
        moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00")
      );
      setTodate(
        moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59")
      );
      dispatch(
        treviewDate([
          moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD 00:00:00"),
          moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD 23:59:59"),
        ])
      );
      dispatch(customDateDiff(0));
      setLabel(event.target.innerText);
      handleClose();
    } else if (event.target.id === "thismonth") {
      setFromDate(moment().startOf("month").format("YYYY-MM-DD 00:00:00"));
      setTodate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"),);
      dispatch(
        treviewDate([
          moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
          moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"),,
        ])
      );
      dispatch(customDateDiff(0));
      setLabel(event.target.innerText);
      handleClose();
    } else if (event.target.id === "custom") {
      handleClose();
      setOpenCustomCal(true);
    }


    auditPayload.message = "Tracking > Map > Selected Period > " + event.target.id;
    auditPayload.action = "View";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);

  };

  //get custom date
  const getCustomDate = (event) => {
    if (event !== undefined && event[0] !== undefined && event[1] !== undefined) {
      setOpenCustomCal(false);
      setLabel(
        `${moment(event[0]).format("YYYY-MM-DD HH:mm:ss")} - ${moment(event[1]).format("YYYY-MM-DD HH:mm:ss")}`
      );
      setFromDate(moment(event[0]).format("DD-MM-YYYY HH:mm:ss"));
      setTodate(moment(event[1]).format("DD-MM-YYYY HH:mm:ss"));
      dispatch(
        treviewDate([
          moment(event[0]).format("YYYY-MM-DD HH:mm:ss"),
          moment(event[1]).format("YYYY-MM-DD HH:mm:ss"),
        ])
      );
      const start = moment(event[0]);
      const end = moment(event[1]);
      let diffDays = end.diff(start, 'days');
      dispatch(customDateDiff(diffDays > 3 ? 0:1));
    }
    else {

    }
  };

  //handle trails mins
  let [trails, setTrails] = useState(1);
  const handleTrailsMins = (event, trailsMins) => {
    setTrails(trailsMins);
  };

  //apply Trails time in a Vehicle
  const applyTrailsVehicle = () => {
    dispatch(trailsTime(trails));
    dispatch(treviewDate([
      moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
      moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
    ])
    );
    handleClose();

    auditPayload.message = "Tracking > Map > Trial View > " + trails + " mins";
    auditPayload.action = "View";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);

  };

  //First Time set Date
  useEffect(() => {
    setFromDate(moment().format("YYYY-MM-DD 00:00:00"));
    setTodate(moment().format("YYYY-MM-DD 23:59:59"));
    dispatch(
      treviewDate([
        moment().format("YYYY-MM-DD 00:00:00"),
        moment().format("YYYY-MM-DD 23:59:59"),
      ])
    );
    dispatch(trailsTime(trails));
    dispatch(customDateDiff(1));
  }, []);


  const { afterToday, allowedRange, allowedMaxDays, combine } = DateRangePicker;
  let currentdate = new Date();
  let last3months = new Date(currentdate.setMonth(currentdate.getMonth() - 3));
  const clickAway = () => {
    setOpenCustomCal(false);
  };



  let text = document.getElementsByClassName('rs-picker-toolbar');
  const addText = () => {
    var type = document.createElement('div')
    type.innerHTML = `<p class='customtext'>Maximum custom range is ${allowDays} days within the last ${allowedMonth} months</p>`
    text[0].prepend(type)
  }

  useEffect(() => {
    if (text.length > 0) {
      addText();
    }
  }, [openCustomCal])



  useEffect(() => {
    let getDatePan = document.getElementsByClassName('rs-picker-daterange-calendar-group');
    if (openCustomCal && time) {
      getDatePan[0]?.classList?.add('addTimerPicker');
    } else if (getDatePan.length > 0) {
      getDatePan[0]?.classList?.remove('addTimerPicker');
    }
  }, [openCustomCal])

  const [showShare, setShowShare] = useState(false)
  const openShare = () => {
    setShowShare(!showShare);
  }

  const closeShare = () => {
    setShowShare(false);
  }

  const aqiData = (level) => {
    switch (level) {
      case 1:
        return "Good";
        break;
      case 2:
        return "Fair";
        break;
      case 3:
        return "Moderate";
        break;
      case 4:
        return "Poor";
        break;
      case 5:
        return "Very Poor";
        break;
    }
  }



  const fitRoad = useMemo(() => {
    return (
      <Paper
        sx={{
          background: `${theme.palette.background.paper} !important`,
          pointerEvents: 'all',
          padding: '5px 5px',
        }}
        elevation={0}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <Box>
            <Typography variant='caption'>Fit to road</Typography>
          </Box>
          <Box>

            <Switch size="small" onChange={handleFitRoad} value={ftr} />
          </Box>
        </Stack>
      </Paper>
    )
  }, [ftr])

  return (
    <MapTopControls>
      <Paper elevation={0} sx={{ backdropFilter: 'blur(0px)' }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ marginRight: { lg: 5, md: 12, sm: 15, pointerEvents: "none" } }}
        >
          <StyledToggleButtonGroup
            className="new-zoom-level-group"
            exclusive
            value={mapzoom}
            onChange={handleZoomLevel}
          >
            {/* <StyledToggleButton value={2}>1x</StyledToggleButton> */}
            <StyledToggleButton value={4}>2X</StyledToggleButton>
            <StyledToggleButton value={8}>4X</StyledToggleButton>
            <StyledToggleButton value={12}>6X</StyledToggleButton>
            <StyledToggleButton value={16}>8X</StyledToggleButton>
          </StyledToggleButtonGroup>

          <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
            {activeFeature === 0 && (
              <Chip
                label={`${min} : ${sec}`}
                color="secondary"
                sx={{ borderRadius: "5px", mr: 1 }}
              />
            )}

            {vehicleTrails !== null ? (
              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>

                {/* <StyledToggleButtonGroup
                  className="new-zoom-level-group"
                  exclusive
                  value={type}
                  onChange={handleMarkerShow}
                  > 
                   
                        <StyledToggleButton value={'all'}>
                            <FaLocationDot  />
                          </StyledToggleButton>
                      <StyledToggleButton value={'charge'}><FaPlug  /></StyledToggleButton>
                      <StyledToggleButton value={'stop'}><FaStopwatch /></StyledToggleButton>
                    
                  </StyledToggleButtonGroup> */}


                {/* <Stack sx={{pointerEvents:'all', background:theme.palette.background.paper, mr:1, borderRadius:'10px', }} direction={'row'} alignItems={'center'} spacing={.2} divider={<Divider orientation="vertical" flexItem/>}>
                    <Tooltip title='Trails' arrow>
                    <Checkbox 
                        icon={<HiOutlineLocationMarker   />}
                        checkedIcon={<HiLocationMarker />}
                        color="secondary"
                        checked={showTrails}
                        onChange={() => handleExtramarkers('all', showTrails)}
                    />
                    </Tooltip>
                    <Tooltip title='Charging' arrow>
                    <Checkbox 
                        icon={<BsLightningCharge  />}
                        checkedIcon={<BsLightningChargeFill />}
                        color="warning"
                        checked={showCharging}
                        onChange={() => {handleExtramarkers('charging', showCharging)
                                          getChargingData(showCharging)
                                        }
                                }
                    />
                    </Tooltip>
                    <Tooltip title='Stoppage' arrow>
                    <Checkbox 
                        icon={<RiTimerLine  />}
                        checkedIcon={<MdTimer />}
                        color="error"
                        checked={showStop}
                        onChange={() => {handleExtramarkers('stoppage', showStop)
                                        getStoppageData(showStop)
                      }}
                    />
                    </Tooltip>
                  </Stack> */}

                { 
                // buttons?.includes('351') &&
                  fromDate === moment().format("YYYY-MM-DD 00:00:00") && toDate === moment().format("YYYY-MM-DD 23:59:59") &&
                  <Paper
                    sx={{
                      background: `${theme.palette.background.paper} !important`,
                      pointerEvents: 'all',
                      padding: '5px 5px',
                    }}
                    elevation={0}
                  >
                    <Stack direction={'row'} alignItems={'center'}>
                      <Box>
                        <Typography variant='caption'>Live traffic</Typography>
                      </Box>
                      <Box>
                        <FormControlLabel
                          sx={{ m: 0 }}
                          control={
                            <Switch size="small" onChange={handleTrafficeLayer} checked={showTraffic} inputProps={{ 'aria-label': 'controlled' }} />
                          }
                        />
                      </Box>
                    </Stack>
                  </Paper>
                 }

                {/* { buttons?.includes('352') && } */}
                <Paper
                  sx={{
                    background: `${theme.palette.background.paper} !important`,
                    pointerEvents: 'all',
                    padding: '5px 5px',
                  }}
                  elevation={0}
                >
                  <Stack direction={'row'} alignItems={'center'}>
                    <Box>
                      <Typography variant='caption'>Low bridges</Typography>
                    </Box>
                    <Box>
                      <FormControlLabel
                        sx={{ m: 0 }}
                        control={
                          <Switch size="small" onChange={handleBridge} checked={bridge} inputProps={{ 'aria-label': 'controlled' }} />
                        }
                      />
                    </Box>
                  </Stack>
                </Paper>
                {/* } */}
                {/* { buttons?.includes('353') && */}
                <Paper
                  sx={{
                    background: `${theme.palette.background.paper} !important`,
                    pointerEvents: 'all',
                    padding: '5px 5px',
                  }}
                  elevation={0}
                >
                  <Stack direction={'row'} alignItems={'center'}>
                    <Box>
                      <Typography variant='caption'>Weigh bridges</Typography>
                    </Box>
                    <Box>
                      <FormControlLabel
                        sx={{ m: 0 }}
                        control={
                          <Switch size="small" onChange={handleWeightBridge} checked={weightBridge} inputProps={{ 'aria-label': 'controlled' }} />
                        }
                      />

                    </Box>
                  </Stack>
                </Paper>
                {/* } */}
              {/* { buttons?.includes('354') && */}
                <Paper
                  sx={{
                    background: `${theme.palette.background.paper} !important`,
                    pointerEvents: 'all',
                    padding: '5px 5px',
                  }}
                  elevation={0}
                >
                  <Stack direction={'row'} alignItems={'center'}>
                    <Box>
                      <Typography variant='caption'>CAZ</Typography>
                    </Box>
                    <Box>
                      <FormControlLabel
                        sx={{ m: 0 }}
                        control={
                          <Switch size="small" onChange={handleCaz} checked={caz} inputProps={{ 'aria-label': 'controlled' }} />
                        }
                      />
                    </Box>
                  </Stack>
                </Paper>
                {/* } */}
                {/* {snapedTrail.length > 0 && snapedTrail !== undefined && fitRoad} */}
                {/* { buttons?.includes('355') && */}
                <Paper
                  sx={{
                    background: `${theme.palette.background.paper} !important`,
                    pointerEvents: 'all',
                    padding: '5px 5px',
                  }}
                  elevation={0}
                >
                  <Stack direction={'row'} alignItems={'center'}>
                    <Box>
                      <Typography variant='caption'>Tolls</Typography>
                    </Box>
                    <Box>
                      <FormControlLabel
                        sx={{ m: 0 }}
                        control={
                          <Switch size="small" onChange={handleToll} checked={toll} inputProps={{ 'aria-label': 'controlled' }} />
                        }
                      />
                    </Box>
                  </Stack>
                </Paper>
                {/* } */}
                {/* <Paper
                  sx={{
                    background:`${theme.palette.background.paper} !important`,
                    pointerEvents:'all',
                    padding:'5px 5px',
                  }}
                  elevation={0}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Box>
                            <Typography variant='caption'>Air Quality</Typography>
                        </Box>
                        <Box>
                            <Switch size="small" onChange={handleTrafficeLayer} value={showTraffic}/>
                        </Box>
                    </Stack>
                </Paper> */}
                {/* if selected a single trails show Date picker - Start */}
                <ClickAwayListener
                  onClickAway={clickAway}
                >
                  <StyledMapDatePiker
                    elevation={0}
                    sx={{ pointerEvents: "all" }}
                  >
                    <DateRangePicker
                      format={timeHide ? 'dd-MM-yyyy HH:mm:ss' : 'dd-MM-yyyy'}
                      disabledDate={combine(
                        allowedMaxDays(allowDays),
                        allowedRange(last3months,moment().subtract(0, 'days'))
                      )}
                      placeholder="Select Date Range"
                      placement="bottomEnd"
                      open={openCustomCal}
                      onOk={(event) => getCustomDate(event)}
                      size="xs"
                      ranges={[]}
                      className={`CustomDateRangePicker ${timeHide && 'hideTime'}`}
                      preventOverflow={true}
                      value={[new Date(moment().format('YYYY-MM-DD 00:00:00')), new Date(moment().format('YYYY-MM-DD 23:59:59'))]}
                    />
                    <InputBase
                      value={label}
                      readOnly
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={(event) => handleClick(event)}
                    />
                    <Icon
                      sx={{
                        width: "2rem",
                        height: "2rem",
                        color: theme.palette.grey[600],
                      }}
                    >
                      <HiOutlineCalendar />
                    </Icon>
                    {/* Datepicker options */}
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      PaperProps={{
                        sx: {
                          "&.MuiPaper-root": {
                            width: "250px",
                          },
                          "& .MuiButtonBase-root ": {
                            fontWeight: 400,
                          },
                        },
                      }}
                    >
                      <ListItemButton
                        onClick={(event) => selectDate(event)}
                        id="today"
                        dense={true}
                        selected={label === "Today"}
                      >
                        Today
                      </ListItemButton>
                      <ListItemButton
                        onClick={(event) => selectDate(event)}
                        id="yesterday"
                        dense={true}
                        selected={label === "Yesterday"}
                      >
                        Yesterday
                      </ListItemButton>
                      <ListItemButton onClick={(event) => selectDate(event)} id='last7days' dense={true} selected={label === 'Last 7 Days'}>Last 7 Days</ListItemButton>
                      <ListItemButton
                        onClick={(event) => selectDate(event)}
                        id="thismonth"
                        dense={true}
                        selected={label === "Current Month"}
                      >
                        Current Month
                      </ListItemButton>
                      <ListItemButton
                        onClick={(event) => selectDate(event)}
                        id="lastmonth"
                        dense={true}
                        selected={label === "Last Month"}
                      >
                        Last Month
                      </ListItemButton>
                      <ListItemButton
                        onClick={(event) => selectDate(event)}
                        id="custom"
                        dense={true}
                        selected={
                          label !== "Today" &&
                          label !== "Yesterday" &&
                          label !== "Last 7 Days" &&
                          label !== "Last Month" &&
                          label !== "Current Month"
                        }
                      >
                        Custom
                      </ListItemButton>
                      {/* <Divider />
                      <ListItem dense={true}>
                        <ListItemText>Trail View</ListItemText>
                      </ListItem>
                      <ListItem dense={true}>
                        <RadioGroup
                          row
                          defaultValue={trails}
                          onChange={handleTrailsMins}
                          sx={{
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormControlLabel
                            value={1}
                            control={
                              <Radio
                                size="small"
                                sx={{ "&": { padding: "0px" } }}
                                color="secondary"
                              />
                            }
                            label="1 min"
                            labelPlacement="bottom"
                            sx={{
                              margin: "0px 2px",
                              "& .MuiFormControlLabel-label": {
                                fontSize: "12px",
                                fontWeight: "400",
                              },
                            }}
                          />
                          <FormControlLabel
                            value={10}
                            control={
                              <Radio
                                size="small"
                                sx={{ "&": { padding: "0px" } }}
                                color="secondary"
                              />
                            }
                            label="10 mins"
                            labelPlacement="bottom"
                            sx={{
                              margin: "0px 2px",
                              "& .MuiFormControlLabel-label": {
                                fontSize: "12px",
                                fontWeight: "400",
                              },
                            }}
                          />
                          <FormControlLabel
                            value={60}
                            control={
                              <Radio
                                size="small"
                                sx={{ "&": { padding: "0px" } }}
                                color="secondary"
                              />
                            }
                            label="1 Hour"
                            labelPlacement="bottom"
                            sx={{
                              margin: "0px 2px",
                              "& .MuiFormControlLabel-label": {
                                fontSize: "12px",
                                fontWeight: "400",
                              },
                            }}
                          />
                        </RadioGroup>
                      </ListItem>
                      <ListItem dense={true}>
                        <Stack
                          spacing={0.2}
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Button
                            onClick={applyTrailsVehicle}
                            size="small"
                            type="submit"
                            color="success"
                            variant="contained"
                          >
                            Apply
                          </Button>
                          <Button
                            onClick={handleClose}
                            size="small"
                            type="submit"
                            color="error"
                            variant="plain"
                          >
                            {" "}
                            Cancel{" "}
                          </Button>
                        </Stack>
                      </ListItem> */}
                    </Menu>
                  </StyledMapDatePiker>
                </ClickAwayListener>
                {/* if selected a single trails show Date picker - End */}
                <SoftButton
                  variant="contained"
                  size="small"
                  color="info"
                  startIcon={<RxReload />}
                  className="map-btn"
                  onClick={refetchSingleVehicle}
                >
                  Refresh
                </SoftButton>
                {/* <SoftButton
                  variant="contained"
                  size="small"
                  color="warning"
                  startIcon={<RxShare1 />}
                  className="map-btn"
                  onClick={openShare}
                >
                  Share
                </SoftButton> */}
              </Stack>
            ) : (
              <Stack direction={'row'} spacing={1}>
                <SoftButton
                  variant="contained"
                  size="small"
                  color="info"
                  startIcon={<RxReload />}
                  className="map-btn"
                  onClick={mapRefetch}
                >
                  Refresh
                </SoftButton>
              </Stack>


            )}
          </Stack>
        </Stack>
      </Paper>
      <VehicleShare showShare={showShare} closeShare={closeShare} />
      {/* <Paper sx={{position:'fixed', top:'200px', right:'20px', width:'auto', height:'auto', background:'#fff !important', p:1}}>
            <Stack direction={'row'} spacing={1}>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Icon sx={{display:'flex', alignItems:'center'}}>
                      <TbAirConditioning />
                    </Icon>
                </Box>
                <Box>
                    <Typography variant="caption">AQI - {airData?.main?.aqi} </Typography>
                    <Typography variant="body1">{aqiData(airData?.main?.aqi)}</Typography>
                </Box>
            </Stack>
      </Paper>  */}

      {/* <Paper sx={{position:'fixed', top:'200px', right:'20px', width:'auto', height:'auto', background:'#fff !important', p:1}}>
            <Stack direction={'row'} spacing={1}>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Icon sx={{display:'flex', alignItems:'center'}}>
                   <FiZoomIn/> 
                    </Icon>
                </Box>
                <Box>
                <Typography variant="body1" sx={{color: `${theme.palette.secondary.main}`}}>{mapzoom}</Typography>
                </Box>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Icon sx={{display:'flex', alignItems:'center'}}>
                    <FiZoomOut/> 
                    </Icon>
                </Box>
            </Stack>
      </Paper>  */}
    </MapTopControls>
  );
}

export default MapControls;
