import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, IconButton, Menu, MenuItem, Paper, Slider, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { IoPause, IoPlay, IoPlaySkipBack, IoPlaySkipForward, IoRepeat, IoStopCircle,IoTimer } from "react-icons/io5";
import Seekbar from './seekbar';
import { Odometer, SpeedMeter, SteeringRotation, TripDate, VehicleStatus, ViewSummary } from './CurrentStatus';
import { Data } from '@react-google-maps/api';
import Chart from './Chart';
import { MdOutlineExpandMore } from 'react-icons/md';

function Controls(props) {
  const { play, stop, pause, playStatus, counter, handleChange, currentTime, timeCode, rotation, speed, odometer, startOdo, distanceT, status, value, startDate, endDate, rawData, getDate, date, newData, speedHandler, speedValue=220, forwardHandler, backwardHandler, timerSeries, valueY} = props;
  const theme =  useTheme();
  ///////////////////////// Speed ///////////////////////////////////
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const speedClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const speedClose = (speed) => {
      setAnchorEl(null);
      speedHandler(speed);
    };
  return (
    <Stack
    sx={{
        //position:'absolute',
        //bottom:'10px',
        //left:'50%',
        //zIndex:999,
        //alignItems:'end',
        //width:'calc(100% - 20%)',
        //transform:'translateX(-50%)',
        // [theme.breakpoints.down('xl')]:{
        //   transform:'translateX(-50%) scale(.8)',
        //   width:'100%',
        // },
        // [theme.breakpoints.only('xl')]:{
        //   transform:'translateX(-50%) scale(.9)',
        //   width:'calc(100% - 2%)',
        // },
    }}
    direction={'column'}
    spacing={1}
    >
      <Box sx={{width:'100%'}}>
          <TripDate startDate={rawData?.data?.tripStartDate} endDate={rawData?.data?.tripEndDate} tripData={rawData?.data} getDate={getDate} date={date}/>
          <Paper sx={{
        width:'100%',
        boxShadow:'0px 0px 8px rgba(0,0,0,0) !important',
        padding:'10px 15px',
        height:'fit-content',
        border:'0.5px solid #ddd'
    }}>
        <Box>
            <Box sx={{width:"100%", pt:1}}>
              {/* <Chart /> */}
                <Seekbar playStatus={playStatus} counter={counter} handleChange={handleChange} currentTime={currentTime} timeCode={timeCode} newData={newData}/>
                {/* <Box
                    sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}
                >
                    <Typography sx={{
                        fontSize: '0.75rem',
                        opacity: 0.38,
                        fontWeight: 500,
                        letterSpacing: 0.2,
                        mr:1,
                        textWrap:'nowrap'
                    }}>{formatDuration(position)}</Typography>
                      <Slider 
                    aria-label="time-indicator"
                    size="small"
                    value={position}
                    min={0}
                    step={1}
                    max={duration}
                    onChange={(_, value) => setPosition(value)}
                    sx={(t) => ({
                        color: 'rgba(0,0,0,0.87)',
                        height: 4,
                        '& .MuiSlider-thumb': {
                          width: 8,
                          height: 8,
                          transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                          '&::before': {
                            boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                          },
                          '&:hover, &.Mui-focusVisible': {
                            boxShadow: `0px 0px 0px 8px ${'rgb(0 0 0 / 16%)'}`,
                            ...t.applyStyles('dark', {
                              boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'}`,
                            }),
                          },
                          '&.Mui-active': {
                            width: 20,
                            height: 20,
                          },
                        },
                        '& .MuiSlider-rail': {
                          opacity: 0.28,
                        },
                        ...t.applyStyles('dark', {
                          color: '#fff',
                        }),
                      })}
                />
                    <Typography
                    sx={{
                        fontSize: '0.75rem',
                        opacity: 0.38,
                        fontWeight: 500,
                        letterSpacing: 0.2,
                        ml:.2,
                        textWrap:'nowrap'
                    }}
                    >-{formatDuration(duration - position)}</Typography>
                </Box> */}
            </Box>
            <Stack direction={'row'} sx={{alignItems:'center', justifyContent:'space-evenly'}}>
                <Box>
                    <Tooltip title={'Repeat'} arrow={true}>
                      <IconButton onClick={pause} size='small'>
                      <IoRepeat />
                      </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title={'backward'} arrow={true}>
                      <IconButton onClick={backwardHandler} size='small'>
                        <IoPlaySkipBack />
                      </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                  <Tooltip title={!playStatus ? 'Play' : 'Pause'} arrow={true}>
                    <IconButton onClick={play} size='small' sx={{background:theme.palette.primary.dark, color:theme.palette.primary.contrastText}}>
                      {
                        !playStatus ? <IoPlay /> : <IoPause />
                      }
                        
                    </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title={'Forward'} arrow={true}>
                      <IconButton onClick={forwardHandler} size='small'>
                        <IoPlaySkipForward />
                      </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                  <Tooltip title={'Speed'} arrow={true}>
                    <IconButton
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={speedClick}
                      size='small'
                    >
                        <IoTimer />
                    </IconButton>
                    </Tooltip>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => speedClose(speedValue)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => speedClose(220)} selected={speedValue === 220}>0x</MenuItem>
                        <MenuItem onClick={() => speedClose(420)} selected={speedValue === 420} >1X</MenuItem>
                        <MenuItem onClick={() => speedClose(620)} selected={speedValue === 620} >2X</MenuItem>
                        <MenuItem onClick={() => speedClose(820)} selected={speedValue === 820} >3X</MenuItem>
                    </Menu>
                </Box>
            </Stack>
           
        </Box>
          </Paper>
      </Box>
    
    <Paper
      sx={{
        boxShadow:'0px 0px 8px rgba(0,0,0,0) !important',
        padding:'5px 0px',
        height:'fit-content',
        border:'0.5px solid #ddd'
      }}
    >
      <VehicleStatus status={status}/>
      <Divider />
      <Stack direction={'row'} spacing={1} sx={{width:'100%', px:1, pt:1}}>
        <SpeedMeter speed={speed}/>
        <Box sx={{width:'100%'}}>
          <Odometer odometer={odometer} startOdo={startOdo} distanceT={distanceT}/>
        </Box>
      </Stack>
      
      {/* <Stack direction={'row'} spacing={3}>
                    
                    <Odometer odometer={odometer} startOdo={startOdo} distanceT={distanceT}/>
                     <SteeringRotation rotation={rotation}/> 
                </Stack> */}
    </Paper>
    <Paper
      sx={{
        boxShadow:'0px 0px 8px rgba(0,0,0,0) !important',
        padding:'5px 0px',
        height:'fit-content',
        border:'0px solid #ddd',
        background:'transparent !important'
      }}
    >
      {/* <ViewSummary tripData={rawData?.data}/> */}
      <Accordion elevation={0}>
          <AccordionSummary 
          sx={{'&.Mui-expanded':{minHeight:'40px !important'},'& .Mui-expanded':{margin:'0 !important'}}}
          expandIcon={<MdOutlineExpandMore />}
          >
              View Summary
          </AccordionSummary>
          <AccordionDetails>
              <ViewSummary tripData={rawData?.data}/>
          </AccordionDetails>
      </Accordion>
    </Paper>
    <Paper
      sx={{
        boxShadow:'0px 0px 8px rgba(0,0,0,0) !important',
        padding:'5px 0px',
        height:'fit-content',
        border:'0.5px solid #ddd',
        width:'100%'
      }}
    >
        <Chart timerSeries={timerSeries} valueY={valueY}/>
    </Paper>
    {/* <SteeringRotation rotation={rotation}/> */}
    </Stack>
  )
}

export default Controls