import {
  CardContent
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import {
  useAuditLogMutation,
  useVehicleSummaryMutation,
} from "../../store/slice/ApiSlice";
import {
  StyledCardShadow,
  StyledPageLayout
} from "../../theme/StyledEle";
import GetLocation from "../Location";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { DataTable } from "../shared/data_table/DataTable";
import MouseOverPopover from "../shared/PopOver";
import {chatBotSingleVehicleData } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";

const text =
  "This Report gives the Summarized data about the vehicle movement for a selected time period.";

const VehicleSummaryReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let menuUser = null;
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;
  //let distanceUnit = "mi" ;
  let distanceUnit = userData.distanceDisplayUnit ;
  let speedUnit = userData.speedDisplayUnit ;
  let distanceValue = distanceUnit === "mi"?2:1;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPeriod, setSelectedPeriod] = useState(1);
  const [auditLog] = useAuditLogMutation();
  const dispatch = useDispatch();
  const [checkNote,setCheckNote] =useState();

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  const theme = useTheme();

  const [commonFilter, setCommonFilter] = React.useState({
    ReportChatbot: false,

  });

  const callfun = () => {
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                "VehicleSummary"
              ) {
             
                var dataStr = {};
                dataStr.ReportChatbot = false;
                
                var strval;
                strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                  j
                ].button.split(",");
                for (var k = 0; k < strval.length; k++) {
                  if (parseInt(strval[k]) === 356) {
                    dataStr.ReportChatbot = true;
                  }
                }
                setCommonFilter(dataStr);
                break;
              }
            }
          }
        }
      }
    }
  }

  useEffect(async () => {
    // await fetchVehicleSummaryReportDetails();
    // var request = {};
    // request.message = "Reports > VehicleSummary";
    // request.action = "View";
    // request.featureName = "VehicleSummary";
    // request.customerName = "All";
    // request.fleetName = "All";
    // request.result = "Success";
    // request.access = "Portal";
    // request.severity = "INFO";
    // request.orgId = globalOrgId;
    // request.userId = globaluserId;
    // request.orgName = globalOrgName;
    // request.userName = globalUserName;
    // const responseData = await logServices.saveAuditLogDetails(request);
    callfun();

    auditPayload.message = "Reports >  Vehicle Summary";
    auditPayload.action = "View";
    auditPayload.featureName = " Vehicle Summary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);

  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleNo",
      },
      {
        Header: "VIN",
        accessor: "vinNumber",
      },
      // {
      //   Header: "Start Time",
      //   accessor: "tripStartTimeOfTheDay",
      // },
      // {
      //   Header: "End Time",
      //   accessor: "tripEndTimeOfTheDay",
      // },
      {
        Header: "Start Location",
        accessor: "startLocation",
        width:180,
        Cell: ({ row }) => {
          return(
            row.original.startLocation !== "" &&   row.original.startLocation !== "NA" ?
          <GetLocation row={row} columnName={row.original.startLocation}/> : '-'
          )
        }
      },
      {
        Header: "End Location",
        accessor: "endLocation",
        width:180,
        Cell: ({ row }) => {
          return(
            row.original.endLocation !== "" &&   row.original.endLocation !== "NA" ?
          <GetLocation row={row} columnName={row.original.endLocation}/> : '-'
          )
        }
      },
      {
        Header: "Total Hours (hh:mm)",
        columns: [
          {
            Header: "Moving (hh:mm)",
            accessor: "moveMinutes",
          },
          {
            Header: "Idle (hh:mm)",
            accessor: "idleMinutes",
          },
          {
            Header: "Stop (hh:mm)",
            accessor: "stopHours",
          },
        ],
      },
      {
        Header: "Distance Traveled ("+distanceUnit +")" ,
        columns: [
          {
            Header: "Start Odometer (" +distanceUnit +")",
            accessor: "startOdometer",
          },
          {
            Header: "End Odometer ("+distanceUnit +")",
            accessor: "endOdometer",
          },
          {
            Header: "Distance Traveled ("+distanceUnit +")",
            accessor: "distanceTraveled",          
          },
        ],
      },
      {
        Header: "Harsh Usage (count)",
        columns: [
          {
            Header: "Acceleration",
            accessor: "harshAccelerationCount",
          },
          {
            Header: "Brake",
            accessor: "harshbrakeCount",
          },
        ],
      },
      {
        Header: "OverSpeeding",
        columns: [
          {
            Header: "Count",
            accessor: "overSpeedCount",
          },
          {
            Header: "Duration (hh:mm)",
            accessor: "overSpeedDurationstr",
          },
        ],
      },
      {
        Header: "Maximum Speed ("+speedUnit+"ph)",
        accessor: "maxSpeed",
      },
      // {
      //   Header: "Engine Run (hh:mm)",
      //   accessor: "engineRunMinsstr",
      // },
      {
        Header: "Fuel Consumed (L)",
        accessor: "fuelConsumed",
      },
      {
        Header: "Mileage ("+distanceUnit +")/L",
        accessor: "fuelMileage",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
        width:120
      },
    ],
    []
  );

  // let initialState1 = {};
  // var arr = [];

  // if (resellerId !== 110224) {
  //   arr.push("batteryEfficiency");
  //   arr.push("targetedDistance");

  //   arr.push("stopEnergyConsumedinpark");
  //   arr.push("overSpeedSOCConsumed");
  //   arr.push("overSpeedDistance");
  //   arr.push("customerName");
  //   arr.push("movingEnergyConsumed");
  //   initialState1.hiddenColumns = arr;
  // }


  //*********** chat Bot Start **************

  const openSummaryWindow1 = (data) => {
  console.log("vsr data=>",data);
    var payload={
      "mapSignal":"Report Chat Bot",
      "option":"Vehicle Performance",
      "vehicleNo":data.vehicleNo,
      "distanceTraveled":data.distanceTraveled,
      "fuelConsumed":data.fuelConsumed,
      "avgSpeed":data.avgSpeed,
      "harshAccelerationCount":data.harshAccelerationCount,
      "harshbrakeCount":data.harshbrakeCount,
      "overSpeedCount":data.overSpeedCount,
      "maxSpeed":data.maxSpeed,
      
    }
    dispatch(chatBotSingleVehicleData(payload));
  }
  // ********** End *************************

  // Get Data from New Dropdown

  const [getOverSpeed, { data: vehicleSummaryData, isLoading, isSuccess }] =
    useVehicleSummaryMutation();
    const [payload, setPayload] = useState(null);
    const [rawPayload, setRawPayload] = useState(null);
  let commonPayload = {};
  const onSubmitForm = (data) => {

    setRawPayload(data);
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerOrgId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId !== undefined ? data.fleetId : data.orgId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate !== undefined ? data.fromDate : data.startDate;
    commonPayload.endDate = data.toDate !== undefined ? data.toDate : data.endDate ;
    commonPayload.sortDir = "desc";
    commonPayload.page = 0;
    commonPayload.size = 25;
    commonPayload.selperiod = data.selectedPriod !== undefined ? data.selectedPriod : data.selperiod;
    // commonPayload.distanceUnit = distanceUnit
    //Service Call
    // dashboardGen(data);
    setPayload(commonPayload);
    getOverSpeed(commonPayload);
   

    // auditPayload.message = "Reports > Vehicle Summary > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    // auditPayload.action = "Submit";
    // auditPayload.featureName = "Vehicle Summmary Report";
    // auditPayload.customerName = "All";
    // auditPayload.fleetName = "All";
    // auditPayload.result = "Success";
    // auditPayload.access = "Portal";
    // auditPayload.severity = "INFO";
    // auditPayload.userName = userName;
    // auditLog(auditPayload);
  };


  //  const dashboardGen = (formData) => {

  //   var regionId = "";
  //   var customerId = "";
  //   var dealerId = "";
  //   var fleetId = "";
  //   var vehicleId = "";
  //   if (formData !== null) {
  //     if (formData?.regionId !== "-1") {
  //       regionId = '"REGION_ID"=' + formData?.regionId + " and ";
  //     } else {
  //       regionId = '"REGION_ID"!=5' + "and";
  //     }

  //     if (formData?.dealerId !== "-1") {
  //       dealerId = '"DEALER_ID"=' + formData?.dealerId + " and ";
  //     } else {
  //       dealerId = "";
  //     }

  //     if (formData?.customerId !== "-1") {
  //       customerId = '"CUSTOMER_ID"=' + formData?.customerId + " and ";
  //     } else {
  //       customerId = "";
  //     }

  //     if (formData?.fleetId !== "-1" && payload?.orgId !== '-1') {
  //       let fleet = formData?.fleetId !== undefined ? formData?.fleetId : payload?.orgId
  //       fleetId = '"org_id"=' + fleet + " and ";
  //     } else {
  //       fleetId = "";
  //     }
  //     if (formData?.vehicleId !== "-1") {
  //       vehicleId = '"vehicle_id"=' + formData?.vehicleId + " and ";
  //     } else {
  //       vehicleId = "";
  //     }
  //   }

  //   //Set URL
  //   setDashboardUrl(
  //     "https://analytics.zoho.com/open-view/2399935000014921700?ZOHO_CRITERIA=" +
  //     regionId +
  //     dealerId +
  //     customerId +
  //     fleetId +
  //     vehicleId +
  //     " RESELLER_ID =" +
  //     resellerId + ' AND "VW_zoho_monthly_vs_DW"."z_created_date" BETWEEN ' + `'${formData?.fromDate !== undefined ? formData?.fromDate :payload?.startDate }'` + " AND " + `'${formData?.toDate !== undefined ? formData?.toDate : payload?.endDate}'` +
  //     ""
  //   )

   
  // }

    //Export PDF & Excel

    const onChangeXcl = async () => {
      try {        
          var url =
          process.env.REACT_APP_API_BASE_URL +
          "VehicleSummaryReport/VehicleSummaryReportcsv.csv?regionId=" +
          payload.regionId +
          "&resellerOrgId=" +
          payload.resellerOrgId +
          "&dealerId=" +
          payload.dealerId +
          "&customerId=" +
          payload.customerId +
          "&orgId=" +
          payload.orgId +
          "&vehicleId=" +
          payload.vehicleId +
          "&size=" +
          payload.size +
          "&page=" +
          payload.page +
          "&startDate=" +
          payload.startDate +
          "&endDate=" +
          payload.endDate +
          "&selperiod=" +
          payload.selperiod +
          "&sortDir=" +
          payload.sortDir +
          "&search=" +
          payload.search +
          "&distanceUnit=" +
          distanceUnit +
          "&distanceDisplayOption=" +
          distanceValue;
console.log(url);
          window.open(url);
        
       auditPayload.message = 'Reports >  Vehicle Summary > XLS';
       auditPayload.action = 'View';
       auditPayload.featureName = 'Vehicle Summary';
       auditPayload.customerName = 'All';
       auditPayload.fleetName = 'All';
       auditPayload.result = 'Success';
       auditPayload.access = 'Portal';
       auditPayload.severity = 'INFO';
       auditLog(auditPayload);
       
      } catch (error) {}
    };
    const onChangePDF = async () => {
  
      try {
             var url =
          process.env.REACT_APP_API_BASE_URL +
          "VehicleSummaryReport/vehicleSummaryReportAll.Pdf?regionId=" +
          payload.regionId +
          "&resellerOrgId=" +
          payload.resellerOrgId +
          "&dealerId=" +
          payload.dealerId +
          "&customerId=" +
          payload.customerId +
          "&orgId=" +
          payload.orgId +
          "&vehicleId=" +
          payload.vehicleId +
          "&size=" +
          payload.size +
          "&page=" +
          payload.page +
          "&startDate=" +
          payload.startDate +
          "&selperiod=" +
          payload.selperiod +
          "&endDate=" +
          payload.endDate +
          "&orderDir=" +
          payload.sortDir +
          "&search=" +
          payload.search;
          window.open(url);

  
        auditPayload.message = 'Reports > Vehicle Summary > PDF';
        auditPayload.action = 'View';
        auditPayload.featureName = ' Vehicle Summary';
        auditPayload.customerName = 'All';
        auditPayload.fleetName = 'All';
        auditPayload.result = 'Success';
        auditPayload.access = 'Portal';
        auditPayload.severity = 'INFO';
        auditLog(auditPayload);
      } catch (error) {}
    };
   //Search Text
   const handleSearch = async (searchText) => {
        setNewPage(0);
        setRowsPerPage(25);
        payload.page = 0;
        payload.rowsPerPage = 25;
       payload.search = searchText;
       if(payload.search.length >= 3){
         getOverSpeed(payload);
       }else if(payload.search.length === 0){
         getOverSpeed(payload);
       }    
   };
   let initialState1 = {};
   var arr = [];
   //Page Change
   const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getOverSpeed(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getOverSpeed(payload);
    }
  };

   useEffect(() => {
     if(isSuccess){
       setResponseData([]);
       if(vehicleSummaryData.data !== null){
        setResponseData(vehicleSummaryData.data.content);
        setTotalElements(vehicleSummaryData.data.totalElements);
        setTotalPages(vehicleSummaryData.data.totalPages);
      }

    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Vehicle Summary Report"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Vehicle Summary Report"} />
      <Box>
        <FilterDropdown
          getData={onSubmitForm}
          status={false}
          allowDays = {payload!==null && payload.vehicleId !=='-1'?31:1}
          time = {true}
          last6Month={false}
          allowedMonth = {3}
          thisMonth={true}
          currentMonth={true}
          last7={true}
          getDataLoading={isLoading}
          offToday={true}
        />
        <StyledCardShadow sx={{ mt: 2 }}>
            <CardContent sx={{ py: 1 }}>
              <DataTable
                payload={payload}
                exportWithAll={true}
                initialState={initialState1}
                isLoading={isLoading}
                columns={columns}
                data={responseData}
                styles={{ maxWidth: "100vw" }}
                numberOfStickyColumns={1}
                onChangeXcl={onChangeXcl}
                onChangePdf={onChangePDF}
                includeSearchBox={true}
                handleChange={handleSearch}
                page={newPage}
                rowsPerPage={rowsPerPage}
                callbackChangePage={pageChange}
                totalElements={totalElements}
                totalPages={totalPages}
                commonFilter={commonFilter}
                onReportChatbot={openSummaryWindow1}
              />
            </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default VehicleSummaryReport;
