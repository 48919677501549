import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography, alpha, styled, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IoSpeedometerOutline } from 'react-icons/io5';
import { MdOutlinePower, MdOutlineTireRepair } from 'react-icons/md';
import { SoftButton, StyledFilterDropDown, StyledFiterButton } from '../../../theme/StyledEle';
import { HiFolderDownload } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuditLogMutation } from '../../../store/slice/ApiSlice';
import { BiDownArrowCircle } from 'react-icons/bi';
import SummaryPdf from './SummaryPdf';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import { snackService } from '../../../store/slice/ControlsSlice';
import moment from 'moment';
import { Empty } from 'antd';
import { ScaleLoader } from 'react-spinners';

function VehicleSummary({data, address, categoryOrgId, categoryVehicleId, showCategory, isLoading}) {
  
    let { summaryData, vehicleTrails, commonData } = data;
    const theme = useTheme();
    const [innerTab, setInnerTab] = useState(1);
    const date = useSelector((state) => state.controls.treeview.date);
    const trailsTime = useSelector((state) => state.controls.treeview.trailsTime);
    const userData = JSON.parse(localStorage.getItem("userData"));
    let distanceUnit = userData.distanceDisplayUnit ;

    const [auditLog] = useAuditLogMutation();

    const NestedListItemButton = styled(ListItemButton)(({ theme }) => ({
            borderRadius:'5px',
            '&.Mui-selected':{
                background:innerTab === 1 ? alpha(theme.palette.info.main, .8) : innerTab === 2 ? alpha(theme.palette.success.main, .8) : alpha(theme.palette.warning.main, .8),
                
                '& .MuiListItemText-root':{
                    '& .MuiTypography-root':{
                        color:theme.palette.primary.contrastText,
                    }
                },
                '& .MuiListItemIcon-root':{
                    color:theme.palette.primary.contrastText
                }
            },
            '& .MuiListItemIcon-root':{
                minWidth:'auto',
                fontSize:'20px',
                marginRight:'10px',
            }
    }))

    //Accordion
    const StyledAccordion = styled(Accordion)(({theme}) => ({
        boxShadow:'none !important',
        position:'sticky',
        top:'0px',
        '&.export':{
            '& .MuiAccordionSummary-root':{
                background:`${alpha(theme.palette.success.main, .08)} !important`,
                
            }
        },
        '& .MuiAccordionSummary-root':{
            background:`${alpha(theme.palette.info.main, .08)} !important`,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper': {
                marginRight: `${theme.spacing(1)} !important`,
            },
            '&.Mui-expanded':{
                minHeight:'auto !important'
            }
        },
        '& .Mui-expanded':{
            //margin:'0px !important',
        },
        '& .MuiAccordionSummary-content':{
            '&.Mui-expanded':{
                margin:'12px 0px !important',
            }
        },
        '& .MuiAccordionDetails-root':{
            padding:'10px 10px 10px'
        }
    }))


    const Statstic = ({label, value, icon, color}) => {
        return(
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box sx={{fontSize:'16px'}}>{icon}</Box>
                <Box>
                    <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px'}}>{label}</Typography>
                    <Typography variant='subtitle2' color={color}>{value}</Typography>
                </Box>
            </Stack>
        )
    }

    //Vehicle Summary
    const VehicleSum = () => {
        return(
           <Box sx={{p:.5}}>
                <StyledAccordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<BiDownArrowCircle />}
                    >
                            <Typography>Vehicle Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{background:''}}>
                        <Grid container rowSpacing={1}>
                            {/* <Grid item lg={6}>
                                <Statstic label={'Start Date'} value={commonData[0]?.startDate}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'End Date'} value={commonData[0]?.lastDate}/>
                            </Grid> */}
                            <Grid item lg={6}>
                                <Statstic label={'Start Odometer ('+distanceUnit+')'} value={summaryData[0]?.startOdometer}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'End Odometer ('+distanceUnit+')'} value={summaryData[0]?.endOdometer}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Distance Traveled ('+distanceUnit+')'} value={summaryData[0]?.distanceTraveled}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Average Speed ('+distanceUnit+'/h)'} value={summaryData[0]?.avgSpeed}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Max Speed ('+distanceUnit+'/h)'} value={summaryData[0]?.maxSpeed}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Moving (hh:mm)'} value={summaryData[0]?.moveMinutes} color={'success'}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Stop (hh:mm)'} value={summaryData[0]?.stopHours} color={'error'}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Idle (hh:mm)'} value={summaryData[0]?.idleMinutes} color={'error'}/>
                            </Grid>
                            <Grid item lg={6}>
                                <Statstic label={'Offline (hh:mm)'} value={summaryData[0]?.offlineHours} color={'error'}/>
                            </Grid>
                            
                        </Grid>
                    </AccordionDetails>
                </StyledAccordion>
           </Box>
        )
    }


    //Driver behaivoiur
    const DriverBehivour = () => {
        return(
            <Box sx={{p:.5}}>
                <StyledAccordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<BiDownArrowCircle />}
                    >
                            <Typography>Driver Behaviour</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{background:''}}>
                        <Grid container rowSpacing={1}>
                            <Grid item lg={12}>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant='body2' gutterBottom sx={{p:.4, fontWeight:500}}>Overspeed</Typography>
                                </Stack>
                            </Grid>
                            <Grid item lg={6}> 
                                <Statstic label={'Count'} value={summaryData[0]?.overSpeedCount}/>
                            </Grid>
                            {/* <Grid item lg={6}> 
                                <Statstic label={'Distance (km)'} value={summaryData[0]?.overSpeedDistance}/>
                            </Grid> */}

                            <Grid item lg={6}>
                                <Statstic label={'Duration (hh:mm)'} value={summaryData[0]?.overSpeedHours}/>
                            </Grid>

                            {/* <Grid item lg={12}>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant='body2' gutterBottom sx={{p:.4, fontWeight:500}} color={'error'}>Harsh Alerts</Typography>
                                    <Tooltip title={'Total count'}>
                                        <Chip color='error' label={summaryData[0]?.harshAlertCount} size='small' sx={{fontSize:'12px', height:'20px'}}/>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            
                            <Grid item lg={4}>
                                <Statstic label={'Accelerating'} value={summaryData[0]?.harshAccelerationCount}/>
                            </Grid>
                            <Grid item lg={4}>
                                <Statstic label={'Braking'} value={summaryData[0]?.harshbrakeCount}/>
                            </Grid>
                            <Grid item lg={4}>
                                <Statstic label={'Cornering'} value={'--'}/>
                            </Grid> */}
                        </Grid>
                    </AccordionDetails>
                </StyledAccordion>
           </Box>
        )
    }

    
    const selectVehicleData = useSelector((state) => state.controls.singleVehicleData);
    const onChangeXcl = async () => {
        let startDate = showCategory ? date[0] : selectVehicleData?.strDate;
        let endDate = showCategory ? date[1] :  selectVehicleData?.endDate ;
        let orgId = showCategory ? categoryOrgId : selectVehicleData?.orgId;
        let vehicleId = showCategory ? categoryVehicleId : selectVehicleData?.vehicleId;

        try {
         var url =
         process.env.REACT_APP_API_BASE_URL +
         "ManualCanDataReport/categoryVehicledataForMontra.csv?startDate=" +
         startDate+
         "&endDate=" +
         endDate+
         "&orderDir=" +
         'desc' +
         "&orgId=" +
         orgId +
         "&status="+"9"+
        // vehicleTrails?.commonData[0]?.propVehicleStatus +
         "&resellerId=" +userData.resellerId+
         "&vehicleId=" +
         vehicleId;
         window.open(url);
         let auditPayload={};
         auditPayload.message = 'MapView > VehicleSummary > CSV';
         auditPayload.action = 'View';
         auditPayload.featureName = 'VehicleSummary';
         auditPayload.customerName = 'All';
         auditPayload.fleetName = 'All';
         auditPayload.result = 'Success';
         auditPayload.access = 'Portal';
         auditPayload.severity = 'INFO';
         
         auditLog(auditPayload);
    
          fetch(url, {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
            },
          }) // FETCH BLOB FROM IT
            .then((response) => {
              response.blob();
            })
            .then((blob) => {})
            .catch((err) => {});
            
        } catch (error) {}
      };


      const [openPdf, setOpenPdf]  = useState(false);
      const onChangePdf = async () => {
        setOpenPdf(true);
      };
      
      const closePdf = () => {
        setOpenPdf(false);
      }






        const ExportCsvoptions = ({ onChangeXcl, onChangePdf, commonFilter }) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const exportOpen = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const location = useLocation();
        return (
          <>
          <div>
            <SoftButton
              className="success-soft"
              startIcon={<HiFolderDownload />}
              id="export-button"
              aria-controls={exportOpen ? "export-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={exportOpen ? "true" : undefined}
              onClick={onChangeXcl}
              disableElevation
              color="primary"
              size="small"
            >
              CSV
            </SoftButton>
            </div>
            {/* <Menu
              id="export-menu"
              anchorEl={anchorEl}
              open={exportOpen}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "export-button",
              }}
              elevation={0}
            >
              <MenuItem onClick={onChangeXcl} dense={true}>
                <ListItemIcon>
                  <RiFileExcel2Fill />
                </ListItemIcon>
                <ListItemText>CSV</ListItemText>
              </MenuItem>
              <MenuItem onClick={onChangePdf}  dense={true}>
                <ListItemIcon>
                  <BsFileEarmarkPdfFill />
                </ListItemIcon>
                  <ListItemText>PDF</ListItemText>
              </MenuItem> 
            </Menu> */}
          </>
        );
      };
      
      
        const ExportPdfoptions = ({ onChangeXcl, onChangePdf, commonFilter }) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const exportOpen = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const location = useLocation();
        return (
          <>
          <div>
            <SoftButton
              className="error-soft"
              startIcon={<HiFolderDownload />}
              id="export-button"
              aria-controls={exportOpen ? "export-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={exportOpen ? "true" : undefined}
              onClick={onChangePdf}
              disableElevation
              color="secondary"
              size="small"
            >
              PDF
            </SoftButton>
            </div>     
          </>
        );
      };

    //Export
    const ExportOpt = () => {
        return(
            <Box sx={{p:.5}}>
                <Stack direction={'row'} spacing={.5} justifyContent={'flex-end'}>
                    <ExportCsvoptions onChangeXcl={onChangeXcl}/>
                    <ExportPdfoptions onChangePdf={onChangePdf} />
                </Stack>
            </Box>
        )
    }

 



    const isSuccess = useSelector((state) => state.controls.alert);
    const dispatch = useDispatch();
    useEffect(() => {
      if (isSuccess.isSuccess) {
        setTimeout(() => dispatch(snackService([null, null, null])), 3000);
      }
    }, [isSuccess.isSuccess]);

  return (
    !isLoading ?
    <Box sx={{height:'100%'}}>

        {
            userData?.resellerId === 110224 && <ExportOpt /> 
        }
        {
            summaryData[0]?.moveMinutes !== '-' ?  <VehicleSum /> :  
            <Box sx={{p:1}}>
            <Empty description = { summaryData[0]?.moveMinutes === '-' && date[0] === moment().format("YYYY-MM-DD 00:00:00") && date[1] === moment().format("YYYY-MM-DD 23:59:59") ?  'Vehicle has not yet moved' : 'Vehicle has not moved for selected period' }/> </Box>}

        {/* <UtilizationSum />
        <ChargingSum /> */}
        {
            summaryData[0]?.overSpeedCount !== 0 && <DriverBehivour />
        }
        <SummaryPdf summaryData={summaryData} commonData={commonData} openPdf={openPdf} closePdf={closePdf}/>
        {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
        
    </Box> : 
    <Box sx={{height:'100%', p:1}}>
      <ScaleLoader color={theme.palette.secondary.main}/>
    </Box>
  )
}

export default VehicleSummary