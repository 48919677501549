import { alpha, Backdrop, Box, Divider, Grid, Icon, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { Marker, Polyline } from '@react-google-maps/api';
import { Empty } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { MdSpeed, MdStopCircle } from 'react-icons/md';
import { PiTrafficSignalBold } from "react-icons/pi";
import { RiRouteLine, RiSlowDownLine, RiSpeedUpLine } from 'react-icons/ri';
import { TbRoute } from 'react-icons/tb';
import ScaleLoader from "react-spinners/ScaleLoader";
import GoogleMapUtil from '../../Utils/GoogleMapUtil';
import { SingleDatePicker } from '../../Utils/RsuiteDatePicker';
import { useGetAddressMutation, useVehicleTripsMutation } from '../../store/slice/VehicleSummarySlice/VehicleSummarySlice';
import { GridLoader } from 'react-spinners';
import { IoLocationOutline, IoPlay, IoPause } from "react-icons/io5";
import endMarker from '../../assets/images/playback/End_Marker.svg'
import startMarker from '../../assets/images/playback/Start_Marker.svg'
import dot from '../../assets/images/svgicons/dot5.svg'
function TripDetailsDashboard({ vehicleId, resellerId, vehicleNo }) {

    const theme = useTheme();
    const userData = JSON.parse(localStorage.getItem("userData"));
    let distanceUnit = userData?.distanceDisplayUnit;
    const [getTripsDetails, { data, isLoading: tripLoading, isSuccess: tripSuccess }] = useVehicleTripsMutation();
    const [getAddress] = useGetAddressMutation();
    const [Trips, setTrips] = useState([]);
    const [center, setCenter] = useState({ lat: 52.92214338014607, lng: -1.4703258589163344 })
    const [selectTrip, setSelectTrip] = useState(0);

    const mapRef = useRef();
    const getTrips = async (fromdate, toDate) => {
        setTrips([]);
        let payload = {
            "vehicleId": vehicleId,
            "orgId": resellerId,
            "startDate": fromdate === undefined ? moment().subtract(1, 'day').format('YYYY-MM-DD 00:00:00') : fromdate,
            "endDate": toDate === undefined ? moment().subtract(1, 'day').format('YYYY-MM-DD 23:59:59') : toDate,
        }
        const res = await getTripsDetails(payload);
        if (res.data !== undefined && res.data !== null && res.data.length > 0) {
            setTrips(res.data);
            getTripTrails(res.data[selectTrip]?.trailData);
            showAdd(res.data[selectTrip].startLocation, 'start');
            showAdd(res.data[selectTrip].endLocation, 'end');

            setSelectTrip(0);
        } else {
            setTrips([]);
        }

    }
    useEffect(() => {
        setTimeout(() => {
            getTrips();
        }, 50)
    }, [])

    const [path, setPath] = useState([])
    const getTripTrails = (trails) => {
        setPath([]);
        setMarkerData([])
        trails.forEach(element => {
            let location = element.split(',');
            let latLng = {};
            latLng.lat = parseFloat(location[0]);
            latLng.lng = parseFloat(location[1]);
            setCenter(latLng);
            setPath((prevState) => [...prevState, latLng]);
            setMarkerData((prevState) => [...prevState, latLng])
        });
    }

    const TripItem = (props) => {
        const { data, index } = props;
        return (
            <ListItem dense sx={{ px: 0, border: `.5px solid ${theme.palette.divider}`, p: 0, my: .5, borderRadius:'8px'}}>
                <ListItemButton sx={{ display: 'block' }} dense selected={index === selectTrip} onClick={() => tripOnChange(index)}>
                    {/* <ListItemText
                        primary={tripLoading ? <Skeleton variant='rectangular' width={100} height={15} sx={{ mb: .5 }} /> : <Typography variant='body1' gutterBottom>{`${moment(data?.strStartDate, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')} - ${moment(data?.strEndDate, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}`}</Typography>}
                        secondary={tripLoading ? <Skeleton variant='rectangular' width={130} height={8} sx={{ mb: .5 }} /> : `Trip Duration: ${data?.tripDuration}`}
                        sx={{ width: '100%' }}
                    /> */}
                    <Stack direction={'row'} spacing={1}>
                        <Stack direction={'row'} spacing={1.5}>
                            <Typography variant='body1' sx={{fontWeight:500}}>{moment(data?.strStartDate, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</Typography>
                            <Typography variant='body1' sx={{fontWeight:500}}> - </Typography>
                            <Typography variant='body1' sx={{fontWeight:500}}>{moment(data?.strEndDate, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</Typography>
                        </Stack>
                        <Typography variant='small' sx={{fontWeight:500, color:'#888'}}>({data?.tripDuration})</Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={3} sx={{ width: '100%', mt: .5, mb: .5 }}>
                        {
                            tripLoading ? <Skeleton variant='rectangular' width={60} height={20} /> :
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Icon color={'primary'} sx={{ display: 'flex', fontSize: '16px', color:'#888' }}>
                                        <RiSpeedUpLine />
                                    </Icon>
                                    <Typography variant='body2' sx={{fontWeight:500}} color={'primary'}>{data.avgSpeed}</Typography>
                                </Box>
                        }
                        {
                            tripLoading ? <Skeleton variant='rectangular' width={60} height={20} /> :
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: .5 }}>
                                    <Icon sx={{ display: 'flex', fontSize: '16px', color:'#888' }}>
                                        <RiRouteLine />
                                    </Icon>
                                    <Typography variant='body2' sx={{fontWeight:500}} color={'primary'}>{data.totDistance} {distanceUnit}</Typography>
                                </Box>
                        }

                    </Stack>
                </ListItemButton>
            </ListItem>
        )
    }

    const ValueCard = (props) => {
        const { label, value, } = props;
        return (
            <Box>
                <Stack spacing={1}>
                    {tripLoading ? <Skeleton variant='rectangular' width={100} height={15} /> : <Typography variant='body2' color={'text.disabled'}>{label}</Typography>}
                    {
                        tripLoading ? <Skeleton variant='rectangular' width={80} height={15} /> : <Typography variant='body1'>{value}</Typography>
                    }

                </Stack>
            </Box>
        )
    }

    const [fromAddress, setFromAddress] = useState('text');
    const [toAddress, setToAddress] = useState('text');

    const showAdd = async (location, where) => {
        let Location = location?.split(',');
        let startLat = Location !== undefined && parseFloat(Location[0]);
        let startLng = Location !== undefined && parseFloat(Location[1]);
        setCenter({ lat: startLat, lng: startLng })
        var requestN = {};
        requestN.latLng = startLat + "," + startLng;
        if (where === 'start') {
            const addRes = await getAddress(requestN).then((data) => {
                setFromAddress(data?.error?.data);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            const addRes = await getAddress(requestN).then((data) => {
                setToAddress(data?.error?.data);
            }).catch((error) => {
                console.log(error);
            })
        }


    }

    const tripOnChange = (id) => {
        setSelectTrip(id);
        showAdd(Trips[id].startLocation, 'start');
        showAdd(Trips[id]?.endLocation, 'end');
        getTripTrails(Trips[id]?.trailData)
    }




    const TripTiming = (props) => {
        const { startDate, endDate, startLocation, endLocation } = props;

        return (
            <Box>
                <Stack
                    direction={'row'}
                    sx={{ width: 'fit-content' }}
                    spacing={2}
                >
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Icon sx={{display:'flex', background:'#ebebeb', padding:.5, width:'30px', height:'30px', alignItems:'center', borderRadius:'50px', mr:1}}><IoLocationOutline /></Icon>
                        <Typography variant='caption'>{fromAddress}</Typography>
                    </Box>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Icon sx={{display:'flex', background:'#ebebeb', padding:.5, width:'30px', height:'30px', alignItems:'center', borderRadius:'50px', mr:1}}
                        ><IoLocationOutline /></Icon>
                        <Typography variant='caption'>{toAddress}</Typography>
                    </Box>
                </Stack>
            </Box>
        )
    }


    const IconBox = (props) => {
        const { label, value, stackSx = {}, icon = null, dir = 'column', boxSx = {} } = props
        return (
            !tripLoading ?
                <Box sx={boxSx}>
                    <Stack sx={stackSx} direction={dir} spacing={.8}>
                        <Box sx={{ display: dir === 'row' ? 'flex' : 'column' }} gap={1}>
                            {icon !== null && <Icon sx={{ display: 'flex' }}>{icon}</Icon>}
                            <Typography variant='caption' color={'text.secondary'}>{label}</Typography>
                        </Box>
                        <Typography variant='body1'>{value}</Typography>
                    </Stack>
                </Box> : <Skeleton variant='rectangular' width={120} height={60} />

        )
    }

    const GreyCard = (props) => {
        const { label, value, color } = props
        return (
            !tripLoading ?
                <Box>
                    <Stack>
                        <Typography variant='body1' sx={{color:color !== undefined ? color : '#28282b', background:alpha(color,.2), width:'fit-content', px:.5, borderRadius:'10px'}}>{value}</Typography>
                        <Typography variant='caption' color={'text.secondary'}>{label}</Typography>
                    </Stack>
                </Box> : <Skeleton variant='rectangular' width={120} height={60} />

        )
    }

    const DateChanged = (date) => {
        setPlay(false);
        let startDate = moment(date).format('YYYY-MM-DD 00:00:00')
        let endtDate = moment(date).format('YYYY-MM-DD 23:59:59')
        getTrips(startDate, endtDate);
    }

    useEffect(() => {
        if (mapRef.current !== undefined) {
            setPlay(false);
            mapRef.current.setZoom(10);
            var bounds = new window.google.maps.LatLngBounds();
            for (var i = 0; i < path.length; i++) {
                bounds.extend(path[i])
            }
            mapRef.current.fitBounds(bounds);
        }
    }, [mapRef.current, selectTrip])



    //triplist 
    const TripList = () => {
        return(
            <Paper elevation={0} sx={{height:'100%'}}>
                    <Box sx={{p:1.2}}>
                        {
                             tripLoading ?
                             <Skeleton variant='rectangular' width={'100%'} height={20}/> :
                             <>
                             <Typography variant='caption' sx={{mr:.5}}>Select Date</Typography>
                             <SingleDatePicker onChangeDate={DateChanged} />
                             </>
                        }
                    </Box>

                
                <Divider />
                <Box sx={{p:1.2}}>
                    {/* <Typography variant='body2'>Trip List (4)</Typography> */}
                    <Typography variant='h6' sx={{fontSize:'14px'}}>{tripLoading ? <Skeleton width={'60%'}/> : moment(Trips[0]?.strStartDate, 'YYYY-MM-DD HH:mm:ss').format('ll')}</Typography>
                    {
                         <List sx={{ pr: 1, p: 0, overflowY: 'auto', height: '100%' }}>
                         {
                            tripLoading ?
                            new Array('item1', 'item2', 'item3', 'item4', 'item5').map(() => {
                                return <Skeleton width={'100%'} height={50}/>
                            }) :
                            Trips.length > 0 ? Trips.map((item, index) => <TripItem data={item} index={index} key={index} />) : !tripLoading && <Empty description={'Trips not found'} />
                        }
                         </List>
                    }
                </Box>
            </Paper>
        )
    }


    ///animation
    const [play,setPlay] = useState(false);
    const [animePath, setAnimePath] = useState([]);
    let [markerData, setMarkerData] = useState([]);
    const animateMarkerRef = useRef(null);
    let timerRef = useRef();
    let linerArray = [];
    var delay = 100;
    var distance = 0;
    var dest = 0;
    var step = 0;
    var lat = 0;
    var lng = 0;    
    var speedLen = 5;
    let [speedValue, setSpeedValue] = useState(700000);
    let [speed, setSpeed] = useState(220);  // km/h
    let [currentLocation, setCurrentLocation] = useState({target:1, lat:null, lng:null, distance:0});


    useEffect(() => {
        if(play){
            animateMarker(animateMarkerRef.current, 220, true);
            mapRef.current.panTo({ lat: animateMarkerRef.current?.marker.position.lat(), lng: animateMarkerRef.current?.marker.position.lng()})
            setPlay(true);
        }else{
            clearTimeout(timerRef.current);
            setPlay(false);
        }
    },[play])
    
    
    const stopAnimation = () => {
        if(animateMarkerRef.current !== undefined){
            setCurrentLocation({target:1, lat:null, lng:null, distance:0});
            setAnimePath([]);
            animateMarkerRef.current.marker.setPosition(new window.google.maps.LatLng(path[0].lat, path[0].lng));
            mapRef.current.panTo({ lat: path[0].lat, lng: path[0].lng})
            setPlay(false);
            clearTimeout(timerRef.current);
        }
       
    }

    const animateMarker = (marker, km_h, state) => {
        var target = currentLocation.target;
        var pathAnim = [];
        var km_h = km_h || 50;
        function goToPoint() {
            let part = [];
            part.push(path[target]);
            if (part[0] !== undefined) {
                linerArray = [...part, ...linerArray];
                //setTrailsData(linerArray);
                var lat = marker?.marker?.position.lat();
                var lng = marker?.marker?.position.lng();
                var step = (km_h * 1000 * delay) / speedValue; // in meters
                var dest = new window.google.maps.LatLng(markerData[target].lat, markerData[target].lng);
                var distance = window.google.maps.geometry.spherical.computeDistanceBetween(dest, marker.marker.position); // in meters
                setCurrentLocation({ target: target, distance: distance, lat, lng })
                let point1 = { lat: markerData[target - 1].lat, lng: markerData[target - 1].lng }
                let point2 = { lat: markerData[target].lat, lng: markerData[target].lng}
                var angle = window.google.maps.geometry.spherical.computeHeading(point1, point2);
                //setRotation(angle);
                var numStep = distance / step;
                var i = 0;
                var deltaLat = (parseFloat(markerData[target].lat) - lat) / numStep;
                var deltaLng = (parseFloat(markerData[target].lng) - lng) / numStep;
                function moveMarker() {
                    lat += deltaLat;
                    lng += deltaLng;
                    i += step;
                    setCurrentLocation({ lat: lat, lng: lng, target, distance })
                    if (i < distance) {
                        let pathObj = { lat: lat, lng: lng }
                        pathAnim.push(pathObj);
                        setAnimePath([...animePath, ...pathAnim])
                        marker.marker.setPosition(new window.google.maps.LatLng(lat, lng));
                        mapRef.current.panTo({ lat: lat, lng: lng });
                        timerRef.current = setTimeout(moveMarker, delay);
                    }
                    else {
                        target++;
                        timerRef.current = setTimeout(goToPoint, delay);
                    }
                }
                moveMarker();
            } else {
                setPlay(false);
                clearTimeout(timerRef.current);
            }
        }
        goToPoint();

    }







    return (
        // <Box sx={{ p: 0,background:"#fff",height:"80vh" }}>
        //             <Backdrop
        //   open={tripLoading}
        //   sx={{
        //     position: "absolute",
        //     background: alpha(theme.palette.background.paper, 0.6),
        //     zIndex: 999,
        //   }}
        // >
        //   <GridLoader color={theme.palette.secondary.main} />
        // </Backdrop>

        //     <Grid container spacing={2} sx={{ height: '100%' }}>
        //         <Grid item lg={3}>
        //             <Box sx={{ p: 2, borderRight: `.8px solid ${theme.palette.divider}`, height: '100%' }}>
        //                 {/* <Box>
        //                     {
        //                         tripLoading ? <Skeleton variant='rectangular' width={100} height={15} /> :
        //                             <Typography color={'secondary'} gutterBottom>{vehicleNo}</Typography>
        //                     }

        //                 </Box> */}
        //                 <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        //                     <Typography>Trip List</Typography>
        //                     <SingleDatePicker onChangeDate={DateChanged} />

        //                 </Stack>
        //                 <Box sx={{ height: '100%', maxHeight: '90%', overflowY: 'auto', mt: .8 }}>
        //                     {/* {
        //                         tripLoading ? <Skeleton variant='rectangular' width={285} height={60} /> : */}
        //                             <List sx={{ pr: 1, p: 0, overflowY: 'auto', height: '100%' }}>
        //                                 {
        //                                     Trips.length > 0 ? Trips.map((item, index) => <TripItem data={item} index={index} key={index} />) : !tripLoading && <Empty description={'Trips not found'} />
        //                                 }

        //                             </List>
        //                     {/* } */}
        //                 </Box>
        //             </Box>
        //         </Grid>
        //         {!tripLoading &&
        //         <Grid item lg={9} sx={{ height: '100%' }}>
        //             <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto auto 1fr auto', p: 2 }}>
        //                 {/* <Box>
        //             <Typography variant='h6' sx={{fontWeight:'600'}} gutterBottom>{tripLoading ? <Skeleton variant='rectangular' width={250} height={15} /> : "Trip & Vehicle Summary"}</Typography>
        //             <Stack direction={'row'} spacing={2} columnGap={3}>
        //                 <ValueCard label={'Odometer Reading'}  value={Trips[selectTrip]?.endOdometer !== undefined ? Trips[selectTrip]?.endOdometer : '-'}/>
        //                 <ValueCard label={'Last Service Date'} value={'-'}/>
        //                 <ValueCard label={'Next Due Service Date'} value={'-'}/>
        //             </Stack>
        //         </Box> */}
        //                 <Box>
        //                     <Typography variant='h6' sx={{ fontWeight: '600' }} gutterBottom>Trip & Vehicle Summary</Typography>
        //                     {tripLoading ? (
        //                         <Box
        //                             sx={{
        //                                 height: "100px",
        //                                 display: "flex",
        //                                 width: "100%",
        //                                 alignItems: "center",
        //                                 justifyContent: "center",
        //                             }}
        //                         >
        //                             <ScaleLoader color={theme.palette.secondary.main} />
        //                         </Box>)
        //                         :

        //                         Trips.length > 0 ? <>
        //                             <Box sx={{ border: `.8px solid ${theme.palette.divider}`, mt: 2, p: 1 }}>
        //                                 <Stack direction={'row'} spacing={2}>
        //                                     {/* <GreyCard label={'Driver Score'} value={Trips[selectTrip]?.driverScore}/> */}
        //                                     <GreyCard label={'Odometer Reading'} value={Trips[selectTrip]?.endOdometer} />
        //                                     <GreyCard label={'Trip Distance'} value={Trips[selectTrip]?.totDistance} />
        //                                     <GreyCard label={'Average Speed'} value={Trips[selectTrip]?.avgSpeed} />
        //                                     <GreyCard label={'Max Speed'} value={Trips[selectTrip]?.maxSpeed} />
        //                                 </Stack>
        //                             </Box>
        //                             <Box sx={{ mt: 1.5 }}>
        //                                 <Grid container spacing={2}>
        //                                     <Grid item lg={3}>
        //                                         <Box sx={{ mt: 2 }}>
        //                                             <TripTiming
        //                                                 startDate={Trips[selectTrip]?.startDate}
        //                                                 endDate={Trips[selectTrip]?.endDate}
        //                                                 startLocation={Trips[selectTrip]?.startLocation}
        //                                                 endLocation={Trips[selectTrip]?.endLocation}
        //                                             />
        //                                             <Stack sx={{ mt: 2 }} spacing={2}>
        //                                                 <IconBox label={'Idling'} value={Trips[selectTrip]?.cumIdleMins} stackSx={{ display: 'flex', justifyContent: 'space-between' }} icon={<PiTrafficSignalBold />} dir={'row'} boxSx={{ background: alpha(theme.palette.info.main, 0.05), py: 1, px: 1.5 }} />
        //                                                 <IconBox label={'Harsh Acceleration'} value={Trips[selectTrip]?.haAlertCount} icon={<RiSpeedUpLine />} dir={'row'} stackSx={{ display: 'flex', justifyContent: 'space-between' }} boxSx={{ background: alpha(theme.palette.error.main, 0.08), py: 1, px: 1.5 }} />
        //                                                 <IconBox label={'Harsh Braking'} value={Trips[selectTrip]?.hbAlertCount} icon={<RiSlowDownLine />} dir={'row'} stackSx={{ display: 'flex', justifyContent: 'space-between' }} boxSx={{ background: alpha(theme.palette.error.main, 0.06), py: 1, px: 1.5 }} />
        //                                             </Stack>
        //                                         </Box>
        //                                     </Grid>
        //                                     <Grid item lg={9}>
        //                                         <Box sx={{ height: 'calc(100vh - 45vh)' }}>
        //                                             {
        //                                                 tripLoading ? <Skeleton variant='rounded' width={'100%'} height={'100%'} />
        //                                                     : <GoogleMapUtil trails={Trips[selectTrip]?.trailData} mapDepend={[selectTrip, path, center, mapRef.current]} center={center} zoom={10} mapRef={mapRef}>
        //                                                         <Polyline
        //                                                             path={path}
        //                                                         />
        //                                                         {/* Start Marker */}
        //                                                         <Marker
        //                                                             position={path[0]}
        //                                                             label={{
        //                                                                 text: 'S'
        //                                                             }}
        //                                                         />

        //                                                         {/* End Marker */}
        //                                                         <Marker
        //                                                             position={path[path.length - 1]}
        //                                                             label={{
        //                                                                 text: 'E'
        //                                                             }}
        //                                                         />
        //                                                     </GoogleMapUtil>
        //                                             }

        //                                         </Box>
        //                                     </Grid>
        //                                 </Grid>
        //                             </Box> </> :
        //                             !tripLoading && <Empty description={'No trip Data'} />
        //                     }
        //                 </Box>


        //             </Box>

        //         </Grid>
        //         }
        //     </Grid>

        // </Box>
        <Box sx={{
            height:'calc(100% - 10%)',
        }}>
            <Grid container spacing={1} sx={{height:'100%'}}>
                <Grid item xl={2.5}>
                    <TripList />
                </Grid>
                <Grid item xl={9.5}>
                    <Paper elevation={0} sx={{height:'100%'}}>
                        {
                            Trips.length > 0 && 
                            <Box sx={{p:1.2}}>
                                <Stack direction={'row'} divider={<Divider orientation='vertical' flexItem/>} spacing={1}>
                                    <Stack direction={'row'} spacing={.5}>
                                    {/* <GreyCard label={'Driver Score'} value={Trips[selectTrip]?.driverScore}/> */}
                                        <GreyCard label={'Odometer Reading'} value={Trips[selectTrip]?.endOdometer} color={'#4a17ff'}/>
                                        <GreyCard label={'Trip Distance'} value={Trips[selectTrip]?.totDistance} color={'#175cff'}/>
                                        <GreyCard label={'Average Speed'} value={Trips[selectTrip]?.avgSpeed} color={'#068b5a'}/>
                                        <GreyCard label={'Max Speed'} value={Trips[selectTrip]?.maxSpeed} color={'#940707'}/>
                                    </Stack>
                                    <Stack direction={'row'} spacing={1.5} sx={{width:'60%'}}>
                                        <TripTiming
                                        startDate={Trips[selectTrip]?.startDate}
                                        endDate={Trips[selectTrip]?.endDate}
                                        startLocation={Trips[selectTrip]?.startLocation}
                                        endLocation={Trips[selectTrip]?.endLocation}
                                        />
                                    </Stack>
                                </Stack>
                               
                            </Box>
                        }
                        <Divider/>
                        <Box sx={{position:'realative', maxHeight:'90%', height:'100%'}}>
                        {
                            Trips.length > 0 &&
                            <Box sx={{position:'absolute', top:'10', width:'100%', zIndex:999, px:2, background:'#fff', boxShadow:'0px 5px 8px rgba(0,0,0,0.08)'}}>
                            <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} spacing={3}>
                                <Box>
                                    <Stack direction={'row'} spacing={1}>
                                        <IconButton sx={{background:`${theme.palette.primary.main} !important`, color:'#fff', boxShadow:'0px 5px 8px rgba(0,0,0,0.5)'}} size='small' onClick={() => setPlay(!play)}>
                                            {play ? <IoPause /> : <IoPlay/> }
                                        </IconButton>
                                        <IconButton 
                                        sx={{background:`${animePath.length <= 0 ? theme.palette.grey[200] : theme.palette.error.main} !important`, color:'#fff'}} size='small' onClick={() => stopAnimation()}
                                        disabled={animePath.length <= 0}
                                        >
                                            {<MdStopCircle />}
                                        </IconButton>
                                    </Stack>
                                </Box>
                                <Box sx={{p:1.2}}>
                                    <Stack direction={'row'} spacing={.5}>
                                        <IconBox label={'Idling'} value={Trips[selectTrip]?.cumIdleMins} stackSx={{ display: 'flex', justifyContent: 'space-between' }} icon={null} dir={'row'} boxSx={{ background: alpha('#fff', 1), p:.5, borderRadius:'8px'}} />
                                        <IconBox label={'Acceleration'} value={Trips[selectTrip]?.haAlertCount} stackSx={{ display: 'flex', justifyContent: 'space-between' }} icon={null} dir={'row'} boxSx={{ background: alpha('#fff', 1), p:.5, borderRadius:'8px'}} />
                                        <IconBox label={'Braking'} value={Trips[selectTrip]?.hbAlertCount} stackSx={{ display: 'flex', justifyContent: 'space-between' }} icon={null} dir={'row'} boxSx={{ background: alpha('#fff', 1), p:.5, borderRadius:'8px'}} />
                                    </Stack>
                                </Box>
                                
                            </Stack>
                            </Box>
                        }
                        {
                        tripLoading ? 
                        <Skeleton variant='rounded' width={'100%'} height={'100%'} />
                        : 
                        <GoogleMapUtil trails={Trips[selectTrip]?.trailData} mapDepend={[selectTrip, path, center, mapRef.current, animePath]} center={center} zoom={10} mapRef={mapRef}>
                            <Polyline
                                path={path}
                                options={{
                                    strokeColor:play ? '#c21d6d' : '#c21d6d',
                                    strokeOpacity: animePath.length > 0 ? 0.2 : 1.0
                                }}
                            />
                            {
                               <Polyline
                               path={animePath}
                               options={{
                                   strokeColor:play ? '#c21d6d' : '#c21d6d',
                                   strokeOpacity: animePath.length > 0 ? 1 : 0
                               }}
                           /> 
                            }
                            {/* Start Marker */}
                            <Marker
                                position={path[0]}
                                options={{
                                    icon: {
                                        url: startMarker,
                                        scale: 1.5
                                    },
                                }}
                            />

                            {/* End Marker */}
                            <Marker
                                position={path[path.length - 1]}
                                options={{
                                    icon: {
                                        url: endMarker,
                                        scale: 1.5
                                    },
                                }}
                            />
                            {/* animatemarker */}
                            <Marker
                                position={path[0]}
                                options={{
                                    //visible:true,
                                    icon: {
                                        url: dot,
                                        scale: 1.5,
                                        anchor: new window.google.maps.Point(20, 16)
                                    },
                                    
                                }}
                                zIndex={9}
                                ref={animateMarkerRef}
                            />
                        </GoogleMapUtil>
                        }
                        </Box>
                    </Paper>
                </Grid>
            
            </Grid>
        </Box>
    )
}



export default TripDetailsDashboard