import React, { useCallback, useEffect, useRef, useState } from 'react'
import "react-video-seek-slider/styles.css";
import { VideoSeekSlider } from "react-video-seek-slider";
import { Box, Stack, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { GOOGLE_MAP_KEY } from '../../constants/appConstant';

function Seekbar(props) {
    const { playStatus, counter, startDate, endDate, handleChange, currentTime, timeCode, newData } = props;
    const interval = useRef(null);
    const [maxTime, setMaxTime] = useState(86399000);
    const previewImage = useRef("");
    const theme = useTheme()
    // useEffect(() => {
    //     if(playStatus){
    //         setCurrentTime(counter * 1000)
    //     }
    // },[counter])

    const hoverTime = (event) => {
        console.log('event', event);
    }


    const getImageFromGoogleStaicMap = (hoverTime, ) => {
        const index = newData.filter((item) => item.mili <= hoverTime);
        if(index.length > 0){
            let latlng = index[index.length - 1].latlng;
            const url = `https://maps.googleapis.com/maps/api/staticmap?center=${latlng}&zoom=12&size=600x300&maptype=roadmap&key=AIzaSyBx1k2yHWgbv2RS0NUVcYrqhcHD8sWr9eA`;
            const image = document.createElement("img");
            image.src = url;
        
            image.onload = () => {
                previewImage.current = url;
            };
        }
    }


    const updatePreviewImage = (hoverTime) => {
        const url = `https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyBx1k2yHWgbv2RS0NUVcYrqhcHD8sWr9eA`;
        const image = document.createElement("img");
        
    };

    const handleGettingPreview = useCallback(
        (hoverTime) => {
        getImageFromGoogleStaicMap(hoverTime, maxTime);
          console.log(hoverTime);
          return previewImage.current
        },
        [maxTime]
      );


  return (
        <Box  
        sx={{
            width:'100%', 
            '& .ui-video-seek-slider':{
                width:'100%',
                '& .track .main:before':{
                    background:'rgba(0,0,0, .2)'
                },
                '& .track .main':{
                    height:'0px !important'
                },
                '& .thumb':{
                    top:'-5px',
                    '& .handler':{
                        background:theme.palette.primary.main
                    }
                    
                }
                }}}>
           
            <VideoSeekSlider 
                max={maxTime}
                currentTime={currentTime}
                onChange={handleChange}
                secondsPrefix="00:00:"
                minutesPrefix="00:"
                timeCodes={timeCode}
                //getPreviewScreenUrl={handleGettingPreview}
            />
            
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography sx={{textWrap:'nowrap', fontSize:'10px', color:'#888', mt:.2}}>{` 
                ${ parseInt(Math.trunc(moment.duration(currentTime).asHours())) > 9 ? 
                Math.trunc(moment.duration(currentTime).asHours()) : '0' + Math.trunc(moment.duration(currentTime).asHours())} : 
                ${parseInt(moment.duration(currentTime).minutes()) > 9 ? moment.duration(currentTime).minutes() : '0' + moment.duration(currentTime).minutes()} : 
                ${parseInt(moment.duration(currentTime).seconds()) > 9 ? moment.duration(currentTime).seconds() : '0' + moment.duration(currentTime).seconds()} `}
                </Typography>
                <Typography sx={{textWrap:'nowrap', fontSize:'10px', color:'#888', mt:.2}}>{`
            ${parseInt(Math.trunc(moment.duration(maxTime - currentTime).asHours())) > 9 ? 
                Math.trunc(moment.duration(maxTime - currentTime).asHours()) : '0' + Math.trunc(moment.duration(maxTime - currentTime).asHours())} : 
            ${parseInt(moment.duration(maxTime - currentTime).minutes()) > 9 ? moment.duration(maxTime - currentTime).minutes() : '0' + moment.duration(maxTime - currentTime).minutes()} : 
            ${parseInt(moment.duration(maxTime - currentTime).seconds()) > 9 ? moment.duration(maxTime - currentTime).seconds() : '0' + moment.duration(maxTime - currentTime).seconds()}`}
            </Typography>  
            </Stack>
        </Box>
  ) 
}

export default Seekbar