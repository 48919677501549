
import { Box, Divider, Stack, Tab, Tabs, styled, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import VehicleDetails from './VehicleDetails';
import { useDispatch, useSelector } from 'react-redux';
import reportServices from '../../../services/reportServices';
import VehicleSummary from './VehicleSummary';
import { StyledFiterButton, StyledIconButton } from '../../../theme/StyledEle';
import { MdOutlineClose } from 'react-icons/md';
import { vehicleSummaryWindow } from '../../../store/slice/ControlsSlice';
import moment from 'moment';
import TimelineSummary from './TimelineSummary';
import { RiEyeCloseFill } from 'react-icons/ri';

function VehicleMainSumary(props) {
    const theme = useTheme()
    const { trailsData, categoryOrgId, categoryVehicleId, height = 350, open, showCategory, CloseSummary, isLoading } = props;
    const date = useSelector((state) => state.controls.treeview.date);
    let [address, setAddress] = useState(null);
    let [summaryStartAddress, setSummaryStartAddress] = useState(null);
    let [summaryEndAddress, setSummaryEndAddress] = useState(null);
    const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);
    const selectedVehicle = useSelector((state) => state.controls.selectedVehicle);
    const [tabValue, setTabValue] = useState(1);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    useEffect(() => {
      if(tabValue === 3 && moment(date[0]).isSame(moment(date[1]).format("YYYY-MM-DD")) === false){
        setTabValue(1)
      }
    },[date])

    const StyledTabs = styled(Tabs)(({ theme }) => ({
        minHeight:'auto',
        '& .MuiTab-root':{
            padding:'10px 10px',
            minHeight:'auto',
            textTransform:'capitalize',
            fontWeight:'400',
            fontSize:'12px'
        }
    }))

    const getAddress = () => {
        setAddress(null);
        var requestN = {};
        requestN.latLng =
          vehicleTrails?.commonData[0]?.lat + "," + vehicleTrails?.commonData[0]?.lng;
        return reportServices
          .getGeocodeAddress(requestN)
          .then((resN) => {
            setAddress(resN.data);
          })
          .catch((err1) => {
            console.log(err1);
          });
      };

      //duplicate it for summary address

      const getSummaryAddress = (lat,lng,type) => {
        setSummaryStartAddress(null);
        setSummaryEndAddress(null);
        var requestN = {};
        requestN.latLng = lat + "," + lng;
        return reportServices
          .getGeocodeAddress(requestN)
          .then((resN) => {
            type === "start" && setSummaryStartAddress(resN.data);
           
            type === "end" &&   setSummaryEndAddress(resN.data);
          
          
          })
          .catch((err1) => {
            console.log(err1);
          });
      };

      useEffect(() => {
            getAddress()
            getSummaryAddress(vehicleTrails.vehicleTrails[0]?.lat,vehicleTrails.vehicleTrails[0]?.lng,"start");
            getSummaryAddress(vehicleTrails?.vehicleTrails[vehicleTrails?.vehicleTrails?.length - 1  ]?.lat,vehicleTrails?.vehicleTrails[ vehicleTrails?.vehicleTrails?.length - 1 ]?.lng,"end");
      },[vehicleTrails, selectedVehicle])

      const dispatch = useDispatch();
      let summaryWindow = useSelector((state) => state.controls.vehicleSummary)
      const closeSumary = () => {
        dispatch(vehicleSummaryWindow(!summaryWindow));
      }


    const [isToday, setIsToday] = useState(true);
    useEffect(() => {
        if (date !== null) {
          if (
            date[0] === moment().format("YYYY-MM-DD 00:00:00") &&
            moment(date[1]).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          ) {
            setIsToday(true);
          } else {
            // console.log("lat,lng=>",vehicleTrails?.vehicleTrails[vehicleTrails?.vehicleTrails?.length - 1  ]?.lat,vehicleTrails?.vehicleTrails[ vehicleTrails?.vehicleTrails?.length - 1 ]?.lng);
            // getSummaryAddress(vehicleTrails.vehicleTrails[0]?.lat,vehicleTrails.vehicleTrails[0]?.lng,"start");
            // getSummaryAddress(vehicleTrails?.vehicleTrails[vehicleTrails?.vehicleTrails?.length - 1  ]?.lat,vehicleTrails?.vehicleTrails[ vehicleTrails?.vehicleTrails?.length - 1 ]?.lng,"end");
            setIsToday(false); // already false changed for demo purpose
          }
        }
      }, [date]);


  return (
    <Box sx={{width:'380px', height:`100%`, overflow:'hidden', overflowY:'auto'}}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{position:'sticky', top:'0px', background:theme.palette.background.paper, borderBottom:`.5px solid ${theme.palette.divider}`, zIndex:99}}>
          <StyledTabs value={tabValue} onChange={handleTabChange} scrollButtons={'auto'} variant='scrollable'>
               <Tab label={isToday ? 'Last Connected' : 'Vehicle Details'} value={1}/>
              {/* <Tab label={'Last Connected'} value={1}/> */}
              <Tab label='Vehicle Summary' value={2}/>
              {
                 moment(date[0]).isSame(moment(date[1]).format("YYYY-MM-DD")) &&  <Tab label='Timeline' value={3}/>
              }
             
          </StyledTabs>
          {
            //: CloseSummary
            !open ? <StyledIconButton size='small' onClick={closeSumary}><MdOutlineClose /></StyledIconButton> : <StyledIconButton size='small' onClick={CloseSummary}><MdOutlineClose /></StyledIconButton>
          }
            
        </Stack>
        <Box sx={{height:'auto'}}>
            {
                tabValue === 1 && <VehicleDetails data={vehicleTrails?.commonData[0]} address={address} allData={vehicleTrails} startLocation={summaryStartAddress} endLocation={summaryEndAddress}/>
            }
            {
              tabValue === 2 && <VehicleSummary data={vehicleTrails} address={address} categoryOrgId={categoryOrgId} categoryVehicleId={categoryVehicleId} showCategory={showCategory} isLoading={isLoading}/>
            }
            {
              tabValue === 3 && <TimelineSummary selectedVehicle={selectedVehicle}/>
            }
        </Box>
    </Box>
  )
}

export default VehicleMainSumary