import React, { useEffect } from "react";
import ReportServices from "../../services/reportServices";
import { Box, Icon, Typography, alpha, useTheme } from "@mui/material";
import { SoftButton } from "../../theme/StyledEle";
import { TiLocationOutline } from "react-icons/ti";


const GetTimeLineAddress = (props) => {
    const theme = useTheme();
    const{row,columnName} = props;
    // console.log("columnName=====>",columnName);
const refAddres = React.useRef(row);
var getAddressBtn = React.useRef(row);
// var getIcon = React.useRef(row);
const getAddress = async (columnName) => {
  if (refAddres.current.innerText === "") {
    var request = {};
    request.latLng = columnName;
    const responseData = (
      await ReportServices.getGeocodeAddress(request)
    ).data;
    refAddres.current.innerText = responseData;
    if (responseData !== "") {
      refAddres.current.style.display = "block";
      refAddres.current.style.fontWeight = "400";
      refAddres.current.style.fontSize = "10px";
      getAddressBtn.current.style.display = "none";
    }
  }
};
useEffect(() => {
  getAddress(columnName);
},[])

return (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Box
      className="addresslink"
      id={columnName}
      sx={{ display: "flex", alignItems: "center" }}
    >
      {/* <SoftButton
        onClick={(e) => getAddress(e)}
        value={columnName}
        ref={getAddressBtn}
        sx={{background:alpha(theme.palette.primary.main,0.1) ,color:alpha(theme.palette.primary.main, 1),padding: "0px 5px",fontSize: "10px",textTransform: "lowercase",fontStyle: "normal"}}
        size="small"
      >
       View Address
      </SoftButton> */}
      {/* <Icon  ref={getIcon} sx={{fontSize:'18px', display:'none',alignItems:'baseline', color:alpha(theme.palette.primary.main, 1)}}>
      <TiLocationOutline />
           </Icon> */}
           
      <Typography
      component={'a'}
        href={`https://www.google.com/maps/search/?api=1&query=${columnName}`}
        target="_blank"
        className="address"
        ref={refAddres}
        id={columnName}
        key={columnName}
        sx={{color:"blue"}}
      ></Typography>
    </Box>
  </Box>
);
}


export default GetTimeLineAddress;