import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab'
import { alpha, Box, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTimelineMutation } from '../../../store/slice/NeweFeatureSlice/FeaturesSlice';
// import GetLocation from '../../Location';
import { useSelector } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { Empty } from 'antd';
import moment from 'moment';
import GetTimeLineAddress from '../../Location/getAddress';

function TimelineSummary(props) {

  const { selectedVehicle } = props;
  const theme = useTheme();
  const date = useSelector((state) => state.controls.treeview.date);
  const [ getTimeline, {data, isLoading, isSuccess, isError, is}] = useTimelineMutation()
  const [timeLineData, setTimelineData] = useState([]);
  useEffect(() => {
    if(selectedVehicle !== null && selectedVehicle !== undefined){
      getTimelineData();
    }
  },[selectedVehicle, date])


  const getTimelineData = async() => {
    let payload = {
      "vehicleId": selectedVehicle,
      "startDate": date[0],
      "endDate": date[1]
    }
    const timelinedata = await getTimeline(payload);
    if(timelinedata?.data !== undefined && timelinedata?.data?.data !== undefined && timelinedata?.data?.data.length > 0){
      setTimelineData(timelinedata?.data?.data)
    }else{
      setTimelineData([])
    }
  }


  const AnalyticsData = ({label, value, status}) => {
    return (
      <Stack>
          <Typography variant='caption' color={'text.secondary'}>{label}</Typography>
          {
            label === "" ? <Typography variant='body2' sx={{color:
              status === "0" ? alpha(theme.palette.error.main, 1) : 
              status === "1" ? alpha(theme.palette.success.main, 1) :
              status === "2" && alpha(theme.palette.info.main, 1),
              fontWeight:label === "" ? '600' : '400'
            }}>{value}</Typography> : 
              <Typography variant='body2' sx={{fontSize:label === "Duration" ?'12px':'14px', fontWeight:'500'}}>{value}</Typography>
          }
          
      </Stack>
    )
  }

  const MovingCard = ({item}) => {
      return (
        <Box>
          <Grid container spacing={.5}>
          <Grid item lg={12} md={12} sm={12}>
            <AnalyticsData label={''} value={item?.vehicleStatusStr} status={item.vehicleStatus}/>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <AnalyticsData label={'Start Time'} value={moment(item?.startDate).format('HH:mm:ss')}/>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <AnalyticsData label={'End Time'} value={moment(item?.endDate).format('HH:mm:ss') }/>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <AnalyticsData label={'Duration'} value={item?.duration}/>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <AnalyticsData label={'Speed (mph)'} value={parseFloat(item?.speed).toFixed(2)}/>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
              <AnalyticsData label={'Distance (mi)'} value={item?.distance}/>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
              <Box sx={{p:.5}}/>
              <Typography variant='caption' color={'text.secondary'} sx={{fontSize:'12px'}}>Address</Typography>
              <GetTimeLineAddress row={''} columnName={item?.latlng}/>
          </Grid>
        </Grid>
      </Box>
      )
  }

  const StopCard = ({item}) => {
    return (
      <Box>
        <Grid container spacing={.5}>
        <Grid item lg={12} md={12} sm={12}>
          <AnalyticsData label={''} value={item?.vehicleStatusStr} status={item.vehicleStatus}/>
        </Grid>
        <Grid item lg={6} md={12} sm={12}>
            <AnalyticsData label={'Start Time'} value={moment(item?.startDate).format('HH:mm:ss')}/>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <AnalyticsData label={'End Time'} value={moment(item?.endDate).format('HH:mm:ss') }/>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <AnalyticsData label={'Duration'} value={item?.duration}/>
          </Grid>
        <Grid item lg={12} md={12} sm={12}>
        <Box sx={{p:.5}}/>
        <Typography variant='caption' color={'text.secondary'} sx={{fontSize:'12px'}}>Address</Typography>
              <GetTimeLineAddress row={''} columnName={item?.latlng}/>
          </Grid>
      </Grid>
    </Box>
    )
}

const IdleCard = ({item}) => {
  return (
    <Box>
      <Grid container spacing={.5}>
      <Grid item lg={12} md={12} sm={12}>
        <AnalyticsData label={''} value={item?.vehicleStatusStr} status={item.vehicleStatus}/>
      </Grid>
      <Grid item lg={6} md={12} sm={12}>
            <AnalyticsData label={'Start Time'} value={moment(item?.startDate).format('HH:mm:ss')}/>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <AnalyticsData label={'End Time'} value={moment(item?.endDate).format('HH:mm:ss') }/>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <AnalyticsData label={'Duration'} value={item?.duration}/>
          </Grid>
      <Grid item lg={12} md={12} sm={12}>
      <Box sx={{p:.5}}/>
      <Typography variant='caption' color={'text.secondary'} sx={{fontSize:'12px'}}>Address</Typography>
              <GetTimeLineAddress row={''} columnName={item?.latlng}/>
          </Grid>
    </Grid>
  </Box>
  )
}

  const TimelineContentData = ({item}) => {
    return (
        <Box
          sx={{
              // background: 
              // item?.vehicleStatus === "0" ? 'rgb(255, 226, 226)' : 
              // item?.vehicleStatus === "1" ? 'rgb(205, 248, 223)' :
              // item?.vehicleStatus === "2" ? 'rgb(215, 239, 252)' : '',
              padding:'0px 12px 12px 12px',
              borderRadius:'0px 10px 10px 10px',
              '&:before':{
                content:"''",
                position:'absolute',
                top:'12px',
                left:'22px',
                width:'15px',
                height:'15px',
                transform:'rotate(-45deg)',
                // background:item?.vehicleStatus === "0" ? 'rgb(255, 226, 226)' : 
                // item?.vehicleStatus === "1" ? 'rgb(205, 248, 223)' :
                // item?.vehicleStatus === "2" ? 'rgb(215, 239, 252)' : '',
              }
          }}
        >
            {
              item.vehicleStatus === 0 ? <StopCard  item={item}/> : 
              item.vehicleStatus === 1 ? <MovingCard item={item} /> : 
              item.vehicleStatus === 2 && <IdleCard item={item} />
            }
        </Box>
    )
  }

  const Timelineitem = ({data}) => {
    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot sx={{
                  background: 
                  data?.vehicleStatus === 0 ? alpha(theme.palette.error.main, 1) : 
                  data?.vehicleStatus === 1 ? alpha(theme.palette.success.main, 1) :
                  data?.vehicleStatus === 2 ? alpha(theme.palette.info.main, 1) : ''
                  }}/>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{px:0, py:1}}>
                  <TimelineContentData item={data}/>
            </TimelineContent>
        </TimelineItem>
    )
  }

  return (
    
      isLoading ? <Box sx={{p:2, display:'flex', justifyContent:'center'}}><ScaleLoader color={theme.palette.secondary.main} /></Box> : 
      !isError && timeLineData?.length > 0 ?
    <Box sx={{p:1}}>
        <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
        >
          {
            timeLineData.length > 0 && timeLineData.map((item, index) => {
              return (
                <Timelineitem data={item} index={index}/>
              )
            })
          }
            
        </Timeline>
    </Box> : <Box sx={{p:1}}>
      <Empty description = {'No Data Found'}/>
    </Box>
  )
}

export default TimelineSummary