import { Button, CardContent } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import ReportServices from "../../services/reportServices";
import { Breadcrumb } from "../shared/Breadcrumbs";
import MouseOverPopover from "../shared/PopOver";
import { DataTable } from "../shared/data_table/DataTable";
import {
  useAuditLogMutation,
  useGetIdleReportMutation
} from "../../store/slice/ApiSlice";
import {
  StyledCardShadow,
  StyledPageLayout
} from "../../theme/StyledEle";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import GetLocation from "../Location";

const text =
  "This report shows various instances when the vehicle was Idling beyond the threshold time. Idle status is when the vehicle is not moving but engine is running.";

const IdleReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > IdleReport";
    auditPayload.action = "View";
    auditPayload.featureName = "IdleReport";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },

      {
        Header: "Gps Location",
        accessor: "gpsLocation",
        width:180,
        Cell: ({ row }) => {
          return(
            row.original.gpsLocation !== "" &&   row.original.gpsLocation !== "NA" ?
          <GetLocation row={row} columnName={row.original.gpsLocation}/> : '-'
          )
        }
      },
      {
        Header: "Start Date",
        accessor: "startTime",
        width:180,
      },
      {
        Header: "End Date",
        accessor: "endTime",
        width:180,
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "idleminsinhrs",
      },
    ],
    []
  );


  // Get Data from New Dropdown
  const [getIdleData, { data: idleData, isLoading, isSuccess }] =
  useGetIdleReportMutation();
  let commonPayload = {};

  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.columnName="END_TIME";
    
    //Service Call
    setPayload(commonPayload);
    getIdleData(commonPayload);

    auditPayload.message = "Reports > IdleReport > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "IdleReport";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "IdleReport/ListIdleReportXLS.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&columnName=" +
        payload.columnName +
        "&orderDir=" +
        payload.orderDir +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > IdleReport > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "IdleReport";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "IdleReport/ListIdleReportXLS.pdf?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId;

      window.open(url);

      auditPayload.message = "Reports > IdleReport > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "IdleReport";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getIdleData(payload);
    } else if (payload.search.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getIdleData(payload);
      
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getIdleData(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getIdleData(payload);
    }
  };

  
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (idleData.data !== null) {
        setResponseData(idleData.data.content);
        setTotalElements(idleData.data.totalElements);
        setTotalPages(idleData.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="IdleReport"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"IdleReport"} />
      <Box>
        <FilterDropdown
          getData={onSubmitForm}
          status={false}
          allowDays={31}
          allowToday={true}
          last6Month={false}
          time = {true}
          allowedMonth = {3}
          last31={ false}
          getDataLoading={isLoading}
        />

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={true}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              handleChange={handleSearch}
              includeExportButton={true}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default IdleReport;
