import { Avatar, Box, IconButton, ListItem, ListItemAvatar, ListItemText, Stack, Typography, FormLabel,  Icon, InputBase, Paper, useTheme} from '@mui/material'
import React from 'react'
import { IoClose } from "react-icons/io5";
import { useSelector,useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import FormDialog from "../../../Utils/FormDialog";
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { BsWechat } from 'react-icons/bs';
import { fontSize } from '@mui/system';
import { chatBotSingleChatData } from "../../../store/slice/ControlsSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ChatBotHeader({close}) {


  const theme = useTheme();
  const dispatch = useDispatch();  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {   
    setOpen(true);    
  };
  const confirmClose=()=>{    
    close();
  }

  const handleClose = () => {
    setOpen(false);    
    dispatch(chatBotSingleChatData([]));
  };
  const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
  return (
    <Box className='chatbot_header'>
        <Stack>
            <Box>
                <ListItem dense
                    secondaryAction={
                        // <IconButton size='small' sx={{color:'#fff'}} onClick={close}>
                        <IconButton size='small' sx={{color:'#fff'}} onClick={handleClickOpen}>
                            <IoClose />
                        </IconButton>
                    }
                >
                    <ListItemAvatar>
                            <Avatar src='https://media.istockphoto.com/id/1957053641/vector/cute-kawaii-robot-character-friendly-chat-bot-assistant-for-online-applications-cartoon.jpg?s=612x612&w=0&k=20&c=Uf7lcu3I_ZNQvjBWxlFenRX7FuG_PKVJ4y1Y11aTZUc='/>
                            {/* <BsWechat color='orange' size={'30px'} sx={{color:'#fff',fontSize:'16px'}} /> */}
                    </ListItemAvatar>
                    <ListItemText 
                        primary={<Typography variant='body1' sx={{color:'#fff',marginBottom:"-10px"}}>AI Bot (beta)</Typography> }
                        secondary={<Typography variant='caption' sx={{color:'#fff',fontSize:"8px"}}>Powered by ChatGPT 4o mini</Typography>}
                        sx={{color:'#fff'}}
                    />
                  
                    
                </ListItem>
               

            </Box>

          {/* <FormDialog title={''} showHeader={false} size="xs" close={false}  >
                    <Typography>Your chat data will be cleared. Please confirm</Typography>
                    <DialogActions sx={{mt:1}}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={confirmClose} autoFocus>
            Confirm
          </Button>
        </DialogActions>
              </FormDialog> */}

       <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          
        >
          <DialogContent sx={{background: theme.palette.background.default, position: 'relative' }}>
          <IconButton 
              onClick={handleClose} // Replace with your close handler
              sx={{ position: 'absolute', right: 3, top: 2,backgroundColor: 'rgba(255, 99, 71)', // Initial background color
                borderRadius: '50%', 
                width: 23,
                height: 23,
                color:'#ffff',
                '&:hover': {
                    backgroundColor: 'rgba(255, 0, 0)', // Background color on hover
                } }} // Positioning the button
          >
              <CloseIcon />
          </IconButton>
            <DialogContentText id="alert-dialog-slide-description">
            <Box sx={{marginTop:"10px",marginBottom:"-6px"}}>
            <Typography>Your chat data will be cleared. Please confirm</Typography>
          </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{background: theme.palette.background.default }}>
            <Button style={{padding: "5px 5px",border: "none", borderRadius: "5px",color: "white",cursor: "pointer",transition: "background-color 0.3s",backgroundColor: "#f44336"}} onClick={handleClose}>Cancel</Button>
            <Button style={{padding: "5px 5px",border: "none", borderRadius: "5px",color: "white",cursor: "pointer",transition: "background-color 0.3s",backgroundColor: "#4CAF50"}} onClick={confirmClose}>Confirm</Button>
          </DialogActions>
        </Dialog>
        </Stack>
    </Box>
  )
}

export default ChatBotHeader    