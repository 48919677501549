import { CardContent, Chip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useAuditLogMutation, useVehicleDataMutation } from "../../store/slice/ApiSlice";
import {
  StyledCardShadow,
  StyledPageLayout
} from "../../theme/StyledEle";
import GetLocation from "../Location";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { DataTable } from "../shared/data_table/DataTable";
import MouseOverPopover from "../shared/PopOver";

const text =
  "This Report lists significant parameter details of vehicles for each minute.";

const ManualCanDataReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let distanceUnit = userData.distanceDisplayUnit;
  let speedUnit = userData.speedDisplayUnit;
  let distanceValue = distanceUnit === "mi"?2:1;

  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState("");

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  }
  useEffect(async () => {
    auditPayload.message = 'Reports > VehicleData';
    auditPayload.action = 'View';
    auditPayload.featureName = 'VehicleData';
    auditPayload.customerName = 'All';
    auditPayload.fleetName = "All";
    auditPayload.result = 'Success';
    auditPayload.access = 'Portal';
    auditPayload.severity = 'INFO';
    auditLog(auditPayload);
  }, []);


  const columns = useMemo(
    () => [
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
        width: 180,
      },
      {
        Header: "Date Time",
        accessor: "createdDate",
      },

      {
        Header: "GPS Location",
        accessor: "location",
        width: 180,
        Cell: ({ row }) => {
          return (
            row.original.location !== "" && row.original.location !== "NA" ?
              <GetLocation
                row={row} columnName={row.original.location} /> : '-'
          )
        }
      },

      {
        Header: "Status",
        accessor: "strgpsMoveStatus",
        Cell: ({ row }) => {
          if (row.original.strgpsMoveStatus !== '-') {
            return <Chip label={row.original.strgpsMoveStatus} size="small" color={row.original.strgpsMoveStatus === 'Moving' ? 'success' : row.original.strgpsMoveStatus === 'Idle' ? 'primary' : 'error'} />
          } else {
            return row.original.strgpsMoveStatus
          }
        }
      },
      {
        Header: "Speed (" + speedUnit + "ph)",
        accessor: "speed",
      },
      {
        Header: "Odometer (" + distanceUnit + ")",
        accessor: "odometer",
      },
      {
        Header: "Ignition",
        accessor: "ign",
      },
      {
        Header: "Fuel Level (L)",
        accessor: "oemFuelLevel",
      },
      {
        Header: "Fuel Level Percentage (%)",
        accessor: "fuelLevelPercent",
      },
      {
        Header: "Engine Operational hours (hrs)",
        accessor: "engineOPHours",
      },
      {
        Header: "Engine Coolant Temperature (°C)",
        accessor: "engineCoolantTemp",
      },
      {
        Header: "Engine Oil Temperature (°C)",
        accessor: "engineOilPressure",
      },
      {
        Header: "Engine Speed (rpm)",
        accessor: "engineSpeed",
      },
      {
        Header: "Internal Battery (V)",
        accessor: "intBatVoltPercentage",
      },
      {
        Header: "External Battery (V)",
        accessor: "externalBatteryVolt",
      },
      {
        Header: "Throttle Position",
        accessor: "throttlePosition",
      },
      {
        Header: "Engine Load",
        accessor: "engineLoad",
      },
      {
        Header: "Active DTC",
        accessor: "activeDTC",
      },
      {
        Header: "Total Mileage ("+speedUnit+"pl)",
        accessor: "totalMileage",
      },
      {
        Header: "Green Driving Type",
        accessor: "greenDrivingType",
      },
    ],
    []
  );

  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [assignedval, setAssignedval] = useState({});


  // Get Data from New Dropdown

  const [getVehicleData, { data: vehicleData, isLoading, isSuccess }] = useVehicleDataMutation();
  let commonPayload = {}
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(50);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.status = data.vehicleStatusId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = "desc";
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    //Service Call
    setPayload(commonPayload);
    getVehicleData(commonPayload);

    auditPayload.message = "Reports > Vehicle Data > " + data.regionName + " > " + data.dealerName + " > " + data.customerName + " > " + data.fleetName + " > " + data.vehicleName + " > " + data.statusName + " > " + data.selectedPeriodName + "(" + data.fromDate + "-" + data.toDate + ")" + " > " + "Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Vehicle Data Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      if (resellerId === 109882) {

        var url =
          process.env.REACT_APP_API_BASE_URL +
          "ManualCanDataReport/vehicledataXLS.csv?startDate=" +
          payload.startDate +
          "&endDate=" +
          payload.endDate +
          "&orderDir=" +
          payload.orderDir +
          "&orgId=" +
          payload.orgId +
          "&status=" +
          payload.status +
          "&resellerId=" +
          payload.resellerId +
          "&vehicleId=" +
          payload.vehicleId;
      } else {
        var url =
          process.env.REACT_APP_API_BASE_URL +
          "ManualCanDataReport/vehicledataXLS.csv?startDate=" +
          payload.startDate +
          "&endDate=" +
          payload.endDate +
          "&orderDir=" +
          payload.orderDir +
          "&orgId=" +
          payload.orgId +
          "&status=" +
          payload.status +
          "&resellerId=" +
          payload.resellerId +
          "&vehicleId=" +
          payload.vehicleId +
          "&distanceUnit=" +
          distanceUnit +
          "&distanceDisplayOption=" +
          distanceValue;
      }
      window.open(url);

      auditPayload.message = 'Reports > Vehicle Data > Export > Excel';
      auditPayload.action = 'View';
      auditPayload.featureName = 'VDR';
      auditPayload.customerName = 'All';
      auditPayload.fleetName = 'All';
      auditPayload.result = 'Success';
      auditPayload.access = 'Portal';
      auditPayload.severity = 'INFO';
      auditLog(auditPayload);


      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => { })
        .catch((err) => { });
    } catch (error) { }
  };
  const onChangePdf = async () => {

    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "ManualCanDataReport/vehicledataPDF.pdf?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&status=" +
        payload.status +
        "&vehicleId=" +
        payload.vehicleId;


      window.open(url);

      auditPayload.message = 'Reports > Vehicle Data > Export > PDF';
      auditPayload.action = 'View';
      auditPayload.featureName = 'VDR';
      auditPayload.customerName = 'All';
      auditPayload.fleetName = 'All';
      auditPayload.result = 'Success';
      auditPayload.access = 'Portal';
      auditPayload.severity = 'INFO';
      auditLog(auditPayload);
    } catch (error) { }
  };
  //Search Text
  const handleSearch = async (searchText) => {
    setNewPage(0);
    setRowsPerPage(50);
    payload.page = 0;
    payload.rowsPerPage = 50;
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getVehicleData(payload);
    } else if (payload.search.length === 0) {
      getVehicleData(payload);
    }

  };
  //Page Change
  // const pageChange = (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   payload.page = newPage;
  //   payload.size = rowsPerPage;
  //   payload.start=newPage;
  //   payload.length=rowsPerPage;
  //   getVehicleData(payload);
  // } 

  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.start = newPage;
    payload.length = rowsPerPage;
    if (totalElements < rowsPerPage * newPage) {
      setNewPage(0)
      payload.start = 0;
      getVehicleData(payload);
    } else {
      setNewPage(newPage);
      payload.start = newPage;
      getVehicleData(payload);
    }
  };


  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (vehicleData.data !== null) {
        setResponseData(vehicleData.data?.content);
        setTotalElements(vehicleData.data?.totalElements);
        setTotalPages(vehicleData.data?.totalPages);
      }
    }
  }, [isSuccess]);

  // useEffect(() =>{
  //   if(isLoading){
  //     setResponseData([]);
  //   }
  // },[isLoading])


  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="Vehicle Data Report" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"Vehicle Data Report"} />
      <Box>
        {/* <VDRDropDown
          onSubmit={onSubmit}
          callbackLoadFn={callbackLoadFn}
          isStatus={true}
          displayDate={false}
          allowDays={0}
        /> */}
        <FilterDropdown getData={onSubmitForm} isVehicleData={true} isShowAll={false} allowDays={31}
          time={true} allowedMonth={3} last7={true} getDataLoading={isLoading} offToday={true} setToday={true}  thisMonth={true} currentMonth={true}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={false}
              loading={loading}
              includeSearchBox={true}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePdf}
              columns={columns}

              data={responseData}
              styles={{ maxWidth: "89vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              //callbck={handleSort}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default ManualCanDataReport;
