import { useTheme } from '@mui/material';
import { Box } from '@mui/material'
import React, { useEffect, useMemo, useRef } from 'react'
import ReactApexChart from 'react-apexcharts';
import ReactEcharts from "echarts-for-react"
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS =  CanvasJSReact.CanvasJS;

function Chart({timerSeries, valueY}) {
    const theme = useTheme();
    const speedRef = useRef();
    // useEffect(() => {
    //     if(speedRef.current !== undefined){
    //       speedRef.current.options.data[0].dataPoints = timerSeries;
    //     }
    // },[timerSeries.length, speedRef.current]);

    const containerProps = {
      height: "150px"
    };

    const options = {
      theme: 'light2',
      height:150,
      animationEnabled: true,
      animationDuration: 2000,
      axisX:{
        labelFormatter: function (e) {
          return CanvasJS.formatDate(e.value, "HH:mm:ss")
        }
      },
      toolTip:{
        contentFormatter: function ( e ) {
                    return "Speed: " +"<strong>" +  e.entries[0].dataPoint.y + "</strong>" + "<small>kmph</small>" ;  
        }  
      },
      // title: {
      //   text: 'CanvasJS Chart - React Functional Component',
      // },
      data: [
        {
          type: 'splineArea',
          dataPoints: [],
        },
      ],
    }


    const useChart = useMemo(() => {
      return {...options, ...options.data[0].dataPoints = timerSeries};
    },[timerSeries])

    
  return (
    <Box sx={{width:'100%', height:'fit-content'}}>
        {/* <ReactEcharts 
          ref={speedRef}
          option={options}
          style={{ width: "auto", height: `250px`}}
          renderer={'svg'}
          notMerge={true}
        /> */}
        <CanvasJSChart options={useChart} onRef={ref => (speedRef.current = ref)} containerProps={containerProps} />
        {/* <ReactApexChart options={chartdata} series={chartdata.series} /> */}
    </Box>
  )
}

export default Chart