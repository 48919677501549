import { Box, Dialog, DialogTitle, Divider, FormLabel, Grid, IconButton, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import SingleDropdown from '../components/NewDropdown/SingleDropdown'
import { StyledFormControl, StyledPaperShadow } from '../theme/StyledEle'
import { Accordion } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {IoMdClose} from 'react-icons/io'
 
function FormDialog({children, title='', showHeader=true, size='lg', maxWidth='1200', close=true, padding=true, clickaway = false}) {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();


  let hashId = location.hash;

  const onClose = (clickaway) => {
    if(clickaway){
      navigate(`${location.pathname}`);
    }
    
  };

  return (
    <Dialog
      open={
        hashId === "#edit" || hashId === "#view" || hashId === "#popup" || hashId==="#changeIMEI" || hashId==="#assign" || hashId ==='#serviceEdit'
          ? true
          : false
      }
      maxWidth={size !== false && size}
      sx={{
        maxWidth: maxWidth !== false && maxWidth,
        margin: "auto",
        overflowY: "hidden",
      }}
      fullWidth
      onClose={() => onClose(clickaway)}
    >
      { showHeader && 
      <Box sx={{ px: 2, py: 1 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          
             <Typography>{title}</Typography>
          
          

          {close && (
            <IconButton color="error" onClick={() => onClose(true)}>
              <IoMdClose />
            </IconButton>
          )}
        </Stack> 
      </Box> }
      <Box sx={{ p: padding ? 2 : 0, background: theme.palette.background.default }}>
        {children}
      </Box>
    </Dialog>
  );
}

export default FormDialog;
