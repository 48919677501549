import { Backdrop, Box, Grid, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { StyledPageLayout } from '../../theme/StyledEle'
import FilterDropdown from '../NewDropdown/FilterDropdown'
import Map from './Map';
import Controls from './Controls';
import { useAuditLogMutation, usePlaybackMutation } from '../../store/slice/ApiSlice';
import moment from 'moment';
import { GridLoader } from 'react-spinners';
import { NoTripFound } from './CurrentStatus';

function Playback() {
    const theme = useTheme();
    //service
    const [ getPlaybackData, {data:playbackData, isLoading:playbackLoading, isSuccess:plackbackSuccess}] = usePlaybackMutation();
    const [auditLog] = useAuditLogMutation();


    let [currentLocation, setCurrentLocation] = useState({target:1, lat:null, lng:null, distance:0});
    const [payload, setPayload] = useState(null);



    //Datas
    const [tripData, setTripData] = useState(null);
    const [playbackDatas, setPlaybackDatas] = useState(null);
    const [trailsData, setTrailsData] = useState([]);
    const [rawData, setRawData] = useState(null);
    const [duration, setDuration] = useState({startTime:'00:00:00', endTime:'23:59:59'});
    const [playPaths, setPlayPaths] = useState();
    const [newData, setNewData] = useState([]);
    const [playData, setPlayData] = useState([]);
    const [animPath, setAnimePath] = useState([]);
    const [timeCode, setTimeCode] = useState([]);
    const userData = JSON.parse(localStorage.getItem('userData'));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    let mapTypeData = userData.mapType;


    let movingMarkerRef = useRef();
    let timerRef = useRef();
    const mapRef = useRef(null);
    let auditPayload = {};
    let commonPayload = {};

    //get playback data
    const handlePlaybackData = async (payload) => {
        const playBackData = await  getPlaybackData(payload);
        if(playBackData?.data !== null && playBackData?.data !== undefined && playBackData?.data?.playBackList.length > 0){
            setPlaybackDatas(playBackData.data?.playBackList[0]);
            setTripData(playBackData);
            setRawData(playBackData);
            getDate(0, playBackData?.data);
            setDuration({ startTime: moment(playBackData?.data?.tripStartDate, 'DD-MM-YYYY HH:mm').format('HH:mm'), endTime:moment(playBackData?.data?.tripEndDate, 'DD-MM-YYYY HH:mm').format('HH:mm')})
            movingMarkerRef?.current?.marker.setPosition(playPath[0]);
            //handleDays(1,1); playbackData?.playBackList[0].playBackList
        }else{
            setPlaybackDatas(null);
            setTripData([]);
            setRawData([]);
            setAnimePath([]);
            setPlayPaths([])
        }
    }

    // animation variables
    let playPath = [];
    let trails = [];
    const pathGenrate = (pathList) => {
        setPlayPaths(null);
        let array = [];
        pathList.forEach((key, index) => {
            let latlng = key.latlng.split(',')
            let playPathItem = {};
            playPathItem.lat = parseFloat(latlng[0]);
            playPathItem.lng = parseFloat(latlng[1]);
            playPath.push(playPathItem);
            
            array.push(Object.values(playPathItem));

            // change trails
            //let startDateTimeStamp = moment(key.startDate).unix();
        })
        setPlayPaths(playPath);
        setMarkerData(playPath);
    }

    //Time Duaration
    const FindDuration = (timeSeries) => {
        setNewData(timeSeries);
        setPlayData(timeSeries);
        const miliSecFirst = moment.duration(moment(timeSeries[0].startDate, "DD-MM-YYYY 00:00:00") -  moment(timeSeries[0].startDate, "DD-MM-YYYY HH:mm:ss"));
        const miliSecLast = moment.duration(moment(timeSeries[timeSeries.length - 1].startDate, "DD-MM-YYYY 00:00:00") -  moment(timeSeries[timeSeries.length - 1].startDate, "DD-MM-YYYY HH:mm:ss").add(10, 'seconds'));
        console.log('miliSecLast', miliSecLast);
        if(parseInt(miliSecFirst._milliseconds) !== 0){
            let timeObj = {};
            timeObj.fromMs = 0;
            timeObj.description = "Trip yet to start"
            setTimeCode(timeCode => [...timeCode, timeObj])
        }
        
    
        timeSeries.forEach((date, index) => {
            let timeObj = {}
            // let date1 = moment(date.startDate, "DD-MM-YYYY 00:00:00");
            // let date2 = moment(date.startDate, "DD-MM-YYYY HH:mm:ss");
            // const miliSec = moment.duration(date2 - date1);
            // const mili = miliSec._milliseconds;
            // const newSet = {...date, 'mili':mili};
            // setNewData((oldData) =>  [...oldData, newSet])
            // setPlayData((oldPlaydata) => [...oldPlaydata, newSet])
            if(index === 0){
                setOdometer(parseFloat(date.odometer).toFixed(2))
                setCurrentTime(date.milliSeconds);
                timeObj.fromMs = date.milliSeconds;
                timeObj.description = "Trip start"
                setTimeCode(timeCode => [...timeCode, timeObj])
            }else if(index === parseInt(timeSeries.length - 1)){
                timeObj.fromMs = date.milliSeconds;
                timeObj.description = "Trip End";
                setTimeCode(timeCode => [...timeCode, timeObj])
            }else if(index === 1){
                timeObj.fromMs = parseInt(date.milliSeconds) + 50000 ;
                timeObj.description = "Trip start"
            }
            
        })
        let timeObj = {};
        timeObj.fromMs = miliSecLast._milliseconds * -1;
        timeObj.description = "Offline"
        setTimeCode(timeCode => [...timeCode, timeObj])
    }

    ////////////////////// seek bar /////////////////////
    const [currentTime, setCurrentTime] = useState(600000);

    const handleChange = (time, offsetTime) => {
        setCurrentTime(time);
    }
   
    const findPath = (milisecond, list) => {

        setAnimePath([])
        const index = newData.filter((item) => item.milliSeconds <= milisecond);
        if(index.length > 0){
            let latlng = index[index.length - 1].latlng.split(',');
            console.log('latlng', playPaths[index.length - 1]);
            setAnimePath(playPaths.slice(0, [index.length - 1]));
            // findDistanceOdo(newData[index.length - 1]);
            setCurrentLocation({target:index.length, distance:0, lat:latlng[0], lng:latlng[1]});
            if(movingMarkerRef.current !== undefined){
                movingMarkerRef?.current?.marker.setPosition(playPaths[index.length - 1]);
            }
        }else{
            //setAnimePath([]);
            setCurrentLocation({target:1, distance:0, lat:null, lng:null});
            if(newData !== undefined && newData.length > 0 && movingMarkerRef.current !== undefined){
                let latlng = newData[0].latlng.split(',');
                movingMarkerRef?.current?.marker?.setPosition(new window.google.maps.LatLng(latlng[0], latlng[1]));
            }
            
        }
        // const path = newData.map((item) => {
        //     if(item.mili <= milisecond){
        //         let latlng = item.latlng.split(',');
        //         let playPathItem = {};
        //         playPathItem.lat = parseFloat(latlng[0]);
        //         playPathItem.lng = parseFloat(latlng[1]);
        //         animePath.push(playPathItem);
        //     }else{
        //         //animePath.push([]);
        //         setAnimePath([]);
        //     }
           
        // });
        //setAnimePath(animePath);
    }
    const onSubmitForm = (data) => {
        //setCurrentLocation({target:1, lat:null, lng:null, distance:0});
        commonPayload.truckId=data.vehicleId;
        commonPayload.orgId= data.fleetId;
        commonPayload.startDate= data.fromDate;
        commonPayload.endDate= data.toDate;
        commonPayload.startOdometer= 0;
        commonPayload.endOdometer= 0;
        //Service Call
        let dateObj = new Date(1729276199999);
        let utcString = dateObj.toUTCString();   
        handlePlaybackData(commonPayload);
        setOdometer(0);
        setDistanceT(0);
        setAlerts(0);
        setAnimePath([]);
        setNewData([]);
        setPlayData([]);
        setCurrentTime(0);
        setCurrentLocation({target:1, distance:0, lat:null, lng:null})
        setStop(false);
        setTimeCode([])
        setPayload(data);
        // setTrailsData([]);
        // setPlayState(false);
        
       
       
       
        //Audit Log
        auditPayload.message = "Tracking > PlayBack";
        auditPayload.action = "View";
        auditPayload.featureName = "Playback";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditPayload.orgId = orgId;
        auditPayload.userId = userId;
       
        auditLog(auditPayload);
    }



    /* ------------------------------------------------------------------------------------------------------------------- */
        /* Timer */
        const [counter, setCounter] = useState(1);
        const [stop, setStop] = useState(false);
       
        var counterTimer;

        useEffect(() => {
            if(!stop){
                findPath(currentTime, playData);
            }
           
        },[currentTime])


        useEffect(() => {
            if (stop) { // 
                counterTimer = setInterval(() => {
                    setCounter((pre) => pre + 1);
                }, 1000);
                
            }
            return () => clearInterval(counterTimer);
        },[stop])

        // const findLocation = (miliSecond) => {
        //     const currentLocation = newData.find(item => {
        //     });
            
        // }

        const playHandler = () => setStop(!stop);

        const resetHandler = () => {
            setCounter(1);
            setStop(true);
            setAnimePath([]);
        };

        const stopHandler = () => {
            setStop(false);
            //onSubmitForm(payload);
            setAnimePath([]);
            setCurrentLocation({target:1, distance:0, lat:null, lng:null});
            setCurrentTime(newData[0]?.milliSeconds)
            movingMarkerRef?.current?.marker.setPosition(playPaths[0]);
            setTimeX([])
        };

        const speedHandler = (speedValue) => {
            setSpeed(speedValue);
            setStop(false);
            setTimeX([])
        }

        const forwardHandler = () => {
            var pathAnim = [];
            console.log('playPath[[animPath.length + 1]] !== undefined', playPaths[animPath.length + 1].lat);
            //let latLng = playPath[animPath.length + 1]?.latlng.split(',');
            if(playPaths[animPath.length + 1] !== undefined){
                let pathObj = {lat:parseFloat(playPaths[animPath.length + 1].lat), lng:parseFloat(playPaths[animPath.length + 1].lng)};
                movingMarkerRef.current.marker.setPosition(playPaths[animPath.length + 1])
                pathAnim.push(pathObj);
                setAnimePath((oldArry) => [...oldArry, ...pathAnim]);
                setCurrentTime(newData[animPath.length + 1].milliSeconds);
            }
            
        }


        const backwardHandler = () => {
            let latLng = newData[animPath.length - 1]?.latlng.split(',');
            // if(playPaths[animPath.length - 1] !== undefined && animPath.length > 0){
            //     setAnimePath((oldArry) => (oldArry.slice(0, -1)));
            //     console.log('animePath',animPath, animPath.slice(0,-1), animPath.length);
            //     movingMarkerRef.current.marker.setPosition(animPath.slice(0,-1));
            //     setCurrentTime(newData[animPath.length - 1].milliSeconds);
            // }
            if(animPath.length > 0){
                setAnimePath((oldArry) => (oldArry.slice(0, -1)));
                movingMarkerRef.current.marker.setPosition(animPath[animPath.length - 1]);
                //setCurrentTime(newData[animPath.length - 1].milliSeconds);
            }
            
        }
        console.log(animPath);
    /* ------------------------------------------------------------------------------------------------------------------- */


    ///////////////////Animation Start
    let linerArray = [];
    var delay = 100;
    let [speedValue, setSpeedValue] = useState(500000);
    let [speed, setSpeed] = useState(320);  // km/h
    let [markerData, setMarkerData] = useState([]);
    let [rotation, setRotation] = useState(90);
    let [vehicleSpeed, setVehicleSpeed] = useState(0);
    let [Odometer, setOdometer] = useState(0);
    let [distanceT, setDistanceT] = useState(0);
    let [alerts, setAlerts] = useState(0);
    const missTimerRef = useRef();

    useEffect(() => {
        if (stop) {
            animateMarker(movingMarkerRef.current, speed, true);
            mapRef.current.panTo({ lat: movingMarkerRef.current?.marker.position.lat(), lng: movingMarkerRef.current?.marker.position.lng() })
            setStop(true);
        } else {
            clearTimeout(timerRef.current);
            clearInterval(missTimerRef.current);
        }
    }, [stop])


    let count1 = 1000;
    let speedCounter = 0;
    const missingTime = (curreTime, nextTime) => {
        count1 += 1000;
        speedCounter += 1;
        setCurrentTime(oldTime => oldTime + count1);
        setVehicleSpeed((oldSpeed) => oldSpeed + speedCounter)
        if (curreTime <= nextTime && stop) {
            clearInterval(missTimerRef.current);
            count1 = 1000;
        }else{
            clearInterval(missTimerRef.current);
        }
    }   


    const findDistanceOdo = (item) => {
        ///Odometer
        const startOdoMeter = newData[0]?.odometer;
        const currentOdoMeter = item?.odometer;
        const distanceTravelled = parseFloat(currentOdoMeter) - parseFloat(startOdoMeter);
        setDistanceT(parseFloat(distanceTravelled));
        if(stop){
            setOdometer(parseFloat(item?.odometer));
        }else{
            setOdometer(parseFloat(item?.odometer));
        }
        
    }


    var distance = 0;
    var dest = 0;
    var step = 0;
    var lat = 0;
    var lng = 0;    
    var speedLen = 5;
    const [timeX, setTimeX] = useState([]);
    const [valueY, setValueY] = useState([]);
    var x = [];
    var value = [];
    var commonObj = [];
    const [speedData, setSpeedData] = useState({xSeries:[], ySeries:[]})
    const animateMarker = (marker,km_h, state) => {
        var target = currentLocation.target;
        var pathAnim = [];
        var km_h = km_h || 50;
        //setTrailsData([...trailsData, playbackData.playBackList[0].playBackList[0]]);
        function goToPoint(){
        let part =[];
        part.push(newData[target]);
           if(part[0] !== undefined && marker !== undefined){
            if(stop){
                missTimerRef.current = setInterval(missingTime(currentTime, newData[target].milliSeconds), 1000);
                findDistanceOdo(newData[target]);
            }else{
                clearInterval(missTimerRef.current)
            }
            
               linerArray=[...part, ...linerArray];
               setTrailsData(linerArray);
               lat = marker?.marker?.position?.lat();
               lng = marker?.marker?.position?.lng();
               step = (km_h * 1000 * delay) / speedValue ; // in meters;
                console.log('step', step);
               dest = new window.google.maps.LatLng(markerData[target].lat, markerData[target].lng);
               distance = window.google.maps.geometry.spherical.computeDistanceBetween(dest, marker.marker.position); // in meters
               setCurrentLocation({target:target, distance:distance, lat, lng})
               let point1 = {lat:markerData[target-1].lat, lng:markerData[target-1].lng}
               let point2 = {lat:markerData[target].lat, lng:markerData[target].lng}
               var angle = window.google.maps.geometry.spherical.computeHeading(point1, point2);
               setRotation(angle);
               var numStep = distance / step;
               var i = 0;
               var deltaLat = (parseFloat(markerData[target].lat) - lat) / numStep;
               var deltaLng = (parseFloat(markerData[target].lng) - lng) / numStep;
            
               

               function moveMarker()
               {
               lat += deltaLat;
               lng += deltaLng;
               
               i += step;
               setCurrentLocation({lat:lat, lng:lng, target, distance});

               if (i < distance)
               {
                
               let pathObj = {lat:lat, lng:lng}
               pathAnim.push(pathObj);
               setAnimePath([...animPath, ...pathAnim])
               marker.marker.setPosition(new window.google.maps.LatLng(lat, lng));
               mapRef.current.panTo({lat:lat, lng:lng});
               timerRef.current = setTimeout(moveMarker, 100);

               //speed
               //setSpeedData({xSeries:x, })
              
               }
               else
               {
               setCurrentTime(newData[target].milliSeconds);
              
               if(x.length <= speedLen){
                if(moment(newData[target].startDate, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss') !== x[x.length - 1]){
                    //setTimeX((oldTime) => [...oldTime, moment(newData[target].startDate, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')])
                    x.push(moment(newData[target].startDate, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss'));
                    value.push(parseFloat(newData[target].speed));
                    let obj = {};
                    obj.x = new Date(newData[target].startDate);
                    obj.y = parseFloat(newData[target].speed);
                    commonObj.push(obj);
                }
                
               }else{
                x.shift();
                value.shift();
                commonObj.shift();
               }
               setTimeX(commonObj);
               setValueY(value);
               target++;
               timerRef.current = setTimeout(goToPoint, 100);
               }
               }
               moveMarker();
        }else{
            setStop(false);
            //setCurrentTime(86399000);
            clearTimeout(timerRef.current);
            clearInterval(missTimerRef.current)
        }
        }
        goToPoint();
       
    }


    ///////// Dates ///////////////////////////
    
    const [date, setDate] = useState(0);
    const getDate = (value, data, datas) => {
        if(value !== null){
            setDate(parseInt(value));
            setNewData([]);
            setCurrentTime(0);
            setPlayData([]);
            setMarkerData([]);
            setPlayPaths([]);
            setOdometer(0);
            setDistanceT(0);
            setAlerts(0);
            setAnimePath([]);
            setCurrentLocation({target:1, distance:0, lat:null, lng:null})
            setStop(false);
            setTimeCode([])
            FindDuration(data?.playBackList[parseInt(value)].playBackList);
            pathGenrate(data?.playBackList[parseInt(value)].playBackList);
        }
        
    }


  return (
    <StyledPageLayout sx={{height:'100%', padding:'0px'}}>
        {
            <Backdrop open={playbackLoading} sx={{zIndex:1111}}>
            <GridLoader color={theme.palette.secondary.main}/>
            </Backdrop>
        }
        
        <Box sx={{height:'100%'}}>
            <Grid container spacing={0} sx={{height:'100%', display:'grid', gridTemplateRows:'auto 1fr'}}> 
                <Grid item lg={12} >
                    <Box sx={{padding:'20px 20px 10px 20px'}}>
                    <FilterDropdown getData={onSubmitForm} status={false} isShowAll={false} allowDays={3} onload={false} last7={false} time={true} offToday={true} allowedMonth = {3}/>
                    </Box>
                </Grid>
                <Grid item lg={12} sx={{position:'relative'}}>
                    <Grid container sx={{height:'100%'}}>
                        <Grid item lg={8.5} xl={9}>
                            <Map playPath={playPaths} animPath={animPath} movingMarkerRef={movingMarkerRef} mapRef={mapRef} rotation={rotation} date={date}/>
                            </Grid>
                            <Grid item lg={3.5} xl={3} sx={{pr:2.5, pl:1, background:'#90daee', pt:1}}>
                            {
                                playbackDatas !== null ? 
                                <Controls 
                                play={playHandler} 
                                stop={resetHandler} 
                                pause={stopHandler} 
                                playStatus={stop} 
                                counter={counter} 
                                rawData={tripData} 
                                handleChange={handleChange} 
                                currentTime={currentTime} 
                                timeCode={timeCode} 
                                rotation={rotation}
                                speed={newData[currentLocation.target]?.speed}
                                odometer={Odometer}
                                startOdo={newData[0]?.odometer}
                                distanceT={distanceT}
                                status={newData[currentLocation.target]?.vehicleStatus}
                                getDate={getDate}
                                date={date}
                                newData={newData}
                                speedHandler={speedHandler}
                                speedValue={speed}
                                forwardHandler={forwardHandler}
                                backwardHandler={backwardHandler}
                                timerSeries={timeX}
                                valueSeries={valueY}
                            /> : <NoTripFound data={playbackDatas === null && !playbackLoading} payload={payload}/> 
                            }
                            
                        </Grid>
                        </Grid>
                       
                    </Grid>
                    {/* {
                        playbackDatas !== null ? 
                    
                    <Controls 
                    play={playHandler} 
                    stop={resetHandler} 
                    pause={stopHandler} 
                    playStatus={stop} 
                    counter={counter} 
                    rawData={tripData} 
                    handleChange={handleChange} 
                    currentTime={currentTime} 
                    timeCode={timeCode} 
                    rotation={rotation}
                    speed={newData[currentLocation.target]?.speed}
                    odometer={Odometer}
                    startOdo={newData[0]?.odometer}
                    distanceT={distanceT}
                    status={newData[currentLocation.target]?.vehicleStatus}
                    getDate={getDate}
                    date={date}
                    newData={newData}
                    speedHandler={speedHandler}
                    speedValue={speed}
                    />  : <NoTripFound data={playbackDatas === null && !playbackLoading} payload={payload}/>
                    } */}
                    
            </Grid>
        </Box>
    </StyledPageLayout>
  )
}

export default Playback