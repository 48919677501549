import {
  Backdrop,
  Box,
  Button,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  alpha,
  useTheme,
  Avatar,
  Typography,
  Stack,
} from "@mui/material";
import {
  Circle,
  DirectionsRenderer,
  GoogleMap,
  HeatmapLayer,
  InfoWindow,
  Marker,
  MarkerF,
  Polygon,
  Polyline,
  TrafficLayer,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GOOGLE_MAP_KEY } from "../../constants/appConstant";
import { GridLoader, HashLoader, ScaleLoader } from "react-spinners";
import BottomBar from "./BottomBar";
import CountBar from "./CountBars";
import MapControls from "./MapControls";
import Treeview from "./Treeview/Treeview";
import {
  useGetRegionListMutation,
  useGetVehicleByResellerMutation,
  useGetVehicleCategoryByResellerMutation,
  useGetVehicleCountMutation,
  useGetVehicleDetailsMutation,
  useAuditLogMutation,
  useChargingMutation
} from "../../store/slice/ApiSlice";
import MarkerRow from "./Marker";
import { useDispatch, useSelector } from "react-redux";
import {
  dropVehicles,
  isLoadingState,
  refresh,
  routeTrips,
  selectedVehicleTrailsData,
  selectedVehicleCategoryListData,
  vehicleCountSlice,
  singleVehicle,
  selectedVehicleData,
  searchCategoryKeyWord,treviewDate, vehicleSummaryWindow, searchKeyWord, ChargingMarkers, StopPageMarkers
} from "../../store/slice/ControlsSlice";
import {
  EndMarker,
  OfflineMarker,
  StartMarker,
  TrailsMarkersDot,
  TrailsPolyline,
} from "./TrailsMakers";
import InfowindowTrailsMarker from "./NewTreeView/Infowindow";
import { ControlsBox } from "../../theme/StyledEle";
import { RiZoomInLine, RiZoomOutLine } from "react-icons/ri";
import { FiLayers } from "react-icons/fi";
import DefaultMap from "../../assets/images/roadamap.png";
import Terrain from "../../assets/images/terrain.png";
import Satellite from "../../assets/images/satellite.png";
import { Dark, Day } from "../../theme/gmapTheme";

import { StyledInfoWindow } from "./Treeview/TreeviewStyledEle";
import RoutesBar from "./RoutesBar/RoutesBar";
import CategoryBar from "./CategoryBar/CategoryBar";
import SubCategory from "./CategoryBar/SubCategory";
import moment from "moment";
import LeafletMap from "./hereMap/leaflet";
import { useVehicleimageMutation} from '../../store/slice/ApiSlice'
import { useGetRouteListForMapMutation } from "../../store/slice/RouteTripSlice/RouteTripSlice";
import RouteMarkers from "./RoutesBar/RouteMarkers";
import GeoMarker from "./GeoZone/GeoMarker";
import AlertMarker from "./Alerts/AlertMarker";
import VehicleMainSumary from "./CategoryBar/VehicleMainSumary";
import SummaryWindow from "./CategoryBar/SummaryWindow";
import { ChargingMarker } from "./extraMarkers/extraMarkers";
import axios from "axios";
import { useCleanAirZonesMutation, useLowBridgesMutation, useTollsMutation, useWeightBridgesMutation } from "../../store/slice/NeweFeatureSlice/FeaturesSlice";
import ClearnAirZones from "./ClearnAirZones";
import Bridges from "./Bridges";
import Tolls from "./Tolls";
import { Birmingham_Fence, CAZBoundaries} from "../../constants/CazUK";
import AlertsListOnMap from "../../Utils/AlertsListOnMap";
function MainMap() {
  const mainMapRef = useRef();
  const theme = useTheme();
  const [defaultCenter, setDefaultCenter] = useState({ lat: 52.92214338014607, lng: -1.4703258589163344 });
  const [mapzoom, setMapzoom] = useState(6);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [libraries] = useState(["drawing", "places", "geometry"]);
  const [categoryVehicleId, setCategoryVehicleId] = useState();
  const [categoryOrgId, setCategoryOrgId] = useState();  
  const [globalMap,setGlobalMap]= useState(1);
  const isSearchKeyword = useSelector(
    (state) => state.controls.treeview.searchCategoryKeyWord
  );
  const { isLoaded, loadError} = useJsApiLoader({
    id: "google-map-script",
    //googleMapsApiKey: "AIzaSyBx1k2yHWgbv2RS0NUVcYrqhcHD8sWr9eA",
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries,
    nonce: "map",
  });
  const [map, setMap] = React.useState(null);
  //map options
  const options = {
    zoom: mapzoom,
    center: defaultCenter,
    minZoom: 2,
    maxZoom: 22,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    disableDefaultUI: true,
    featureType: "poi.business",
    stylers: [{ visibility: "off" }],
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
    featureType: "poi",
    stylers: [{ visibility: "off" }],
    tilt: 90,
    heading: 320,
    disableDoubleClickZoom: false,
    tiltInteractionEnabled:true,
  };


  //map ref
  const mapRef = useRef(null);

  //map style
  const mapContainerStyle = {
    height: "100%",
    position: "relative",
  };


  //mode
  const gmode = useSelector((state) => state.controls.customizer.mapMode);
  const tripList = useSelector((state) => state.controls.tripsList);

  //Map Onload
  const onLoad = useCallback(
    function callback(map) {
      // const bounds = new window.google.maps.LatLngBounds(defaultCenter);
      // map.fitBounds(bounds);
      setMap(map);
      setMapzoom(6);
      mapRef.current = map;
      // setDefaultCenter(defaultCenter);
      //addBoundires(map);
      //setZoomLevel(map.getZoom());
    },
    [gmode]
  );


  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  },[]);

  //get Login Username from Local Storage
  const UserData = JSON.parse(localStorage.getItem("userData"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData?.resellerId;
  let orgId = userData?.orgId;
  let userId = userData?.id;
  let orgName = userData?.orgName;
  let userName = userData?.userName;
  let userOrgType = userData?.userOrgType;
  let mapTypeData = userData?.mapType;

  useEffect(()=>{
    setGlobalMap(mapTypeData)
  },[mapTypeData])

  //Controls Slice
  const vehicleData = useSelector((state) => state.controls.dropVehicles);
  const vehicleCount = useSelector((state) => state.controls.vehicleCount);
  const isLoading = useSelector((state) => state.controls.isloading);
  const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);

  let start = 0;
  let length = 100;
  const [snapData, setSnapData] = useState([]);
  const baseArry = [];
  //Draw Polyline
  const [polyline, setPolyline] = useState([]);
  const [snapToRoad, setSnapToRoad] = useState(null);
  const [snapedTrail, setSnapedTrail] = useState([]);
  let array = [];
  let strArry = [];
  const ploylinePath = () => {
    setPolyline([]);
    setSnapedTrail([]);
    setFtr(false);
    setSnapData([]);
    setSnapedPoints([]);
    if (vehicleTrails.vehicleTrails !== null) {
      for (let i = 0; vehicleTrails.vehicleTrails?.length > i; i++) {
        let opj = { lat: "", lng: "" };
        opj.lat = vehicleTrails.vehicleTrails[i]?.lat;
        opj.lng = vehicleTrails.vehicleTrails[i]?.lng;
        array.push(opj); //data[i].lat
        let lat = `${vehicleTrails.vehicleTrails[i]?.lat}`;
        let lng = `${vehicleTrails.vehicleTrails[i]?.lng}`;
        let pathStr = lat.concat(",",lng);
        strArry.push(pathStr);
      }
      setPolyline(array);
      setSnapData(strArry)
      //getSanptoRoadData(strArry);
    }
  };

  
  const getSanptoRoadData = () => {
    let start = 1;
    let size = 100;

      for(start; (polyline.length / 100) + 1  >= start; start++){
        let data = snapData.slice((start - 1) * 100 , size);
        snapToRoadService(data);
        baseArry.push(data);
        size = size + 100;
    }
  }

  //const [ getSnap, {data:snapData, isLoading:snapLoading, isSuccess:snapp} ] = useLazySnapToRoadApiQuery();
  const [snapedPoints, setSnapedPoints] = useState([])
  const snapToRoadService = async (data) => {
      var path = data.join('|');
      try{
        const getSnapRes = await axios.get(`https://roads.googleapis.com/v1/snapToRoads?interpolate=true&path=${path}&key=AIzaSyCviATpjAjgEFZGeyhV9jZ8FiFdRQ24cO4`);
        collectSnapToRodaTrail(getSnapRes?.data?.snappedPoints);
      }catch(error){
        console.warn(error)
      }
  } 


  
  const collectSnapToRodaTrail = (data) => {
      if(data !== undefined && data.length > 0 && data !== null){
          let snapTrailArry = [];
          data.forEach((item, index) => {
              let latlng = {};
              latlng.lat = item.location.latitude;
              latlng.lng = item.location.longitude;
              snapTrailArry.push(latlng);
          });
          setFtr(true);
          setSnapedTrail(oldArray => [...oldArray, ...snapTrailArry]);
      }
  }
 

   //Handle Map Zoom
   const handleMapZoom = () => {
    setMapzoom(map?.zoom);
    if(RouteGeoZones !== null){
      setMapzoom(map?.zoom);
    }
  }

  useEffect(() => {
    if(mapTypeOnClick.current !== undefined && mapTypeOnClick.current === false && mapzoom <= 19){
      setMapType('roadmap');
    }else if(mapzoom > 19 && mapTypeOnClick.current !== undefined && mapTypeOnClick.current === false){
      setMapType('satellite');
    }
    // if(mapzoom > 19 && vehicleTrails !== null && snapedTrail.length <= 0 && snapedTrail !== null && snapedTrail !== undefined){
    //   //snapToRoadService();
    //   //getSanptoRoadData();
    // }
  },[mapzoom])

  const handleZoomLevel = (event, newZoom) => {
    if (newZoom !== null) {
      setMapzoom(newZoom);
      //addBoundires(map);
    }
  };

  useEffect(() => {
    if(map !== null && map !== undefined){
    setTimeout(() => {
      reCenter()
    },1000)}
  },[map])



  const reCenter = () => {
    map?.setZoom(6);
    map?.panTo(defaultCenter);
    // if(!!navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //        setDefaultCenter({lat:parseFloat(position?.coords?.latitude), lng:parseFloat(position?.coords?.longitude)})
    //         map?.panTo({lat:parseFloat(position?.coords?.latitude), lng:parseFloat(position?.coords?.longitude)});
    //         map?.setZoom(5);
    //     })
    // }
}

  useEffect(() => {
    setBridge(false);
    setWeightBridge(false);
    setToll(false)
    setCaz(false)
    if (vehicleTrails !== null && vehicleTrails.vehicleTrails?.length > 0) {
      var bounds = new window.google.maps.LatLngBounds();
      for(var i = 0; i < vehicleData?.vehicles.length; i++){
        if(vehicleTrails?.vehicleTrails[i]?.lat !== undefined && vehicleTrails?.vehicleTrails[i]?.lng !== undefined){
            let latlng = {lat:vehicleTrails.vehicleTrails[i].lat, lng:vehicleTrails?.vehicleTrails[i].lng}
            bounds.extend(latlng);
        }
      }
      map?.fitBounds(bounds);
      setMapzoom(12);
      let lat = vehicleTrails?.vehicleTrails.slice(-1)[0].lat;
      let lng = vehicleTrails?.vehicleTrails.slice(-1)[0].lng;
      map?.panTo({lat,lng});
      //setDefaultCenter({ lat, lng });
      ploylinePath();
      const filterMoving = vehicleTrails?.vehicleTrails.filter(
        (item) => item.vehicleStatus === "Moving"
      );
      setFirstMoving(filterMoving[0]);
      setlastMoving(filterMoving.slice(-1));
      
      //airQuality(filterMoving.slice(-1)[0].lat, filterMoving.slice(-1)[0].lng);
    } else {
      setMapzoom(6);
      setDefaultCenter(defaultCenter);
      setSnapedTrail([]);
      setFtr(false);
    }
  }, [vehicleTrails]);

  //Hover Vehicle
  // const hoverVehicleDetails = useSelector((state) => state.controls.hoverVehicle);
  // useEffect(() =>{
  //     if(vehicleTrails === null){
  //         const dealyDebounce = setTimeout(() => {
  //             if(hoverVehicleDetails !== null){
  //                 setMapzoom(5);
  //             }
  //         },200)
  //         return () => clearTimeout(dealyDebounce);
  //     }
  // },[hoverVehicleDetails])



  //Get First Moving Last Moving
  
  const [firstMoving, setFirstMoving] = useState(null);
  const [lastMoving, setlastMoving] = useState(null);
  useEffect(() => {
    if (vehicleTrails !== null) {
      const filterMoving = vehicleTrails?.vehicleTrails.filter(
        (item) => item.vehicleStatus === "Moving"
      );
      setFirstMoving(filterMoving[0]);
      setlastMoving(filterMoving.slice(-1));
      //getBridge(vehicleTrails?.vehicleTrails?.slice(-1)[0]?.lat, vehicleTrails?.vehicleTrails.slice(-1)[0]?.lng);
      //getWeightBridge(vehicleTrails?.vehicleTrails?.slice(-1)[0]?.lat, vehicleTrails?.vehicleTrails.slice(-1)[0]?.lng);
      //airQuality(filterMoving.slice(-1)[0].lat, filterMoving.slice(-1)[0].lng);
    }
  }, [vehicleTrails]);

  const dispatch = useDispatch();
  //Map Controls
  const [openMapEl, setOpenMapEl] = useState(null);
  const [mapType, setMapType] = useState("roadmap");

  const openMap = Boolean(openMapEl);
  const handleMapLayer = (event) => {
    setOpenMapEl(event.currentTarget);
    setMapzoom(map.zoom);
  };
  const handleMapLayerClose = () => {
    setOpenMapEl(null);
    setMapzoom(map.zoom);
  };
  
  const mapTypeOnClick = useRef(false);
  const handleMapType = (mapTypename) => {
    setMapType(mapTypename);
    mapTypeOnClick.current = true;
    auditPayload.message = "Tracking > Map > "+mapTypename;
    auditPayload.action = "View";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
   // auditLog(auditPayload);
    handleMapLayerClose();
  };

  const isLoadingStateSelect = useSelector((state) => state.controls.isloading);
  const [
    getVehicleCount,
    {
      data: vehicleCountData,
      isLoading: countLoading,
      isSuccess: countSuccess,
    },
  ] = useGetVehicleCountMutation();

  const [
    getVehicleByReseller,
    {
      data: getVehicleData,
      isLoading: getVehicleLoading,
      isSuccess: getVehicleSuccess,
    },
  ] = useGetVehicleByResellerMutation();

 const [
    getVehicleCategoryByReseller,
    {
      data: getVehicleCategoryData,
      isLoading: getVehicleCategoryLoading,
      isSuccess: getVehicleCategorySuccess,
    },
  ] = useGetVehicleCategoryByResellerMutation();

  //Single Vehicle
  const [
    getVehicleDetails,
    {
      data: getDetails,
      isLoading: getDetailsLoading,
      isSuccess: getDetailsSuccess,
    },
  ] = useGetVehicleDetailsMutation();


  const [cleanAirZone, setCleeanAirZone] = useState([]);
  const [bridgeData, setBridgeData] = useState([]);
  const [weightBridgeData , setWeightBridgeData] = useState([]);
  const [tollData , setTollData] = useState([]);
  const [ getCleanZone ] = useCleanAirZonesMutation();
  const [ getBridges] = useLowBridgesMutation();
  const [ getWeightBridges ] = useWeightBridgesMutation();
  const [ getTolls ] = useTollsMutation();

  const getZones = async () => {
    let payload = {
      "latLng": "53.167871,-2.982745",
      "radius":15
    }
    const res = await getCleanZone(payload);
    if(res?.data?.data !== undefined && res?.data?.data !== null && res?.data?.data?.length > 0){
      setCleeanAirZone(res?.data?.data)
    }else{
      setCleeanAirZone([])
    }
}

const getBridge = async (lat, lng) => {
  let payload = {
    "radius":50
  }
  
  payload.latLng = `${lat},${lng}`;
  const res = await getBridges(payload);
  if(res?.data?.data !== undefined && res?.data?.data !== null && res?.data?.data?.length > 0){
    setBridgeData(res?.data?.data)
  }else{
    setBridgeData([])
  }
}

const getWeightBridge = async (lat,lng) => {
  let payload = {
    "radius":50
  }

  payload.latLng = `${lat},${lng}`;
  const res = await getWeightBridges(payload);
  if (res?.data?.data !== undefined && res?.data?.data !== null && res?.data?.data?.length >0) {
    setWeightBridgeData(res?.data?.data)
  }else {
    setWeightBridgeData([])
  }
}

const getToll = async (lat , lng) => {
  let payload = {
    "radius":100
  }
  
  payload.latLng = `${lat},${lng}`;
  const res = await getTolls(payload);

  if (res?.data?.data !== undefined && res?.data?.data !== null && res?.data?.data?.length >0) {
    setTollData(res?.data?.data)
  }else {
    setTollData([]);
  }
}



  const selectVehicleData = useSelector(
    (state) => state.controls.singleVehicleData
  );
  const autoBtn = useSelector((state) => state.controls.refreshBtn.mapRef);

  const [auditLog] = useAuditLogMutation();


  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };
  const [ getVehicleImg, {data:vehicleImg,isSuccess:imgDataSuccess}] = useVehicleimageMutation();

  useEffect(() => {
    const vehicleImage = {
      resellerId: resellerId,
    };
    getVehicleImg(vehicleImage);
  }, [])

  const [imgData, setimgData] = useState([])
  useEffect(() => {

    if (imgDataSuccess) {
      setimgData([])
      if (vehicleImg.data !== undefined) {
        setimgData(vehicleImg?.data)
      }

    }
  }, [imgDataSuccess])


    //Auto Refresh
    const [timer, setTimer] = useState(false);
    const [autoRefresh, setAutoReferesh] = useState(false);
    const [geoZone, setGeoZone] = useState(false);
    const [alerts, setAlerts] = useState(false);
    const [route, setRoute] = useState(false);
    const [manual, setManual] = useState(false);
    const [geoZoneList, setGeoZoneList] = useState(null);
    const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState(false);
    let min = 2;
    let sec = 59;
    const [minutes, setMinutes] = useState(min);
    const [seconds, setSeconds] = useState(sec);

    

  //Page Referesh
  useEffect(() =>{
    mapRefetch()
    auditPayload.message = "Tracking > Map";
    auditPayload.action = "View";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  },[])

  const mapRefetch = () => {
      //setMapzoom(map?.zoom)
    if (activeFeature === 0) {
      setMinutes(min);
      setSeconds(sec);
      setManual(false);
      
    } else {
      setManual(true);
    }
    //airQuality();
    if (userOrgType === 2) {
      // Reseller
      let commonCountPayload = {
        resellerId: UserData?.resellerId,
        selectId: "",
        selectType: "",
      };
      getVehicleCount(commonCountPayload);
      let commonPayload = {
        resellerId: UserData?.resellerId,
        regionId: 0,
        dealerId: 0,
        customerId: 0,
        orgId: UserData?.resellerId,
        checkboxStatus: false,
        page: 0,
        size: 1000,
        region: 0,
        vehModel: "All",
        searchText: "",
        zoneId: 0,
      };
      getVehicleByReseller(commonPayload);

    } else if (userOrgType === 5) {
      //Dealer Admin
      let commonCountPayload = {
        resellerId: UserData?.resellerId,
        selectId: UserData?.orgId,
        selectType: "dealer",
      };
      getVehicleCount(commonCountPayload);
      let commonPayload = {
        resellerId: UserData?.resellerId,
        dealerId: UserData?.orgId,
        customerId: 0,
        orgId: UserData?.resellerId,
        checkboxStatus: false,
        page: 0,
        size: 1000,
        region: 0,
        vehModel: "All",
        searchText: "",
        zoneId: 0,
      };
      getVehicleByReseller(commonPayload);
    } else if (userOrgType === 3) {
      let commonCountPayload = {
        resellerId: UserData?.resellerId,
        selectId: UserData?.orgId,
        selectType: "customer",
      };
      getVehicleCount(commonCountPayload);
      let commonPayload = {
        resellerId: UserData?.resellerId,
        dealerId: 0,
        customerId: UserData?.orgId,
        orgId: UserData?.resellerId,
        checkboxStatus: false,
        page: 0,
        size: 1000,
        region: 0,
        vehModel: "All",
        searchText: "",
        zoneId: 0,
      };
      getVehicleByReseller(commonPayload);
    }else if (userOrgType === 1) {
      let commonCountPayload = {
        resellerId: UserData?.resellerId,
        selectId: UserData?.orgId,
        selectType: "fleet",
      };
      getVehicleCount(commonCountPayload);
      let commonPayload = {
        resellerId: UserData?.resellerId,
        dealerId: 0,
        customerId: UserData?.orgId,
        orgId: UserData?.resellerId,
        checkboxStatus: false,
        page: 0,
        size: 1000,
        region: 0,
        vehModel: "All",
        searchText: "",
        zoneId: 0,
      };
      getVehicleByReseller(commonPayload);
    }

    auditPayload.message = "Tracking > Map > Refresh";
    auditPayload.action = "View";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Single Vehicle Refresh

  const refetch = () => {
    if (activeFeature === 0) {       //Changed autoRefresh state to activeFeature === 0 for single vehicle
      setMinutes(min);
      setSeconds(sec);
      setManual(false);
    } else {
      setManual(true);
    }
    dispatch(isLoadingState(true));
    getVehicleDetails(selectVehicleData);
    auditPayload.message = "Tracking > Map > Refresh > Single Vehicle";
    auditPayload.action = "Refresh";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);

  };

  useEffect(() => {
    if (getDetailsSuccess) {
      //dispatch(selectedVehicleTrailsData(getDetails));
      dispatch(isLoadingState(false));
    }
  }, [getDetailsSuccess]);

  useEffect(() => {
    if (getVehicleCategorySuccess) {
      if(getVehicleCategoryData?.result?.length>0){
        dispatch(selectedVehicleCategoryListData(getVehicleCategoryData));
        //setShowCategory(true);
      }else{
        dispatch(selectedVehicleCategoryListData(null));
        //setShowCategory(false);
      }
      
      dispatch(isLoadingState(false));
    }
  }, [getVehicleCategorySuccess]);
  



// map move based on vehicle
const mapMove = () => {
  if(vehicleData !== null && vehicleData !== undefined && map !== null && map !== undefined){
    let location = {
      lat:parseFloat(vehicleData?.vehicles[0].gpsLocation?.split(',')[0]),
      lng:parseFloat(vehicleData?.vehicles[0].gpsLocation?.split(',')[1])
    } 
    var bounds = new window.google.maps.LatLngBounds();
    for(var i = 0; i < vehicleData?.vehicles.length; i++){
      let latlng = {lat:vehicleData?.vehicles[i].lat, lng:vehicleData?.vehicles[i].lng}
      bounds.extend(latlng);
    }
    if(vehicleData?.vehicles?.length > 10){
      map?.fitBounds(bounds);
    }else{
      map?.setZoom(8)
    }
    map?.panTo(location);
  }else{
    map?.setZoom(8)
  }
}

useEffect(() => {
  mapMove();
},[vehicleData])


  //Set AutoRefresh
  const handleAutoRef = useCallback(() => {
    //setGeoZone(false);
    setMinutes(min);
    setSeconds(sec);
    activeFeature(!autoRefresh);
    dispatch(refresh([true, false]));
  }, [autoRefresh]);

  useEffect(() => {
    if (!manual && activeFeature === 0) {
      setAutoReferesh(true);
    } else {
      setAutoReferesh(false);
      if(activeFeature !== 2){
        setActiveFeature(null);
      }
    }
    setGeoZoneList(null);
    setRouteData(null);
  }, [selectVehicleData]);

  useEffect(() => {
    setAutoReferesh(false);
    if(activeFeature !== 2){
      setActiveFeature(null);
    }
  }, [vehicleCount]);

  useEffect(() => {
    if (manual && activeFeature === 0) {
      setAutoReferesh(true);
    } else {
      setAutoReferesh(false);
    }
  }, [manual]);







  //Checkbox Point
  const [activeFeature, setActiveFeature] = useState(null);
  const [showTrails, setShowtrails] = useState(true);
  const [showPoly, setShowPoly] = useState(true);
  const checkBoxPoint = (state, value) => {
    if(value === 0){ // Auto Ref
      if(state){
        setActiveFeature(0);
        dispatch(routeTrips(null));
      }else{
        setActiveFeature(null);
      }
    }else if(value === 1){ //GeoZone
      if(state){
        setActiveFeature(1);
        // setMapzoom(8);
        dispatch(routeTrips(null));
      }else{
        setActiveFeature(null);
      }
    }else if(value === 2){ //Route
      if(state){
        setActiveFeature(2);
      }else{
        setActiveFeature(null);
        dispatch(routeTrips(null));
      }
    } else if(value === 3){ //Alerts
      if(state){
        setActiveFeature(3);
        // setMapzoom(8);
        dispatch(routeTrips(null));
      }else{
        setActiveFeature(null);
      }
    }
  } 


  const handleTrails = () => {
    setShowtrails(!showTrails);
    // if(!showTrails){
    //   setMapzoom(map.zoom);
    // }else{
    //   setMapzoom(map.zoom);
    // }
  }
  const handlePoly = () => {
    setShowPoly(!showPoly);
  }

  const handleFeatureChange = (state, value) => {
    checkBoxPoint(state, value);
    
  }




  //Auto Refresh
  useEffect(() => {
    if (activeFeature === 0) {
      let myInterval = setInterval(() => {
        
        if(seconds > 0){
          setSeconds(preState => preState - 1);
        }else{
            if(minutes === 0){
              //clearInterval(myInterval);
              setMinutes(min);
              setSeconds(sec);
              if(selectedVehicle === null){
                  mapRefetch();
              }else{
                  refetch()
              }
            }else{
              setMinutes(minutes - 1);
              setSeconds(sec);
            }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    } else {
      setMinutes(min);
      setSeconds(sec);
    }
  }, [minutes, seconds, activeFeature, manual]);


  


    //Route Marker Data
  const RouteGeoZones = useSelector((state) => state.controls.routeTrip);
  const selectedVehicle = useSelector((state) => state.controls.selectedVehicle);

  const [routeData, setRouteData] = useState(null);
  const [waypointList, setWaypointList] = useState([]);
  //For Category window
  const [showCategory, setShowCategory] = useState(false);
  const [showCatgryVehicleStatus, setShowCatgryVehicleStatus] = useState("load");
  const [categoryVehicleSummary, setCategoryVehicleSummary] = useState(false);
  
  const date = useSelector((state) => state.controls.treeview.date);
  const trailsTime = useSelector((state) => state.controls.treeview.trailsTime);
  const customDateDiff = useSelector((state) => state.controls.treeview.customDateDiff);


  var waypoints = [];
  //Show Route
  const directionService = async () => {
    let start = RouteGeoZones[0].cordinates.split(',');
    let end = RouteGeoZones.slice(-1)[0].cordinates.split(',');
    const getDirections = new window.google.maps.DirectionsService();
    const result = await getDirections.route({
      origin: {'lat': parseFloat(start[0]), 'lng':parseFloat(start[1])},
      destination: {'lat': parseFloat(end[0]), 'lng':parseFloat(end[1])},
      travelMode: window.google.maps.TravelMode.DRIVING,
      waypoints: waypointList,
    });
    map.setZoom(map?.zoom)
    setMapzoom(map?.zoom);
    setRouteData(result);
  };
  

 
       

  const waypointLists = () => {
    setWaypointList([]);
    if(RouteGeoZones !== null){
      setWaypointList([]);
      RouteGeoZones.forEach((key, index) => {
      let waypointsObj = {
        location: {},
      };
      waypointsObj.location.id = key.name;
      waypointsObj.location.lat = parseFloat(key.latitude);
      waypointsObj.location.lng = parseFloat(key.longitude);
      waypoints.push(waypointsObj);
      })
      setWaypointList(waypoints);
    }
  }

  useEffect(() => {
    if(waypointList.length > 0){
      directionService();
    }
  },[waypointList])

  useEffect(() => {
      if(RouteGeoZones !== null){
        waypointLists(); 
      }
  },[RouteGeoZones])

  const selectTypeId = useSelector((state) => state.controls.selectedType.id);
  const selectType = useSelector((state) => state.controls.selectedType.type);
  
  const [catSearch, setCatSearch] = useState(null);
  const getCategorySearch = (searchKey) => {
    setCatSearch(searchKey);
  }

  let cateGoryPayload = {
    orgType:UserData?.userOrgType,
    //regionId: 0,
    //dealerId: 0,
    //customerId: 0,
    orgId:UserData?.resellerId,
    //resellerId:UserData.orgId,
    //[type]:selectTypeId,
    //checkboxStatus: false,
    start:0,
    length:1000,
    //page: 0,
    //size: 1000,
    //region: 0,
    //vehModel: "All",
    search: catSearch
  }



  const [statusPayload, setStatusPayload] = useState(null);
  const closeTreeViewfn=(vehicleStatus)=>{
    if(UserData?.userOrgType === 3){
      cateGoryPayload.customerId = UserData?.orgId
    }else if(UserData?.userOrgType === 5){
      cateGoryPayload.dealerId = UserData?.orgId
    }else if(UserData?.userOrgType === 2){
        cateGoryPayload.resellerId = UserData?.orgId
    }else if(UserData?.userOrgType === 1){
      cateGoryPayload.fleetId = UserData?.orgId
    }

    var type = selectType;
    setShowCatgryVehicleStatus(vehicleStatus);
    cateGoryPayload.categoryType = vehicleStatus;
    setShowCategory(true);
    //getVehicleByReseller(commonPayload);
    getVehicleCategoryByReseller(cateGoryPayload);
    setStatusPayload(cateGoryPayload);
   }

   useEffect(() => {
    if(showCatgryVehicleStatus!== "load"){
    let commonPayload = {
      orgType:UserData?.userOrgType,
      categoryType:showCatgryVehicleStatus,
      orgId:UserData?.resellerId,
      start:0,
      length:1000,
    };

    if(UserData?.userOrgType === 3){
      commonPayload.customerId = UserData?.orgId
    }else if(UserData?.userOrgType === 5){
      commonPayload.dealerId = UserData?.orgId
    }else if(UserData?.userOrgType === 2){
      commonPayload.resellerId = UserData?.orgId
    }else if(UserData?.userOrgType === 1){
      commonPayload.fleetId = UserData?.orgId
    }

    if(catSearch !== null && catSearch?.length > 3){
      commonPayload.search = catSearch;
      getVehicleCategoryByReseller(commonPayload);
    }else if(catSearch !== null && catSearch?.length <= 0){
      commonPayload.search = "";
      getVehicleCategoryByReseller(commonPayload);
    }}
  }, [catSearch]);



   
   useEffect(() => {
    if (date !== null) {
      if(date[0] !== moment().format("YYYY-MM-DD 00:00:00") && date[1] !== moment().format("YYYY-MM-DD 23:59:59")){
        setShowTraffic(false);
      }else if(vehicleTrails !== null && date[0] === moment().format("YYYY-MM-DD 00:00:00") && date[1] === moment().format("YYYY-MM-DD 23:59:59")){
        setShowTraffic(false);
      }else{
        setShowTraffic(false);
      }
    }
  }, [date, vehicleTrails]);


  useEffect(() => {
        setShowCharging(false);
        setShowStop(false);
        dispatch(ChargingMarkers(null))
        dispatch(StopPageMarkers(null));
        
        //dispatch(searchKeyWord(null));
  },[date, selectedVehicle, showCategory])

  useEffect(() => {
    setRouteData(null);
    setWaypointList([]);
    dispatch(routeTrips(null));
  },[vehicleTrails])

  useEffect(() => {
    dispatch(vehicleSummaryWindow(false));
    dispatch(selectedVehicleTrailsData(null))
    dispatch(searchKeyWord(null));
  },[])


  const [type, setType] = useState('all');
  //handleMarkerShow
  const handleMarkerShow = (event, value) => {
    if(value !== null){
      setType(value);
      setMapzoom(map.zoom);
      const newPos = mapRef.current.getCenter().toJSON();
      setDefaultCenter(newPos);
      map?.panTo(newPos);
    }
    
  }

  const chagenedCenter = () => {
    if(!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    setDefaultCenter(newPos);
  }


  const chargingMarker = useSelector((state) => state.controls.chargingMarkers);
  const stopMarker = useSelector((state) => state.controls.stopPageMarkers);

  const [showCharging, setShowCharging] = useState(false);
  const [showStop, setShowStop] = useState(false);


  const handleExtramarkers = (type, value) => {
      if(type === 'all'){
        setShowtrails(!value);
        setMapzoom(map.zoom);
        setDefaultCenter(mapRef.current.getCenter().toJSON());
      }else if(type === 'charging'){
        setShowCharging(!value);
        setMapzoom(map.zoom);
      }else if(type === 'stoppage'){
        setShowStop(!value);
        setMapzoom(map.zoom);
      }
  }

  /// get air quality fbc7ec16b091892092c5fe058c23c1b0

  const [airQualityData, setAirQualityData] = useState([]);
  const [airData, setAirData] = useState([])
  const airQuality = async (lat, lon) => {
      const getData = await axios.get(`http://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=fbc7ec16b091892092c5fe058c23c1b0`);
      let location = {}
      location.location = new window.google.maps.LatLng(lat, lon);
      location.weight = getData?.data.list[0].main.aqi;
      setAirData(getData?.data.list[0])
      setAirQualityData([location]);
  }

  // useEffect(() => {
  //   getLng();
  // },[])

  // const getLng = () => {
  //   let locationArry = [];
  //   Birmingham_Fence.forEach((item) => {
  //       let path = {
  //         lat:item[1],
  //         lng:item[0]
  //       }
  //       locationArry.push(path);
  //     })
  // }




  const [showTraffic, setShowTraffic] = useState(false);
  const [caz, setCaz] = useState(false);
  const [bridge, setBridge] = useState(false);
  const [weightBridge , setWeightBridge] = useState(false);
  const [toll , setToll] = useState(false);
  const [ftr, setFtr] = useState(false)
  const handleTrafficeLayer = (event, newValue) => {
    setShowTraffic(newValue)
  }

  const handleCaz = (event) => {
    setCaz(event.target.checked);
    // if(event.target.checked){
    //   setMapzoom(8)
    // }
    getZones()
    if(event.target.checked){
      //addBoundires(map,cleanAirZone)
      // cleanAirZone.length > 0 && .map((item, index) => {
      //   console.log(item.placeId);
      // })
    }else{
      let featureLayer;
      featureLayer = map.getFeatureLayer("LOCALITY");
      featureLayer.style = null
    }
    
  }

  const handleBridge = (event, newValue) => {
    setBridge(newValue);
    if(vehicleTrails !== null && vehicleTrails !== undefined && event.target.checked){
      getBridge(vehicleTrails?.vehicleTrails?.slice(-1)[0]?.lat, vehicleTrails?.vehicleTrails.slice(-1)[0]?.lng);
    }
    // if(newValue){
    //   setMapzoom(8)
    // }
    
    // if(newValue){
    //   getBridge(vehicleTrails?.vehicleTrails?.slice(-1)[0]?.lat, vehicleTrails?.vehicleTrails.slice(-1)[0]?.lng);
    // }
  }

  const handleWeightBridge = (event) => {
    setWeightBridge(event.target.checked);
    if(vehicleTrails !== null && vehicleTrails !== undefined && event.target.checked){
      getWeightBridge(vehicleTrails?.vehicleTrails?.slice(-1)[0]?.lat, vehicleTrails?.vehicleTrails.slice(-1)[0]?.lng);
    }
    // if(event.target.checked){
    //   setMapzoom(8)
    // }
  }

  const handleToll = (event) => {
    setToll(event.target.checked);
    if(vehicleTrails !== null && vehicleTrails !== undefined && event.target.checked){
      getToll(vehicleTrails?.vehicleTrails?.slice(-1)[0]?.lat, vehicleTrails?.vehicleTrails.slice(-1)[0]?.lng);
    }
    // if(event.target.checked){
    //   setMapzoom(8)
    // }
  }



  const handleFitRoad = (event, newValue) => {
    setFtr(newValue);
  } 


  const states = {
    "ChIJdd4hrwug2EcRmSrV3Vo6llI": "ChIJdd4hrwug2EcRmSrV3Vo6llI", // Alabama
    "ChIJc3FBGy2UcEgRmHnurvD-gco": "ChIJc3FBGy2UcEgRmHnurvD-gco", // Alaska
    "ChIJYdizgWaDcUgRH9eaSy6y5I4": "ChIJYdizgWaDcUgRH9eaSy6y5I4", // Arizona
    "ChIJrx_ErYAzcUgRAnRUy6jbIMg": "ChIJrx_ErYAzcUgRAnRUy6jbIMg", // Arkansas
    "ChIJLeE-dKZ4cUgRCZpt1tAnixM": "ChIJLeE-dKZ4cUgRCZpt1tAnixM", // California
    "ChIJGRnC6XpfeUgRTxknMtjt4I8": "ChIJGRnC6XpfeUgRTxknMtjt4I8", // Colorado
    "ChIJ6fEUGKRCdEgReTs3A-qDtkU": "ChIJ6fEUGKRCdEgReTs3A-qDtkU", // Connecticut
    "ChIJAZuMSvgOYUgRjAPlGBbSkvE": 'ChIJAZuMSvgOYUgRjAPlGBbSkvE', // Delaware
  };

  const addBoundires = (map, placeId) => {
    let featureLayer;
    featureLayer = map.getFeatureLayer(window.google.maps.FeatureType.LOCALITY);
    const featureStyleOptions = {
      strokeColor: "#810FCB",
      strokeOpacity: 1.0,
      strokeWeight: 3.0,
      fillColor: "#810FCB",
      fillOpacity: 0.5,
    };
      featureLayer.style = (options) => {

        const placeFeature = options.feature;
        if(states[placeFeature.placeId] !== undefined){
          if(placeFeature.placeId === states[placeFeature.placeId]){
            return featureStyleOptions;
          }
        }
        
        
    };   
}

const circleOptions = {
  fillColor:theme.palette.secondary.main,
  fillOpacity:.5,
  strokeWeight:1.2,
  strokeColor:theme.palette.secondary.main,
}

  //Map Section
  const mapMemo = useMemo(() => {
      return isLoaded ? (
        <GoogleMap
        options={{
          mapTypeId: mapType,
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: false,
          mapTypeControl: false,
          disableDefaultUI: true,
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
          featureType: "poi",
          stylers: [{ visibility: "off" }],
          zoom: mapzoom,
          center: defaultCenter,
          minZoom: 2,
          maxZoom: 22,
          disableDoubleClickZoom: false,
         //mapId:'aeb8d02543982e62',
        }}
        //zoom={mapzoom}
        mapContainerStyle={mapContainerStyle}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onZoomChanged={handleMapZoom}
        onCenterChanged={chagenedCenter}
      >
        {/* Loading */}
        <Backdrop
          open={ vehicleCount === null || isLoadingStateSelect || getVehicleLoading}
          sx={{
            position: "absolute",
            background: alpha(theme.palette.background.paper, 0.6),
            zIndex: 999,
          }}
        >
          <GridLoader color={theme.palette.secondary.main} />
        </Backdrop>
  
        
        {/* <AdvancedMarker /> */}
        {(vehicleData !== null && categoryVehicleSummary!==true) && <MarkerRow data={vehicleData} text={"onLoad"}  imgData={imgData} />}
  
        {/* Start Marker */}
        {vehicleTrails !== null && vehicleTrails?.vehicleTrails?.length === 0 &&  (
          <OfflineMarker position={defaultCenter} />
        )}
       
        {/* End Marker */}
        {
          vehicleTrails !== null && vehicleTrails.vehicleTrails?.length > 0 && <EndMarker data={lastMoving !== null && lastMoving} />
        }
  
        {/* Polyline Marker */}
        {vehicleTrails !== null && vehicleTrails.vehicleTrails?.length > 0 && !ftr && showPoly && (
          <TrailsPolyline data={polyline} />
        )}

        {
          snapedTrail?.length > 0 && snapedTrail !== undefined && ftr &&
          <Polyline 
          path={snapedTrail}
          options={{
            strokeColor:'#006400',
            strokeWeight:2,
            geodesic: true,
            icons: [{
            icon: {path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW},
            offset: '50%',
            repeat: '100px'
            }]
          }}  
           />
        }

  
        {vehicleTrails !== null && vehicleTrails.vehicleTrails?.length > 0 && showTrails && (
          <TrailsMarkersDot
            data={vehicleTrails?.vehicleTrails}
            firstMoving={firstMoving !== null && firstMoving}
            lastMoving={lastMoving !== null && lastMoving}
            autoRefresh={autoRefresh}
            activeFeature={activeFeature}
          />
        )}

  
        {/* {geoZoneList !== null && activeFeature === 1 &&
          geoZoneList.map((item, index) => {
            return item.zoneType === "Circles" ? (
              <>
                <Marker
                  position={{
                    lat: parseFloat(item.latitude),
                    lng: parseFloat(item.longitude),
                  }}
                  onClick={() => getZoneDetails(index)}
                  label={{
                    text:'G',
                    color:'#fff',
                    fontWeight:'700',
                  }}
                />
  
                {openInfoWindowMarkerId === index && (
                  <InfoWindow
                    options={{
                      pixelOffset: new window.google.maps.Size(0, -20),
                    }}
                    position={{
                      lat: parseFloat(item.latitude),
                      lng: parseFloat(item.longitude),
                    }}
                    onCloseClick={() => setOpenInfoWindowMarkerId(false)}
                  >
                    <StyledInfoWindow sx={{ p: 2, background:'#fff'}}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography variant="caption" sx={{ width: "25%" }}>
                            Name
                          </Typography>
                          <Typography variant="subtitle2">
                            {item?.name}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography variant="caption" sx={{ width: "25%" }}>
                            Radius
                          </Typography>
                          <Typography variant="subtitle2">
                            {item?.radius} m
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption">Address</Typography>
                          <Typography variant="subtitle2">
                            {item.location === undefined ? "" : item.location}
                          </Typography>
                        </Box>
                      </Stack>
                    </StyledInfoWindow>
                  </InfoWindow>
                )}
  
                <Circle
                  center={{
                    lat: parseFloat(item.latitude),
                    lng: parseFloat(item.longitude),
                  }}date
                  radius={item.radius}
                  options={circleOption}
                />
              </>
            ) : (
              item.zoneType === "Polygons" && (
                <Polygon
                  path={JSON.parse(
                    item.cordinates !== undefined ? item.cordinates : ""
                  )}
                  options={{ strokeColor: "#FF0000" }}
                />
              )
            );
          })} */}
  
  
        <GeoMarker activeFeature={activeFeature} selectVehicleData={selectVehicleData} vehicleTrails={vehicleTrails} />
        {/* Vehicle Status bar */}

        <AlertMarker activeFeature={activeFeature} selectVehicleData={selectVehicleData} vehicleTrails={vehicleTrails} />

        {/* Route Direaction */}
        { routeData !== null && vehicleTrails !== null && RouteGeoZones !== null && <DirectionsRenderer directions={routeData}/> }
  
          {/* Routes Markers */}
          {
           vehicleTrails !== null && activeFeature === 2 && RouteGeoZones !== null && RouteGeoZones !== undefined && <RouteMarkers data={RouteGeoZones}/> 
          }
          
          {
            showTraffic && <TrafficLayer autoRefresh={true} />
          }
          {
            airQualityData.length > 0 && <HeatmapLayer data={airQualityData} options={{radius:50}}/>
          }
          
          {
            caz && <ClearnAirZones data={cleanAirZone}/>
          }
          {
            bridge && <Bridges data={bridgeData} center={vehicleTrails?.vehicleTrails?.slice(-1)[0]}/>
          }

          {
            weightBridge && <Bridges data = {weightBridgeData} center = {vehicleTrails?.vehicleTrails?.slice(-1)[0]} isWeightBridge = {true}/>
          }
          {
            
            toll && <Tolls data = {tollData} center ={vehicleTrails?.vehicleTrails?.slice(-1)[0]}/>
          }

      </GoogleMap>
      ) : ('Loading')
   
  }, [globalMap,isLoaded, getVehicleLoading, isLoadingStateSelect, vehicleCount, vehicleTrails, vehicleData, polyline,imgData, mapzoom, mapType, RouteGeoZones, activeFeature, openInfoWindowMarkerId, showTrails, routeData, type, chargingMarker, showStop, showCharging, showTraffic, airQualityData, caz, bridge, snapedTrail,weightBridge,toll, ftr,showPoly])

  
  const LeafletmapMemo = useMemo(() => {
      return (
        <>
        <Backdrop
          open={isLoadingStateSelect}
          sx={{
            position: "absolute",
            background: alpha(theme.palette.background.paper, 0.6),
            zIndex: 999,
          }}
        >
          <GridLoader color={theme.palette.secondary.main} />
        </Backdrop>
        {isLoaded ? (
          <LeafletMap data={vehicleData} vehicleTrails={vehicleTrails} mapzoom={mapzoom} mapType={mapType}    imgData={imgData}/>
        ) : (
          'Loading'
        )}
      </>
      );
   
  }, [globalMap,isLoaded, getVehicleLoading, isLoadingStateSelect, vehicleCount, vehicleTrails, vehicleData, polyline, mapzoom,imgData, mapType, RouteGeoZones, activeFeature, openInfoWindowMarkerId, showTrails, routeData, type, chargingMarker, showStop, showCharging, ftr,showPoly])
  
  let mapSetting;

  if (globalMap === 1) {
    mapSetting = mapMemo;
  } else if (globalMap === 2) {
    mapSetting = LeafletmapMemo;
  } else {
    mapSetting = mapMemo;
  }
  const handleZoomIn = () => {
    if (globalMap==1) {
      setMapzoom(map.zoom + 1)
    } else if(globalMap==2) {
      setMapzoom(prevZoom => Math.min(prevZoom + 1, 22)); // Limit zoom to 22
    }
    else{
      setMapzoom(map.zoom + 1)
    }
  };

  const handleZoomOut = () => {
    if (globalMap===1) {
      setMapzoom(map.zoom - 1)
    } else if(globalMap==2){
      setMapzoom(prevZoom => Math.max(prevZoom - 1, 3)); // Limit zoom to 3
    }
    else{
      setMapzoom(map.zoom - 1)
    }
  }; 
  const vehicleSummaryfn=(vehicleId,orgId)=>{
    //vehicleId
    setCategoryOrgId(orgId);
    let body = {
      roleId: 6,
      sessionOrgId: userData?.orgId,
      vehicleId: vehicleId,
      logoName: "string",
      strDate: date[0],
      endDate: date[1],
      statusFlag: 5,
      orgId: userData?.orgId,
      mins: "1",
      orgType: "2",
      agencyId: 0,
      enLatLng: 0,
      sessionOrgVehicleDisplay: 1,
      startOdometer: 0,
      endOdometer: 0,
      storepack: 0,
      userId: "2",
      viewTrails30sec: customDateDiff,
      cngVehicle: true,
      hoursFeed: trailsTime,
      view_trails30sec: 0,
    };
    dispatch(isLoadingState(true));
    dispatch(singleVehicle(body));
    //getVehicleDetails(body);
    setCategoryVehicleId(vehicleId);
   }

  const closeCategoryfn =()=>{
    setShowCategory(false);
    setCategoryVehicleSummary(false);
    //mapRefetch();
    dispatch(selectedVehicleTrailsData(null));
    setCategoryVehicleId();
    setCategoryOrgId();
    dispatch(selectedVehicleData(null));
  }

  const closeSubCategoryfn =()=>{
    setCategoryVehicleSummary(false);
    setAnchorEl(null)
   }
   const vehicleSummaryWindowfn=(event)=>{
    setCategoryVehicleSummary(true);
    setAnchorEl(event.currentTarget)
   }


   //vehicle Summary
   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl)
   let summaryWindow = useSelector((state) => state.controls.vehicleSummary)
   let treeRef = useRef(null);

  const CloseSummary = () => {
    setAnchorEl(null);
  }

  return (
    <Box sx={{height:'100%'}}>
      {/* Top bar controls */}

        <Treeview
        userData={UserData}
        autoRef={handleAutoRef}
        autoRefresh={autoRefresh}
        geoZone={geoZone}
        alerts={alerts}
        vehicleTrails={vehicleTrails}
        activeFeature={activeFeature}
        handleFeatureChange={handleFeatureChange}
        showTrails={showTrails}
        handleTrails={handleTrails}
        showPoly={showPoly}
        handlePoly={handlePoly}
        treeRef={treeRef}
        showCategory={showCategory}
      />
    

    {vehicleCount !== null && <CountBar data={vehicleCount} closeTreeViewfn={closeTreeViewfn} imgData={imgData} showCategory={showCategory} mapRefetch={mapRefetch} reCenter={reCenter} />}  
      <MapControls
        handleZoomLevel={handleZoomLevel}
        mapzoom={mapzoom}
        mapRefetch={mapRefetch}
        min={minutes}
        sec={seconds}
        autoRefresh={autoRefresh}
        refetchSingleVehicle={refetch}
        UserData={UserData}
        activeFeature={activeFeature}
        handleMarkerShow={handleMarkerShow}
        type={type}
        showCharging={showCharging}
        showStop={showStop}
        showTrails={showTrails}
        handleExtramarkers={handleExtramarkers}
        handleTrafficeLayer={handleTrafficeLayer}
        handleCaz={handleCaz}
        showTraffic={showTraffic}
        caz={caz}
        airData={airData}
        handleBridge={handleBridge}
        bridge={bridge}
        handleWeightBridge={handleWeightBridge}
        weightBridge={weightBridge}
        handleToll={handleToll}
        toll={toll}
        allowDays={31}
        handleFitRoad={handleFitRoad}
        ftr={ftr}
        snapedTrail={snapedTrail}
      />
       {/* Map Controls */}
       <ControlsBox
        className="zoom-control-wrapper"
        direction="row"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          className="map-btn"
          onClick={handleZoomIn}
          disabled={mapzoom === 22}
        >
          <RiZoomInLine />
        </Button>
        <Button
          className="map-btn"
         sx={{cursor: "default",}}
        >
          <Typography variant='body1'>{mapzoom}</Typography>
        </Button>
        <Button
          className="map-btn"
          onClick={handleZoomOut}
          disabled={mapzoom === 3}
        >
          <RiZoomOutLine />
        </Button>
        <Button
          className="map-btn"
          id="basicmaplayer-button"
          aria-controls={openMap ? "maplayer-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMap ? "true" : undefined}
          onClick={handleMapLayer}
        >
          <FiLayers />
        </Button>
        <Menu
          id="maplayer-menu"
          anchorEl={openMapEl}
          open={openMap}
          onClose={handleMapLayerClose}
          MenuListProps={{
            "aria-labelledby": "maplayer-button",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ListItemButton
            className="small-menuItem"
            onClick={() => handleMapType("roadmap")}
            selected={mapType === "roadmap"}
          >
            <ListItemAvatar>
              <Avatar
                src={DefaultMap}
                variant="rounded"
                sx={{ width: "25px", height: "25px" }}
              />
            </ListItemAvatar>
            <ListItemText>RoadMap</ListItemText>
          </ListItemButton>
          <ListItemButton
            className="small-menuItem"
            onClick={() => handleMapType("terrain")}
            selected={mapType === "terrain"}
          >
            <ListItemAvatar>
              <Avatar
                src={Terrain}
                variant="rounded"
                sx={{ width: "25px", height: "25px" }}
              />
            </ListItemAvatar>
            <ListItemText>Terrain</ListItemText>
          </ListItemButton>
          <ListItemButton
            className="small-menuItem"
            onClick={() => handleMapType("satellite")}
            selected={mapType === "satellite"}
          >
            <ListItemAvatar>
              <Avatar
                src={Satellite}
                variant="rounded"
                sx={{ width: "25px", height: "25px" }}
              />
            </ListItemAvatar>
          <ListItemText>Satellite</ListItemText>
          </ListItemButton>
        </Menu>
        {/* <AlertsListOnMap /> */}
      </ControlsBox>
      {/* Bottom Sheet */}
      {/* { resellerId !== 110224 && 
        <BottomBar data={polyline} autoRefresh={autoRefresh} />
      } */}
    {
      vehicleTrails !== null && activeFeature === 2 && <RoutesBar showTrails={showTrails}
      handleTrails={handleTrails} RouteGeoZones={RouteGeoZones}/>
    }

    {mapSetting}
    {showCategory && resellerId === 110224 &&
    <CategoryBar vehicleCount={vehicleCount}  getCategorySearch={getCategorySearch} showTrails={showTrails}  handleTrails={handleTrails} closeCategoryfn={closeCategoryfn} showCatgryVehicleStatus={showCatgryVehicleStatus} vehicleSummaryfn={vehicleSummaryfn} vehicleSummaryWindowfn={vehicleSummaryWindowfn} statusPayload={statusPayload}/>
    }
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{overflow:'hidden', '& .MuiPaper-root':{overflow:'hidden', '& .MuiList-root':{padding:'0px'}}}}
    >
      {/* <SubCategory open={open} anchorEl={anchorEl} showTrails={showTrails}  handleTrails={handleTrails} closeSubCategoryfn={closeSubCategoryfn} showCatgryVehicleStatus={showCatgryVehicleStatus} categoryOrgId={categoryOrgId} categoryVehicleId={categoryVehicleId}/> */}
      <VehicleMainSumary CloseSummary={CloseSummary} trailsData={handleTrails} open={open} categoryOrgId={categoryOrgId} categoryVehicleId={categoryVehicleId} isLoading={isLoadingStateSelect || getVehicleLoading}/>
    </Menu>
      {
        summaryWindow && vehicleTrails !== null && <SummaryWindow position={treeRef} trailsData={handleTrails} showCategory={showCategory}/>
      }

    {/* <SubCategory open={open} anchorEl={anchorEl} showTrails={showTrails}  handleTrails={handleTrails} closeSubCategoryfn={closeSubCategoryfn} showCatgryVehicleStatus={showCatgryVehicleStatus} categoryOrgId={categoryOrgId} categoryVehicleId={categoryVehicleId}/> */}

    </Box>
  )
}
export default MainMap;
