import {
  Box,
  Button,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import {
  HiMiniFolderMinus,
  HiMiniFolderPlus,
  HiRectangleGroup
} from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import {
  useAuditLogMutation,
  useGetRegionListMutation,
  useGetVehicleDetailsMutation
} from "../../../store/slice/ApiSlice";
import {
  isLoadingState,
  refresh,
  searchCategoryKeyWord,
  searchKeyWord,
  selectedVehicleData,
  selectedVehicleTrailsData,
  singleVehicle
} from "../../../store/slice/ControlsSlice";
import {
  TreeViewAccodionBox,
  TreeViewListItemBtn,
} from "../Treeview/TreeviewStyledEle";
import Vehicle from "./Vehicle";

function Fleet(props) {
  const {
    isLogin,
    whoIam,
    buttonIndex,
    isCutomer,
    fleetItem,
    index,
    openfleetId,
    regionName,
    dealerName,
    customerName,
    payload,
    fleetPayload
  } = props;

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const vehicleRef = fleetItem.vehicles.map(() => createRef());
  let feetRef = useRef(fleetItem);
  const selectedVehicle = useSelector(
    (state) => state.controls.selectedVehicle
  );
  const date = useSelector((state) => state.controls.treeview.date);
  const trailsTime = useSelector((state) => state.controls.treeview.trailsTime);
  const customDateDiff = useSelector((state) => state.controls.treeview.customDateDiff);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  let orgId = userData?.orgId;
  let userId = userData?.id;
  let orgName = userData?.orgName;
  let userName = userData?.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const [orgNameByFleet, setOrgNameByFleet] = useState(null);
  const showVehicleDetails = (data) => {
    //setOrgNameByFleet(data.current.getAttribute('data-orgName'));
    setOrgNameByFleet(data.current.getAttribute('data-fleetIndex'));
    //setSelectedVehicle(data.current.id);
    dispatch(selectedVehicleData(data.current.id));
    getSingleVehicleDetails(data.current.id);
  };

  //get single vehicle details
  const [
    getVehicleDetails,
    {
      data: getDetails,
      isLoading: getDetailsLoading,
      isSuccess: getVehicleSuccess,
    },
  ] = useGetVehicleDetailsMutation();


  const getSingleVehicleDetails = (id) => {
    let body = {
      roleId: 6,
      sessionOrgId: fleetItem?.orgId,
      vehicleId: id,
      logoName: "string",
      strDate: date[0],
      endDate: date[1],
      statusFlag: 5,
      orgId: fleetItem?.orgId,
      mins: "1",
      orgType: "2",
      agencyId: 0,
      enLatLng: 0,
      sessionOrgVehicleDisplay: 1,
      startOdometer: 0,
      endOdometer: 0,
      storepack: 0,
      userId: "2",
      viewTrails30sec: customDateDiff,
      cngVehicle: true,
      hoursFeed: trailsTime,
      view_trails30sec: 0,
    };
    dispatch(isLoadingState(true));
    dispatch(singleVehicle(body));
    getVehicleDetails(body);
  };

  useEffect(() => {
    dispatch(isLoadingState(false));
    if (date !== null && selectedVehicle !== null) {
      getSingleVehicleDetails(selectedVehicle);
    }
  }, [date, trailsTime]);

 //API Slice
const [getVehicles, { data:updateVehicleData, isSuccess }] = useGetRegionListMutation();

  const [vehicleList, setVehicleList] = useState(null);

  useEffect(() =>{
    if(selectedVehicle !== null && isSuccess && orgNameByFleet !== null){
        setVehicleList(updateVehicleData.treeviewList[0].orgs[orgNameByFleet]);
    }
  
    
  },[selectedVehicle, isSuccess, orgNameByFleet])

  const isSearchKeyword = useSelector(
    (state) => state.controls.treeview.searchKeyWord
  );
  const [vehicleListNew, setVehicleListNew] = useState([]);
  const isLoading = useSelector((state) => state.controls.isloading);

  const updateVehicleList = async (index) => {
    let arrayUP = [...vehicleListNew];
    payload.value = 'vehicles'
    
    if (selectedVehicle !== null && open && isSearchKeyword === null ) {
     if (fleetPayload === null || fleetPayload === undefined){
          const newVehicles = await getVehicles(payload);
          arrayUP[index] = newVehicles.data.treeviewList[0]?.orgs[0]?.vehicles[index];
          setVehicleListNew(arrayUP);
     }else{
          getVehicles(fleetPayload);
     }}

    if (selectedVehicle !== null && open && isSearchKeyword !== null ) {
      if (fleetPayload === null || fleetPayload === undefined){
            payload.search=isSearchKeyword;
            const newVehicles = await getVehicles(payload);
            arrayUP[index] = newVehicles.data.treeviewList[0]?.orgs[0]?.vehicles[index];
            setVehicleListNew(arrayUP);
    }else{
      fleetPayload.search=isSearchKeyword;
      getVehicles(fleetPayload);
    }

    setOpen(true) //test
    }   
  }
  // useEffect(() => {
  //   if (isSuccess) {
  //     // setFleetList(data);
  //     console.log("Fleet=>",data);
  //   }
  // }, [isSuccess]);

  useEffect(() => {
    if (getVehicleSuccess) {
      dispatch(selectedVehicleTrailsData(getDetails));
      dispatch(isLoadingState(false));
    }
  }, [getVehicleSuccess]);

  


  let vehicleArry  = []
  const [newPage, setNewPage] = useState(0);
  const [length, setLength] = useState(100);
  const [totalEle, setTolalEle] = useState(0);


  let getVehiclePayload = {
    resellerId: userData?.resellerId,
    regionId: 0,
    orgId: feetRef.current.id,
    start: newPage,
    length: length,
    vehmodel: "All",
    statusFlag: 6,
    value: "Vehicles",
    search: "",
  };
  
    //API Slice
    const [getNewVehicle, { data, isLoading:getNewehicleLoading, isSuccess:getNewehicleSuccess }] = useGetRegionListMutation();

    const getNewVehicleList = async (newPage, search) => {
        setNewPage(newPage);
        getVehiclePayload.start = newPage;
        getVehiclePayload.search = search;
        getVehiclePayload.orgId = payload !== undefined && payload?.orgId !== undefined ? payload?.orgId : orgId;
        let res = await getNewVehicle(getVehiclePayload);
        let newVehicleList = res.data;
        setTolalEle(newVehicleList?.totalElements !== undefined && newVehicleList?.totalElements !== null ? newVehicleList?.totalElements : 0);
        if(newVehicleList !== null && newVehicleList?.treeviewList?.length > 0 ){
          setVehicleListNew((prevState) => [...prevState, ...newVehicleList?.treeviewList[0]?.orgs[index]?.vehicles]);
          if(search !== null && search !== undefined && search !== ''){
            getSingleVehicleDetails(newVehicleList?.treeviewList[0]?.orgs[0]?.vehicles[0].vehicleId);
            dispatch(selectedVehicleData(newVehicleList?.treeviewList[0]?.orgs[0]?.vehicles[0].vehicleId));
          }
          //setVehicleListNew([, vehicleArry])
        }else{
          setVehicleListNew([])
        }
    }


    


  //collabse open
  const collabseOpen = () => {
  
    //setVehicleList(fleetItem)
  
    setOpen(!open); //rest
    if(!open){
      setVehicleListNew([]);
      setNewPage(0);
      getNewVehicleList(newPage, '');
    }
    if (selectedVehicle !== null) {
      dispatch(selectedVehicleData(null));
      dispatch(selectedVehicleTrailsData(null));
      dispatch(refresh([false, false]));
    }

    auditPayload.message = "Tracking > Map > Treeview > "+userData?.orgName+" > "+regionName+" > "+dealerName+" > "+customerName+" > "+fleetItem?.orgName;
    auditPayload.action = "Select";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
  //  auditLog(auditPayload);
  };


  // useEffect(() => {

  // },[getVehicleSuccess])

  useEffect(() => {
    const dealyDebounce = setTimeout(() => {
      if (
        fleetItem !== null &&
        isSearchKeyword !== null &&
        isSearchKeyword.length >= 3
      ) {
        setOpen(true);
        setVehicleListNew([]);
        setNewPage(0);
        getNewVehicleList(newPage, isSearchKeyword);
      } else {
        dispatch(selectedVehicleData(null));
        dispatch(selectedVehicleTrailsData(null));
        dispatch(refresh([false, false]));
      }
    }, 1000);
    return () => clearTimeout(dealyDebounce);
  }, [isSearchKeyword]);


  //Onload Expand
  useEffect(() => {
    if ( (isSearchKeyword === null || isSearchKeyword === "")) {
      setOpen(false);
      //
    }else{
      collabseOpen();
    }
  }, []);

  useEffect(() => {
    if (userData.userOrgType === 3 && (isSearchKeyword === null || isSearchKeyword === "")) {
      collabseOpen();
    }
  }, []);




  return (
    <TreeViewAccodionBox rolles={"fleet"}>
      <TreeViewListItemBtn
        primary={"fleet"}
        secondaryAction={
          <IconButton onClick={collabseOpen}>
            {open ? <HiMiniFolderMinus /> : <HiMiniFolderPlus />}
          </IconButton>
        }
        id={fleetItem.orgId}
        key={index}
        buttonIndex={isCutomer ? buttonIndex : buttonIndex + 35 }
        ref={feetRef}
      >
        <ListItemIcon>
          <HiRectangleGroup />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction={"row"} spacing={0.5}>
              <Typography>{fleetItem?.orgName}</Typography>
              <Typography>{`(${fleetItem?.vehicleCount})`}</Typography>
            </Stack>
          }
        />
      </TreeViewListItemBtn>
      <Collapse in={open}>
        {vehicleListNew.length > 0 && vehicleListNew?.map((vehicles, indexVehicle) => (
              <Vehicle
                key={indexVehicle}
                vehiclesItem={vehicles}
                buttonIndex={buttonIndex}
                index={indexVehicle}
                selectVehicle={showVehicleDetails}
                selctedVehile={selectedVehicle}
                regionName={regionName}
                dealerName={dealerName}
                customerName={customerName}
                fleetName={fleetItem.orgName}
                updateVehicleList={updateVehicleList}
              />
          ))}
          {
            totalEle !== 0 && totalEle !== length && totalEle > length &&
            <Box sx={{display:'flex', justifyContent:'center'}}>
              <Button variant="plain" disabled={totalEle === vehicleListNew?.length} onClick={() => getNewVehicleList(newPage + 1)}>Load More</Button>
            </Box>
          }
         
      </Collapse>
    </TreeViewAccodionBox>
  );
}

export default Fleet;
