import { Alert, alpha, Box, Button, Chip, CircularProgress, circularProgressClasses, Dialog, Divider, Grid, Icon, IconButton, Paper, Snackbar, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from "@mui/material"
import { useCallback, useMemo, useState } from "react";
import CountUp from "react-countup";
import { TbSteeringWheel } from "react-icons/tb";
import { MdAssistantNavigation, MdClose } from "react-icons/md";
import moment from "moment";
import { Empty } from "antd";
import noTripData from '../../assets/images/svgicons/notrip.svg'
export const SteeringRotation = (props) => {
    const  { rotation } = props;
    return (
        <Paper
            sx={{boxShadow:'0px 0px 0px rgba(0,0,0,0) !important',
                padding:'10px 15px', display:'flex', alignItems:'center', justifyContent:'center'}}
        >
            <Icon sx={{ display:'flex', transform:`rotate(${rotation}deg)`, transition:'.3s', fontSize:'52px'}}>
                <TbSteeringWheel />
            </Icon>
        </Paper>
    )
}

export const SpeedMeter = (props) => {
    const { speed } =  props;
    const counter = useMemo(() => {
        return(
            <CountUp 
            end={parseFloat(speed)}
            duration={1}
            redraw={false}
            >
            {({ countUpRef }) => (
            <Typography variant='h5' sx={{ fontWeight: 600, lineHeight:'.9'}} ref={countUpRef}/>
            )}
        </CountUp>
        )
    },[speed])
    return(
        <Box>
            <Box sx={{position:'relative', display:'inline-flex'}}>
                <CircularProgress 
                variant="determinate" 
                value={100} 
                size={80}
                thickness={2} 
                sx={(theme) => ({
                    transform:'rotate(90deg) !important',
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                    color: "#ddd",
                    ...theme.applyStyles('dark', {
                    color: '#ddd',
                    }),
                    }) }/>
                    <CircularProgress
                    variant="determinate"
                    disableShrink
                    sx={(theme) => ({
                        transform:'rotate(90deg) !important',
                    color: parseFloat(speed) > 30 ? '#f00' : '#1a90ff',
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                    },
                    ...theme.applyStyles('dark', {
                    color: parseFloat(speed) > 9 ? '#f00' : '#308fe8',
                    }),
                    })}
                    size={80} 
                    value={parseFloat(speed)}
                    thickness={2} 
                    />
                <Box
                    sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection:'column'
                    }}
                >
                <Typography
                    variant="body1"
                    sx={{fontSize:'10px', fontWeight:'400', mb:0}}
                >
                    Speed
                </Typography>
                    {counter}
                {/* <Typography
                    variant="h5"
                    component="div"
                    sx={{ color: 'text.primary', fontSize:'14x', fontWeight:'bold'}}
                >
                    {`${parseFloat(speed)}`}
                </Typography> */}
                <Typography
                    variant="body1"
                    sx={{fontSize:'10px', fontWeight:'400', mb:0}}
                >
                    kmph
                </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export const VehicleStatus = (props) => {
    const { status } = props;
    const Label = ({color}) => {
        return (
            <Box sx={{
                background:color, 
                fontSize:'10.5px', 
                padding:'4px 4px', 
                borderRadius:'5px', 
                color:'#fff'}}>
                
            </Box>
        )
    }
    return(
        <Box sx={{pb:.5, px:1}}>
            <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant="caption">Vehicle Status</Typography>
                <Box 
                sx={{px:.5, textAlign:'center', mt:1, borderRadius:'4px', background:status === '0' ? "#e74c3c" : status === '1' ? '#2ecc71' : status === '2' ? '#3498db' : '#7f8c8d', color:'#fff'}}
                >
                <Typography variant="body2" sx={{fontSize:'12px'}}>{status === '0' ? "Stop" : status === '1' ? 'Moving' : status === '2' ? 'Idle' : 'Offline'}</Typography>
            </Box>
            </Stack>
        </Box>
    )
}

export const Odometer = (props) => {
    const { odometer, startOdo, distanceT } = props;
    return(
        <Stack direction={'row'} spacing={3}>
            <Box>
                <Typography variant="caption" sx={{lineHeight:'2px !important'}}>Odometer ({parseFloat(startOdo).toFixed(1)} km)</Typography>
                <Typography variant="h6" color={'primary'}>{parseFloat(odometer).toFixed(1)}<Typography component={'span'} variant="body2" >km</Typography></Typography>
            </Box>
            <Box>
                <Typography variant="caption">Distance Traveled</Typography>
                <Typography variant="h6" color={'secondary'}>{parseFloat(distanceT).toFixed(1)}<Typography component={'span'} variant="caption" >km</Typography></Typography>
            </Box>
            {/* <Box>
                <Typography variant="caption">Alerts</Typography>
                <Typography variant="h6">0<Typography component={'span'} variant="caption" >km</Typography></Typography>
            </Box> */}
        </Stack>
    )
}

export const NoTripFound = (props) => {
    const { data, payload } = props;
    return (
        // <Snackbar
        //     anchorOrigin={{
        //         vertical:'bottom',
        //         horizontal:'center'
        //     }}
        //     open={data}

        // >
        //     <Alert severity="warning">
        //         <Typography variant="body1"> No trip found at {moment(payload?.fromDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')} - {moment(payload?.toDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</Typography>
        //     </Alert>
        // </Snackbar>
        <Box>
            <Empty
            image={noTripData}
            description={`No trip found at ${moment(payload?.fromDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')} - ${moment(payload?.toDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}`}/>
        </Box>
    )
}


export const TripDate = (props) => {
    const { startDate, endDate, tripData, getDate, date} = props;
    return(
        <Paper
            sx={{boxShadow:'0px 0px 8px rgba(0,0,0,0) !important', backdropFilter:'none !important', mb:1, px:1, border:'0px solid #ddd', background:'transparent !important'}}
        >
            <Stack direction={'row'} alignItems={'center'}>
                <Typography>Dates: </Typography>
                <Box sx={{ml:1}}>
                    <Dates getDate={getDate} tripData={tripData} date={date}/>
                </Box>
            </Stack>
        </Paper>
    )
}

export const ViewSummary = (props) => {
    const { tripData } = props;
    const theme = useTheme();
    const [ summary, setSummary] = useState(false);
    let userData = JSON.parse(localStorage.getItem("userData"));
    let speedUnit = userData.speedDisplayUnit ;
    let distanceUnit = userData.distanceDisplayUnit ;
    let distanceValue = speedUnit === "m"?2:1;
    const closeSummary = () => {
        setSummary(false);
    }
    const Item = ({label, value}) => {
        return(
            <Box>
                <Typography variant="caption">{label}</Typography>
                <Typography>{value}</Typography>
            </Box>
        )
    }
    return(
       <>
            {/* <Stack>
                <Button size="small" sx={{py:.2, fontSize:'12px'}} variant="contained" color='secondary' onClick={() => setSummary(true)}>View Summary</Button>
            </Stack> */}
            <Paper elevation={0} sx={{p:0}}>
                    {/* <Box sx={{p:2}}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography>Trip Summary</Typography>
                            <IconButton onClick={closeSummary} size="small"><MdClose /></IconButton>
                        </Stack>
                    </Box> 
                    <Divider />*/}
                    <Box sx={{p:0}}>
                        {/* <Typography variant="h6">{moment(tripData?.tripStartDate, 'DD-MM-YYYY HH:mm:00').format('dddd, MMM Do YYYY')}</Typography> */}
                        <Box sx={{border:'.5px solid #f1f1f1', background:'#f8f8f8', my:1, borderRadius:'10px'}}>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{px:2, py:1}}>
                                <Box>
                                    <Typography variant="caption">Trip Start</Typography>
                                    <Typography variant="h6" sx={{fontSize:'16px'}}>{moment(tripData?.tripStartDate, 'DD-MM-YYYY HH:mm:00').format('DD-MM-YYYY HH:mm:ss')}</Typography>
                                    <Typography variant="caption" component={'a'} target='_Blank' href={`https://www.google.com/maps/search/?api=1&query=${tripData?.startLocation}`}>{tripData?.startLocation}</Typography>
                                </Box>
                                {/* <Box sx={{borderWidth:'.5px', borderStyle:'dashed', borderColor:'#c1c1c1', width:'calc(100% - 30%) !important', height:'auto', position:'relative'}}>
                                    <Icon sx={{fontSize:'32px', display:'flex', margin:'auto', transform:'rotate(90deg) translateX(-50%)', position:'absolute', left:'50%', zIndex:9}}>
                                        <MdAssistantNavigation />
                                    </Icon>
                                </Box> */}
                                <Box>
                                    <Typography variant="caption">Trip End</Typography>
                                    <Typography variant="h6" sx={{fontSize:'16px'}}>{moment(tripData?.tripEndDate, 'DD-MM-YYYY HH:mm:00').format('DD-MM-YYYY HH:mm:ss')}</Typography>
                                    <Typography variant="caption" component={'a'} target='_Blank' href={`https://www.google.com/maps/search/?api=1&query=${tripData?.endLocation}`} >{tripData?.endLocation}</Typography>
                                </Box>
                            </Stack>
                            <Divider />
                            <Box sx={{px:2, py:1, background:alpha(theme.palette.info.main, .2)}}>
                                <Typography color={'info'} variant="body1" sx={{color:theme.palette.info.main}}>{tripData?.tripDuration}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{mt:1}}>
                            <Grid container spacing={2}>
                                <Grid item lg={4}>
                                    <Item label={`Start Odometer (${distanceUnit})`} value={tripData?.summaryData[0]?.startCanOdometer} />
                                </Grid>
                                <Grid item lg={4}>
                                    <Item label={`End Odometer (${distanceUnit})`} value={tripData?.summaryData[0]?.endCanOdometer} />
                                </Grid>
                                <Grid item lg={4}>
                                    <Item label={`Distance Traveled (${distanceUnit})`} value={tripData?.distTraveled} />
                                </Grid>
                                <Grid item lg={4}>
                                    <Item label={`Average Speed ${(+speedUnit+"ph")}`} value={tripData?.avgSpeed} />
                                </Grid>
                                <Grid item lg={4}>
                                    <Item label={`Max Speed ${(+speedUnit+"ph")}`} value={tripData?.summaryData[0]?.maxSpeed} />
                                </Grid>
                                <Grid item lg={4}>
                                    <Item label={'Moving (hh:mm)'} value={tripData?.summaryData[0]?.moveMinutes} />
                                </Grid>
                                <Grid item lg={4}>
                                    <Item label={'Idle (hh:mm)'} value={tripData?.summaryData[0]?.idleMinutes} />
                                </Grid>
                                <Grid item lg={4}>
                                    <Item label={'Offline (hh:mm)'} value={tripData?.summaryData[0]?.offlineMinutes} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Paper>
            {/* <Dialog open={true} size={'xs'} fullWidth>
               
            </Dialog> */}
       </>
    )
}


export const Dates = (props) => {
    const { getDate, tripData, date } = props;
    return(
        <ToggleButtonGroup value={date} onChange={(event) => getDate(event.target.value, tripData, tripData)} size="small" exclusive>
            {
                tripData !== undefined && tripData !== null && tripData?.playBackList?.map((item, index) => <Tooltip arrow title={index}><ToggleButton value={index}>{index + 1}</ToggleButton></Tooltip>)
            }
        </ToggleButtonGroup>
    )
}