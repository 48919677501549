import { dashboardApi } from "../ApiSlice";

const TrackingSlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
    //mapView/getGeoFenceForVehicle
    showGeoZone: builder.mutation({
        query: (body) => ({
          url: "mapView/getGeoFenceForVehicle",
          method: "POST",
          body: body,
        }),
      }),
      getVehicleByResellerNew: builder.mutation({
        query: (body) => ({
          url: "mapView/getVehiclesByResellerTest",
          method: "POST",
          body: body,
        }),
      }),
      //vehicle share mobileService/getShareLocationLink
      vehicleShare: builder.mutation({
        query: (body) => ({
          url: "mobileService/getShareLocationLink",
          method: "POST",
          body: body,
        }),
      }),
    
    showAlert: builder.mutation({
      query: (body) => ({
        url: "mapView/getVehicleAlertDetails",
        method: "POST",
        body: body,
      }),
    }),
    //ALerts List
    alertListOnMap: builder.mutation({
      query: (body) => ({
        url: "/getAlertCount",
        method: "POST",
        body: body,
      }),
    }),
  }),
})

export const { useShowGeoZoneMutation, useGetVehicleByResellerNewMutation, useVehicleShareMutation,useShowAlertMutation, useAlertListOnMapMutation } = TrackingSlice;