import { Box, Chip, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'

function MovingCard(props) {
    const theme = useTheme();
    const { trailsData } = props;
    let userData = JSON.parse(localStorage.getItem("userData"));
    let distanceUnit = userData.distanceDisplayUnit ;
  return (
    trailsData.length > 0 && trailsData !== null && trailsData.map((item, index) => {
        return (
        <Box elevation={0} sx={{mt:.5, border:`.5px solid ${item?.vehicleStatus === '0' ? theme.palette.error.main : item?.vehicleStatus === '1' ? theme.palette.success.main : item?.vehicleStatus === '2' ? theme.palette.info.main : 'default'}`,my:1, px:.8, borderRadius:'10px', '&:first-child':{background:'#f7f7f7 !important', borderStyle:'dashed', borderWidth:'2px', backgroundColor:'#f2fdf1'}}} key={index}>
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant='body2' sx={{fontSize:'13px',}}>{item?.startDate}</Typography>
            <Chip size='small'  color={item?.vehicleStatus === '0' ? 'error' : item?.vehicleStatus === '1' ? 'success' : item?.vehicleStatus === '2' ? 'info' : 'default' } label={item?.vehicleStatus === '0' ? 'Stop' : item?.vehicleStatus === '1' ? 'Moving' : item?.vehicleStatus === '2' ? 'Idle' : 'default'} sx={{borderRadius:'0px 0px 10px 10px', fontSize:'12px'}}/>
        </Stack>
        <Grid container sx={{mt:.8, pb:.8}}>
            <Grid item lg={4}>
                <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px', mb:.2}}>Odometer ({distanceUnit})</Typography>
                <Typography variant='body2' sx={{fontSize:'13px',fontWeight:'500'}}>{item?.odometer}</Typography>
            </Grid>
            {/* <Grid item lg={4}>
                <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px', mb:.2}}>SOC (%)</Typography>
                <Typography variant='body2' sx={{fontSize:'13px',fontWeight:'500'}}>{item?.soc}</Typography>
            </Grid> */}
            <Grid item lg={4}>
                <Typography variant='body2' color={'text.secondary'} sx={{fontSize:'12px', mb:.2}}>Speed ({distanceUnit}/h)</Typography>
                <Typography variant='body2' sx={{fontSize:'13px',fontWeight:'500'}}>{item?.speed}</Typography>
            </Grid>
        </Grid>
        </Box>
        )
    })
    
  )
}

export default MovingCard