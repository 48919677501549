import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { GOOGLE_MAP_KEY } from '../../constants/appConstant';
import { GoogleMap, InfoWindow, Marker, Polygon, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { Box, CardMedia } from '@mui/material';
import startMarker from '../../assets/images/svgicons/start_Flag.svg'
import endMarker from '../../assets/images/svgicons/end_Flag.svg'
import { bikePath, car, truck, auto, wheel } from './resource'
import dot from '../../assets/images/svgicons/dot5.svg'
function Map(props) {
    const { playPath, animPath, movingMarkerRef, mapRef, rotation, date } = props;
    const [map, setMap] = useState(null);
    const [mapType, setMapType] = useState("roadmap");
    const [libraries] = useState(["drawing", "places", "geometry"]);
    const [defaultCenter, setDefaultCenter] = useState({ lat: 52.92214338014607, lng: -1.4703258589163344 });
    const [zoom, setZoom] = useState(5);
    const {isLoaded} = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries,
    nonce:"map"
    });
    //map ref
    const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(defaultCenter);
    // map.fitBounds(bounds);
    setMap(map);
    mapRef.current = map;
    //setMapzoom(5);
    },[])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    {/* onLoad useEffect */}
    

    useEffect(async () => {
        if(playPath !== null && playPath !== undefined && playPath.length > 0){
            var bounds = new window.google.maps.LatLngBounds();
            map.setCenter(playPath[0]);
            for(let i = 0; i  < playPath.length; i++){
                bounds.extend(playPath[i]);
            }
            map.fitBounds(bounds);
        }else{

        }
    },[playPath])
    const options = {
        zoom:zoom,
        minZoom:2,
        maxZoom:22,
        center:defaultCenter,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
        featureType: "poi",
        stylers: [{ visibility: "off" }],
        tilt:47.5,
        heading: 320,
    }
    const mapContainerStyle = {
        height:'inherit',
        position:'relative',
        pointerEvents:'inherit',
        height:'100%'
        }

    const changeCenter = () => {
        if(!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        setDefaultCenter(newPos);
    }

    const changedZoom = () => {
        if(!mapRef.current) return;
        const newPos = mapRef.current.getZoom();
        setZoom(newPos);
        //mapRef.current.setZoom(newPos);
    }

    const playicon = {
            path: wheel, 
            fillColor:'green',
            strokeWeight: .2,
            strokeColor:'#fff',
            rotation:rotation,
            scale: 1.3,
        }

  const mapPane = useMemo(() => {

    console.log(playPath !== null && playPath !== undefined && playPath?.length > 0 && animPath?.length === 0);

        return (
            isLoaded ? 
            <GoogleMap
            options={{
                mapTypeId: mapType,
                ...options,
            }}
            mapContainerStyle={mapContainerStyle}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={zoom}
            onCenterChanged={changeCenter}
            onZoomChanged={changedZoom}
            >
                {
                    animPath?.length === 0 ?
                    <Polyline 
                    path={playPath}
                    options={{
                        strokeColor:'#d35400',
                        geodesic: true,
                        icons: [{
                        icon: {path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW},
                        offset: '50%',
                        repeat: '100px',
                        fillColor:'#f00'
                        }]
                    }}
                    /> : 
                    <Polyline 
                    path={animPath}
                    options={{
                        strokeColor:'#C21D6D'
                    }}
                    />
                    
                }
                {/* {
                    animPath !== null && animPath !== undefined && animPath.length > 0 &&
                    <Polyline 
                    path={animPath}
                    options={{
                        strokeColor:'#C21D6D'
                    }}
                    />
                } */}
                {
                    /* Start Marker */
                    playPath !== null && playPath !== undefined && playPath.length > 0 && 
                    <Marker
                    position={playPath[0]}
                    options={{
                        icon: {
                            url: startMarker,
                            scale: 10.5
                        },
                    }}
                        zIndex={9999}
                    />
                }
               
                {
                    /* End Marker */
                    playPath !== null && playPath !== undefined && playPath.length > 0 && 
                    <Marker
                        position={playPath.slice(-1)[0]}
                        options={{
                            icon: {
                                url: endMarker,
                                scale: 1.5
                            },
                        }}
                        zIndex={9999}
                    />
                }
                {
                    playPath !== null && playPath !== undefined && playPath.length > 0 && 
                    <Marker
                    position={playPath[0]}
                    zIndex={9999}
                    ref={movingMarkerRef}
                    title='animated-marker'
                    options={{
                        //visible:true,
                        icon: {
                            url: dot,
                            scale: 1.5,
                            anchor: new window.google.maps.Point(20, 16)
                        },
                        
                    }}
                    />
                }
                
            </GoogleMap> : 'Loading...'
        )
  },[isLoaded, playPath, animPath, date])
    let img = `https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyBx1k2yHWgbv2RS0NUVcYrqhcHD8sWr9eA`;
  return (
    <Box sx={{
        height:'100%', 
    background:'#f8f8f8', 
    // '& div[title="animated-marker"]':{
    //        //position:'relative',
    //         '&::before':{
    //             position:'absolute',
    //             content:"''",
    //             transform:`translate(-50%, -50%) rotate(-${rotation}deg) !important`,
    //             width:'20px',
    //             height:'20px',
    //             background:'red',
    //             borderRadius:'50%',
    //             top:'50%',
    //             left:'50%',
                
    //         }
            
    //     //transform:`rotate(${rotation}deg)`
    // }
    
    }}>
        {mapPane}

    </Box>
  )
}

export default Map