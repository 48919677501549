import { Avatar, Box, IconButton, ListItem, ListItemAvatar, ListItemText, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormDialog from "../../Utils/FormDialog";
import { useLocation, useNavigate } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ChatBotHeader({close}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

  const handleClickOpen = () => {
    //navigate('#popup')
    setOpen(true);    
  };
  const confirmClose=()=>{    
    //navigate(location.pathname);
    close();
  }

  const handleClose = () => {
    //navigate(location.pathname)
    setOpen(false);    
  };
  const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
  return (
    <Box className='chatbot_header'>
        <Stack>
            <Box>
                <ListItem dense
                    secondaryAction={
                        <IconButton size='small' sx={{color:'#fff'}} onClick={handleClickOpen}>
                            <IoClose />
                        </IconButton>
                    }
                >
                    <ListItemAvatar>
                            <Avatar src='https://media.istockphoto.com/id/1957053641/vector/cute-kawaii-robot-character-friendly-chat-bot-assistant-for-online-applications-cartoon.jpg?s=612x612&w=0&k=20&c=Uf7lcu3I_ZNQvjBWxlFenRX7FuG_PKVJ4y1Y11aTZUc='/>
                    </ListItemAvatar>
                    <ListItemText 
                        primary={<Typography variant='body2' sx={{color:'#fff'}}>Chat Bot (beta)</Typography>}
                        secondary={<Typography variant='caption' sx={{color:'#fff'}}>{selectedVehicle?.vehicleNo}</Typography>}
                        sx={{color:'#fff'}}
                    />
                </ListItem>
            </Box>
          <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                  
                >
                  <DialogContent sx={{background: theme.palette.background.default, position: 'relative' }}>
                  <IconButton 
                      onClick={handleClose} // Replace with your close handler
                      sx={{ position: 'absolute', right: 3, top: 2,backgroundColor: 'rgba(255, 99, 71)', // Initial background color
                        borderRadius: '50%', 
                        width: 23,
                        height: 23,
                        color:'#ffff',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0)', // Background color on hover
                        } }} // Positioning the button
                  >
                      <CloseIcon />
                  </IconButton>
                    <DialogContentText id="alert-dialog-slide-description">
                    <Box sx={{marginTop:"10px",marginBottom:"-6px"}}>
                    <Typography>Your chat data will be cleared. Please confirm</Typography>
                  </Box>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{background: theme.palette.background.default }}>
                    <Button style={{padding: "5px 5px",border: "none", borderRadius: "5px",color: "white",cursor: "pointer",transition: "background-color 0.3s",backgroundColor: "#f44336"}} onClick={handleClose}>Cancel</Button>
                    <Button style={{padding: "5px 5px",border: "none", borderRadius: "5px",color: "white",cursor: "pointer",transition: "background-color 0.3s",backgroundColor: "#4CAF50"}} onClick={confirmClose}>Confirm</Button>
                  </DialogActions>
                </Dialog>

        </Stack>
    </Box>
  )
}

export default ChatBotHeader    