import React from 'react'
import { StyledInfoWindow } from '../NewTracking/Treeview/TreeviewStyledEle';
import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { InfoWindow } from '@react-google-maps/api';

function ZoneInfo({data, markerClose}) {
    const theme = useTheme();
    var bounds = new window.google.maps.LatLngBounds();
    //let { address, latitude, longitude, zoneType, radius, name, cordinates  } = data;
    if(data?.zoneType === 'Polygons'){
      let coords = JSON.parse(data.cordinates);
      for (let i = 0; i < coords.length; i++) {
        bounds.extend(coords[i]);
      }
    }

    let lati = data?.zoneType === 'Polygons' ? parseFloat(bounds.getCenter().lat()) : parseFloat(data.latitude);
    let lngi = data?.zoneType === 'Polygons' ? parseFloat(bounds.getCenter().lng()) : parseFloat(data.longitude);
    
  return (
    data !== null && data !== undefined &&
    <InfoWindow
        position={{lat:lati, lng:lngi}}
        options={{pixelOffset: new window.google.maps.Size(0, -70)}}
        onCloseClick={markerClose}
    >
         <StyledInfoWindow>
                  <Box
                  className='infowindowHHeader'
                  sx={{background:theme.palette.warning.main, width:'100%'}}
                  > 
                      <Stack spacing={3} direction={'row'} alignItems={'baseline'}>
                          <Box>
                            <Typography variant='caption'>Zone Name</Typography>
                            <Typography variant='subtitle2'>{data?.name}</Typography>
                          </Box>
                          {
                            data.zoneType === 'Circles' &&
                            <Box>
                            <Typography variant='caption'>Radius</Typography>
                            <Typography variant='subtitle2'>{data?.radius}</Typography>
                          </Box>
                          }
                          
                          {/* <Fab disableTouchRipple size='small' 
                          sx={BackgroundStyle} 
                          >
                             <Icon>
                             {
                              setlectMarker?.props.markerData.vehicleStatus === 'Stop' || setlectMarker?.props.markerData.vehicleStatus === '0' ? <FaParking />: 
                              setlectMarker?.props.markerData.vehicleStatus === 'Charging' || setlectMarker?.props.markerData.vehicleStatus === '6'? <FaPlugCircleBolt /> : 
                              setlectMarker?.props.markerData.vehicleStatus === 'Offline' || setlectMarker?.props.markerData.vehicleStatus === '4'? <MdSignalWifiBad /> : 
                              setlectMarker?.props.markerData.vehicleStatus === 'Moving' || setlectMarker?.props.markerData.vehicleStatus === '1'? <FaRoad /> : <FaParking />
                            }
                             </Icon>
                          </Fab> */}
                      </Stack>
                  </Box>
                  <Box sx={{padding:'5px 15px', width:'100%'}}>
                      <Stack direction={'row'} spacing={2} justifyContent={'space-between'} sx={{width:'100%'}}>
                          <Box sx={{width:'100%'}}> 
                              <Stack direction={'column'} spacing={.5}>
                                  <Box>
                                    <Typography variant='caption'>Address</Typography>
                                    {
                                      <Typography variant='subtitle2'>{data?.address === null ? '-' : data?.address}</Typography>
                                    }
                                  </Box>
                              </Stack>
                          </Box>
                      </Stack>
                  </Box>
              </StyledInfoWindow>
    </InfoWindow>
  )
}

export default ZoneInfo