import { Box, Grid, List, ListItemButton, ListItemText, Typography } from "@mui/material"
//import { useBotUserMessageMutation } from "../../../store/slice/botSlice/BotSlice"
import {  useState,useMemo } from "react"
import { useSelector,useDispatch } from "react-redux";
import { chatBotSingleVehicle,chatBotSingleVehicleData } from "../../../../store/slice/ControlsSlice";
import moment from "moment";
import { fontSize } from "@mui/system";

export const IntroOptions = (props) => {
    return(
        <Box sx={{display:'flex'}}>
            <List dense className="list_options">
                <ListItemButton dense className="list_btns" onClick={() => props.actionProvider.customMes('Vehicle Summary')}>
                    <ListItemText 
                        primary='Vehicle Summary'
                    />
                </ListItemButton>
                <ListItemButton dense className="list_btns">
                    <ListItemText 
                        primary='Vehicle Data'
                    />
                </ListItemButton>
            </List>
        </Box>
    )
}

export const GetNumber = (props) => {
   const { gridData } = props.state;
   const [data, setdata] = useState(gridData!=null?JSON.parse(gridData):gridData);
     return(
        <div style={{marginTop: "-5px",marginBottom: "7px"}}>
        <Box className='widget_wrapper' sx={{marginBottom:"2px"}}>
            {
                gridData !== undefined && 
                <Grid container>
                <Grid item lg={6}><Typography variant="body2">Start Odometer (km)</Typography></Grid>
                <Grid item lg={6}><Typography variant="body1"><b>{data.canStartOdometer}</b></Typography></Grid>
                <Grid item lg={6}><Typography variant="body2">End Odometer (km)</Typography></Grid>
                <Grid item lg={6}><Typography variant="body1"><b>{data.canEndOdometer}</b></Typography></Grid>
                <Grid item lg={6}><Typography variant="body2">Distance Travelled (km)</Typography>  </Grid>
                <Grid item lg={6}><Typography variant="body1"><b>{data.distanceTraveled}</b></Typography></Grid>
                <Grid item lg={6}> <Typography variant="body2">Energy Efficiency (%)</Typography>  </Grid>
                <Grid item lg={6}><Typography variant="body1"><b>{data?.efficiencyPerKM}</b></Typography></Grid>
                <Grid item lg={6}><Typography variant="body2">Energy Consumed (Wh)</Typography> </Grid>
                <Grid item lg={6}><Typography variant="body1"><b>{data.energyConsumed}</b></Typography></Grid>
                <Grid item lg={6}><Typography variant="body2">Start Date</Typography> </Grid>
                <Grid item lg={6}><Typography variant="body1"><b>{data?.summaryStartDate!==null?moment(data?.summaryStartDate).format('DD-MM-YYYY'):"-"}</b></Typography></Grid>
                </Grid>
            }
        </Box>
        </div>
    )
}

export const MotorHealthList = (props) => {
    const { gridData } = props.state;
    const [data, setdata] = useState(gridData!=null?JSON.parse(gridData):gridData);
     return(
        <div style={{marginTop: "-5px",marginBottom: "7px"}}>
         <Box className='widget_wrapper' sx={{marginBottom:"2px"}}>
             {
                 gridData !== undefined && 
                 <Grid container>
                 <Grid item lg={5}><Typography variant="body2">Motor Temparature (°C)</Typography></Grid>
                 <Grid item lg={7}><Typography variant="body1"><b>{data?.motorTemparature}</b></Typography></Grid>
                 <Grid item lg={5}><Typography variant="body2">Motor RPM (A)</Typography></Grid>
                 <Grid item lg={7}><Typography variant="body1"><b>{data?.motorRpm}</b></Typography></Grid>
              </Grid>
             }
         </Box>
         </div>
     )
   }

   
export const Chatgpt = (props) => {
    const { gridData } = props.state;
    const [data, setdata] = useState(gridData!=null?gridData?.content:gridData?.content);
    
   return(
    <div style={{marginTop: "2px",marginBottom: "1px"}}>
      <Box className='widget_wrapper' sx={{marginBottom:"0px"}}>
          {
              gridData !== undefined && 
              <Grid container>
              <Grid item lg={12}><Typography variant="body1">  <b dangerouslySetInnerHTML={{ __html: data.replace(/\n/g, '<p />') }} /></Typography></Grid>
            </Grid> 
          }
      </Box>
      </div>
  )
}

export const MotorDetailList = (props) => {
  const { gridData } = props.state;
  const [data, setdata] = useState(gridData!=null?JSON.parse(gridData):gridData);
   return(
    <div style={{marginTop: "-6px",marginBottom: "6px"}}>
       <Box className='widget_wrapper' sx={{marginBottom:"2px"}}>
           {
               gridData !== undefined && 
               <Grid container>
               <Grid item lg={6}><Typography variant="body2">Nomial Motor Voltage (A)</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.nomialMotorVoltage}</b></Typography></Grid>
               </Grid>
           }
       </Box>
       </div>
   )
 }

export const BatteryDetailList = (props) => {
   const { gridData } = props.state;
  const [data, setdata] = useState(gridData!=null?JSON.parse(gridData):gridData);
   return(
    <div style={{marginBottom:"7px",marginTop:"-4px"}}>
       <Box className='widget_wrapper' sx={{marginBottom:"2px"}}>
           {
               gridData !== undefined && 
               <Grid container>
               <Grid item lg={6}><Typography variant="body2">Nominal Battery Capacity (A)</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.nomialBatteryCapacity}</b></Typography></Grid>
               <Grid item lg={6}><Typography variant="body2">Nomial Battery Capacity (A)</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.nomialBatteryCapacity}</b></Typography></Grid>
               <Grid item lg={6}><Typography variant="body2">Battery Voltage (V)</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.batteryVoltage}</b></Typography></Grid>
               <Grid item lg={6}><Typography variant="body2">SOC (%)</Typography>  </Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.soc}</b></Typography></Grid>   
                {/* <Grid item lg={6}><Typography variant="body2">Internal Battery Volt (V)</Typography></Grid>
               <Grid item lg={6}>{data?.internalBatteryVolt}</Grid>
               <Grid item lg={6}><Typography variant="body2">External Battery Volt (V)</Typography></Grid>
               <Grid item lg={6}>{data?.externalBatteryVolt}</Grid> */}    
               <Grid item lg={6}><Typography variant="body2">Battery Current (A)</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.batteryCurrent}</b></Typography></Grid>
               <Grid item lg={6}><Typography variant="body2">Min Battery Temparature (°C)</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.minBatteryTemp}</b></Typography></Grid>
               <Grid item lg={6}><Typography variant="body2">Max Battery Temparature (°C)</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.maxBatteryTemp}</b></Typography></Grid>
            </Grid>
           }
       </Box>
       </div>
   )
 }

export const CurrentFault = (props) => {
    const { gridData } = props.state;
    const [data, setdata] = useState(gridData!=null?JSON.parse(gridData):gridData);
      return(
        <div style={{marginTop: "-5px",marginBottom: "7px"}}>
         <Box className='widget_wrapper' sx={{marginBottom:"2px"}}>
               {  (gridData !== undefined && (data?.faultLevel!==undefined && data?.faultCode!==undefined && data?.startOdometer!==undefined)) && 
                 <>
                 <Grid container>
                 {/* <Grid item lg={6}><Typography variant="body2">Fault Level</Typography></Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.faultLevel}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Fault Code</Typography></Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.faultCode}</b></Typography></Grid>  */}
                 <Grid item lg={6}><Typography variant="body2">occuranceCount</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.occuranceCount}</b></Typography></Grid>  
                 <Grid item lg={6}><Typography variant="body2">Fault Name</Typography>  </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.faultDescription}</b></Typography></Grid>               
                 <Grid item lg={6}> <Typography variant="body2">Severity</Typography>  </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.startOdometer}</b></Typography></Grid>  
                 <Grid item lg={6}><Typography variant="body2">First Occurance</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.firstOccurance}</b></Typography></Grid>           
                 {/* 
                  <Grid item lg={6}><Typography variant="body2">Description</Typography>  </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.faultDescription}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Active/InActive</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.status}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Fault Location</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.faultLocation}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">First Occurance</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.firstOccurance}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Fault Closed Date</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.faultClosedDate}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">occuranceCount</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.occuranceCount}</b></Typography></Grid> */}
                 </Grid>
                </>
                }
               { (data?.faultLevel===undefined && data?.faultCode===undefined && data?.startOdometer===undefined) &&
                <> 
                <Grid container>
                 <Grid item lg={6}><Typography variant="body2">No Faults</Typography></Grid>
                 </Grid>
                 </>
                }
            </Box>
         </div>
     )
 }

 export const CurrentAlert = (props) => {
    const { gridData } = props.state;
    const [data, setdata] = useState(gridData!=null?JSON.parse(gridData):gridData);
      return(
        <div style={{marginTop: "-5px",marginBottom: "7px"}}>
         <Box className='widget_wrapper' sx={{marginBottom:"2px"}}>
             {
                 (gridData !== undefined && (data?.alertType!==undefined && data?.gpsDate!==undefined)) && 
                 <Grid container>
                
                 {/* <Grid item lg={6}><Typography variant="body2">Region</Typography></Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.regionName}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Dealer Name</Typography>  </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.dealerName}</b></Typography></Grid>
                 <Grid item lg={6}> <Typography variant="body2">Customer Name</Typography>  </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.customerName}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Fleet Name</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.fleetName}</b></Typography></Grid> */}                 
                 <Grid item lg={5}><Typography variant="body2">Alert Type</Typography> </Grid>
                 <Grid item lg={7}><Typography variant="body1"><b>{data?.alertType}</b></Typography></Grid>
                 <Grid item lg={5}><Typography variant="body2">Date</Typography></Grid>
                 <Grid item lg={7}><Typography variant="body1"><b>{data?.gpsDate}</b></Typography></Grid>
                 {/* <Grid item lg={6}><Typography variant="body2">Lat Long</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.gpsLocation}</b></Typography></Grid> */}         
                 </Grid>
             }
           {
             (gridData === undefined || (data?.alertType===undefined && data?.gpsDate===undefined)) && 
             <Grid container>
               <Grid item lg={6}><Typography variant="body2">No Alerts</Typography> </Grid>
              </Grid>
           }
         </Box>
         </div>
     )
 }

 export function roundToDecimalPlaces(num, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
}

export const ReportListDetail = (props) => {
  const { gridData } = props.state;
  //const data = useState(gridData!=null?JSON.parse(gridData):gridData);
  const dataN = useMemo(() => {
    return gridData != null ? JSON.parse(gridData) : gridData;
  }, []);
   return(
    <div style={{marginBottom:"7px",marginTop:"-2px"}}>
       <Box className='widget_wrapper' sx={{marginBottom:"2px"}}>
           {
               gridData !== undefined && 
               <Grid container>
               {/* <Grid item lg={6}><Typography variant="body2">Vehicle No</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.vehicleNo}</b></Typography></Grid> */}
               <Grid item lg={5}><Typography variant="body2">VIN</Typography></Grid>
               <Grid item lg={7}><Typography variant="body1"><b>{dataN?.vinNo}</b></Typography></Grid>
               <Grid item lg={5}><Typography variant="body2">Odometer (mi)</Typography> </Grid>
               <Grid item lg={7}><Typography variant="body1"><b>{dataN?.Odometer!==undefined?roundToDecimalPlaces(dataN?.Odometer, 2):"N/A"}</b></Typography></Grid> 
               <Grid item lg={5}> <Typography variant="body2">Speed (mph)</Typography>  </Grid>
               <Grid item lg={7}><Typography variant="body1"><b>{dataN?.gpsSpeed!==undefined?roundToDecimalPlaces(dataN?.gpsSpeed, 2):"N/A"}</b></Typography></Grid>   
               <Grid item lg={5}><Typography variant="body2">Last Connected</Typography></Grid>
               <Grid item lg={7}><Typography variant="body1"><b>{dataN?.lastConnected}</b></Typography></Grid>             
               <Grid item lg={5}><Typography variant="body2">Vehicle Status</Typography></Grid>
               <Grid item lg={7}><Typography variant="body1"><b>{(dataN?.vehicleStatus === 0 || dataN?.vehicleStatus === 2)?"Stop":dataN?.vehicleStatus === 1?"Idle":dataN?.vehicleStatus === 4?"Moving":dataN?.vehicleStatus === 6?"Offline":"Charging"}</b></Typography></Grid>
               <Grid item lg={5}><Typography variant="body2">Fuel Type</Typography></Grid>
               <Grid item lg={7}><Typography variant="body1"><b>{dataN?.fuelType}</b></Typography></Grid>           
               {/* <Grid item lg={6}><Typography variant="body2">Vehicle Model</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.model}</b></Typography></Grid>
               <Grid item lg={6}><Typography variant="body2">Vehicle Efficiency</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.vehicleEfficiency}</b></Typography></Grid>
               <Grid item lg={6}><Typography variant="body2">Remaining Energy (Wh)</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.energyAtTime}</b></Typography></Grid>          
               <Grid item lg={6}><Typography variant="body2">Imei</Typography></Grid>
               <Grid item lg={6}><Typography variant="body1"><b>{data?.imei}</b></Typography></Grid> */}

            </Grid>
           }
       </Box>
       </div>
   )
}

export const VehicleTrip = (props) => {
    //console.log(props);
    const { gridData } = props.state;
    const [data, setdata] = useState(gridData!=null?JSON.parse(gridData):gridData);
     return(
        <div style={{marginTop: "-5px",marginBottom: "6px"}}>
         <Box className='widget_wrapper' sx={{margin:"5px"}}>
             {
                 (gridData !== undefined && (data?.distTravelled!==undefined && data?.energyConsumed!==undefined  && data?.duration!==undefined)) && 
                 <Grid container>
                 <Grid item lg={5}><Typography variant="body2">Start Time</Typography> </Grid>
                 <Grid item lg={7}><Typography variant="body1"><b>{data?.startTime}</b></Typography></Grid>
                 <Grid item lg={5}><Typography variant="body2">End Time</Typography> </Grid>
                 <Grid item lg={7}><Typography variant="body1"><b>{data?.endTime}</b></Typography></Grid>
                 <Grid item lg={5}><Typography variant="body2">Duration (hh:mm)</Typography> </Grid>
                 <Grid item lg={7}><Typography variant="body1"><b>{data?.duration}</b></Typography></Grid>
                 <Grid item lg={5}><Typography variant="body2">Distance Traveled (km)</Typography>  </Grid>
                 <Grid item lg={7}><Typography variant="body1"><b>{data?.distTravelled}</b></Typography></Grid>
                 <Grid item lg={5}><Typography variant="body2">Trip Status</Typography> </Grid>
                 <Grid item lg={7}><Typography variant="body1"><b>{data?.tripStatus}</b></Typography></Grid>              
                {/*
                 <Grid item lg={6}><Typography variant="body2">Max Speed (km/h)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.maxSpeed}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Max Battery Current</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.maxBtCurrent}</b></Typography></Grid>                
                 <Grid item lg={6}><Typography variant="body2">Start Odometer (km)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.startOdometer}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">End Odometer (km)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.endOdometer}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Avg Speed (km)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.avgSpeed}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">End Energy</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.endEnergy}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Energy efficiency (km/Wh)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.energyefficiency}</b></Typography></Grid>

                  <Grid item lg={6}><Typography variant="body2">Start Voltage (km)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.startVoltage}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">End Voltage (km)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.endVoltage}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">End Location</Typography> </Grid>
                 <Grid item lg={6}><span>{data?.endLoc}</span></Grid>                 
                 <Grid item lg={6}><Typography variant="body2">Move Mins (hh:mm)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.movmins}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Start SOC (%)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.startsoc}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">End SOC (%)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.endsoc}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Start Battery Temp (℃)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.startBtTemp}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Max Battery Temperature (℃)</Typography></Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.maxBtTemp}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Avg Battery Temp (℃)</Typography> </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.avgBtTemp}</b></Typography></Grid>                  */}
                 </Grid>
             }
             {
                (gridData === undefined || ((data?.distTravelled===undefined && data?.energyConsumed===undefined) || data?.duration===undefined)) && 
                <Grid container>
                 <Grid item lg={6}><Typography variant="body2">No data found</Typography>  </Grid>
                </Grid>
             }
         </Box>
         </div>
     )
  }

  export const BatteryHealth = (props) => {
    const { gridData } = props.state;
    const [data, setdata] = useState(gridData!=null?JSON.parse(gridData):gridData);
     return(
        <div style={{marginTop: "-5px",marginBottom: "6px"}}>
         <Box className='widget_wrapper' sx={{marginBottom:"2px"}}>
             {
                 gridData !== undefined && 
                 <Grid container>
                 {/* <Grid item lg={6}><Typography variant="body2">SOH (%)</Typography></Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.soh?.toFixed(2)}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">SOC (%)</Typography>  </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.soc}</b></Typography></Grid>   
                 <Grid item lg={6}><Typography variant="body2">Internal Battery Capacity (%)</Typography></Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.totalCapacity?.toFixed(2)}</b></Typography></Grid>
                 <Grid item lg={6}><Typography variant="body2">Current Battery Capacity (%)</Typography>  </Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.currentCapacity}</b></Typography></Grid>*/}
                 <Grid item lg={5.3}><Typography variant="body2">Internal Battery Voltage (V)</Typography></Grid>
                 <Grid item lg={6.7}><Typography variant="body1"  style={{marginTop:"-1px"}}><b>{data?.internalBatteryVoltage?.toFixed(2)}</b></Typography></Grid>
                 <Grid item lg={5.3}><Typography variant="body2" style={{marginTop:"2px"}}>External Battery Voltage (V)</Typography>  </Grid>
                 <Grid item lg={6.7}><Typography variant="body1" ><b>{data?.externalBatteryVoltage?.toFixed(2)}</b></Typography></Grid>
                 
                 {/* <Grid item lg={6}><Typography variant="body2">Remaining Usage Life (%)</Typography></Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.rul?.toFixed(2)}</b></Typography></Grid>    
                 <Grid item lg={6}><Typography variant="body2">Remaining Capacity (A)</Typography></Grid>
                 <Grid item lg={6}><Typography variant="body1"><b>{data?.remainingCapacity?.toFixed(2)}</b></Typography></Grid>                             */}
                 </Grid>
             }
         </Box>
         </div>
     )
  }


  export const MainList = (props) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    const handleClick = (option) => {
       if(selectedVehicle!=null){
        var payload={
            "vehicleId":selectedVehicle?.vehicleId,
            "vehicleNo":selectedVehicle?.vehicleNo,
            "option":option
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };

    const options = [
        { text: "Vehicle", handler: () => {handleClick("Vehicle")}, id: 1 },
        { text: "Battery", handler: () => {handleClick("Battery")}, id: 2 },
        { text: "Motor", handler: () => {handleClick("Motor")}, id: 3 },
    ];
    const buttonsMarkup = options.map((option) => (
      <button key={option.id} onClick={option.handler} style={{ marginRight: "6px",marginLeft: "5px",
        borderRadius: "14px",
        paddingLeft: "6px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "3px",
        border: "1px solid #0288d1",
        //backgroundColor:"#90EE90",
        color:"#fff",
        backgroundColor:"#1976d2",
        //color:"#0288d1",
        //fontWeight: "normal",
        fontSize:"11px",
        fontStyle: "normal",
        fontWeight:"400",
        //background: "transparent",
        textAlign: "center"}}>
        {option.text}
      </button>
    ));    
    return <div style={{ display: "flex",flexWrap: "wrap",marginTop:"1px"}}>{buttonsMarkup}</div>;
  };

export const BatteryList = (props) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    const handleClick = (option) => {
       if(selectedVehicle!=null){
        var payload={
            "vehicleId":selectedVehicle?.vehicleId,
            "vehicleNo":selectedVehicle?.vehicleNo,
            "option":option
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };
    const options = [
        { text: "Battery detail", handler: () => {handleClick("Battery detail")}, id: 1 },   
        { text: "Battery health", handler: () => {handleClick("Battery health")}, id: 2 }        
    ];
    const buttonsMarkup = options.map((option) => (
      <button key={option.id} onClick={option.handler} style={{ marginRight: "6px",marginLeft: "5px",
        borderRadius: "14px",
        paddingLeft: "6px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "3px",
        border: "1px solid #0288d1",
        //backgroundColor:"#90EE90",
        color:"#fff",
        backgroundColor:"#1976d2",
        //color:"#0288d1",
        //fontWeight: "normal",
        fontSize:"11px",
        fontStyle: "normal",
        fontWeight:"400",
        //background: "transparent",
        textAlign: "center"}}>
        {option.text}
      </button>
    ));
    return <div style={{ display: "flex",flexWrap: "wrap",marginTop:"-4px"}}>{buttonsMarkup}</div>;
};

export const MotorList= (props) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    const handleClick = (option) => {
       if(selectedVehicle!=null){
        var payload={
            "vehicleId":selectedVehicle?.vehicleId,
            "vehicleNo":selectedVehicle?.vehicleNo,
            "option":option
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };
    const options = [
        { text: "Motor detail", handler: () => {handleClick("Motor detail")}, id: 1 },   
        { text: "Motor health", handler: () => {handleClick("Motor health")}, id: 2 } 
    ];
    const buttonsMarkup = options.map((option) => (
      <button key={option.id} onClick={option.handler} style={{ marginRight: "6px",marginLeft: "5px",
        borderRadius: "14px",
        paddingLeft: "6px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "3px",
        border: "1px solid #0288d1",
        //backgroundColor:"#90EE90",
        color:"#fff",
        backgroundColor:"#1976d2",
        //color:"#0288d1",
        //fontWeight: "normal",
        fontSize:"11px",
        fontStyle: "normal",
        fontWeight:"400",
        //background: "transparent",
        textAlign: "center"}}>
        {option.text}
      </button>
    ));
    return <div style={{ display: "flex",flexWrap: "wrap",marginTop:"-2px"}}>{buttonsMarkup}</div>;
};


export const ReportList = (props) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    const handleClick = (option) => {
       if(selectedVehicle!=null){
        var payload={
            "vehicleId":selectedVehicle?.vehicleId,
            "vehicleNo":selectedVehicle?.vehicleNo,
            "option":option
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };

    const options = [
      { text: "Vehicle detail", handler: () => {handleClick("Vehicle detail")}, id: 1 },     
      { text: "Last trip details", handler: () => {handleClick("Last trip details")}, id: 2 },      
      { text: "Faults (Active)", handler: () => {handleClick("Faults")}, id: 3 },
      { text: "Alerts (High)", handler: () => {handleClick("Alerts")}, id: 4 },
      { text: "Battery status", handler: () => {handleClick("Battery status")}, id: 5 },
    ];
    const buttonsMarkup = options.map((option) => (
      <button key={option.id} onClick={option.handler} style={{ margin: "5px",
        borderRadius: "14px",
        paddingLeft: "6px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "3px",
        border: "1px solid #0288d1",
        //backgroundColor:"#90EE90",
        color:"#fff",
        backgroundColor:"#1976d2",
        //color:"#0288d1",
        //fontWeight: "normal",
        fontSize:"11px",
        fontStyle: "normal",
        fontWeight:"400",
        //background: "transparent",
        textAlign: "center"}}>
        {option.text}
      </button>
    ));
    return <div style={{ display: "flex",flexWrap: "wrap", marginTop: "-4px",marginBottom: "-5px"}}>{buttonsMarkup}</div>;
  };


  export const ClearAirZoneList = (props) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    const handleClick = (option,value) => {
       if(selectedVehicle!=null){
        var payload={
            "mapSignal":selectedVehicle?.mapSignal,
            "option":value,
            "location":selectedVehicle?.location,
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };
    const options = [
      { text: "Classification and CO2 Emission level", handler: () => {handleClick("Provide short description of Classification and CO2 Emission level at Clear Air Zone "+selectedVehicle?.mapSignal+" in UK","Classification and CO2 Emission level")}, id: 1 },     
      { text: "Location and Road type", handler: () => {handleClick("Provide short description of the location , road type and operating hours at Clear Air Zone "+selectedVehicle?.mapSignal+" in UK","Location and Road type")}, id: 2 },      
      { text: "Vehicle type, Charges and Penality", handler: () => {handleClick("Provide short description of vehicle type, Charges and penalities at Clear Air Zone "+selectedVehicle?.mapSignal+" in UK","Vehicle type, Charges and Penality")}, id: 3 },
      { text: "Traffic and Speed limit", handler: () => {handleClick("Provide short description of traffic and speed limit at Clear Air Zone "+selectedVehicle?.mapSignal+" in UK","Traffic and Speed limit")}, id: 4 },
    ];
    const buttonsMarkup = options.map((option) => (
      <button key={option.id} onClick={option.handler} style={{ margin: "5px",
        borderRadius: "14px",
        paddingLeft: "6px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "3px",
        border: "1px solid #0288d1",
        //backgroundColor:"#90EE90",
        color:"#fff",
        backgroundColor:"#1976d2",
        //color:"#0288d1",
        //fontWeight: "normal",
        fontSize:"11px",
        fontStyle: "normal",
        fontWeight:"400",
        //background: "transparent",
        textAlign: "center"}}>
        {option.text}
      </button>
    ));
    return <div style={{ display: "flex",flexWrap: "wrap", marginTop: "-4px",marginBottom: "-5px"}}>{buttonsMarkup}</div>;
  };


  export const TollsList = (props) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    const handleClick = (option,value) => {
       if(selectedVehicle!=null){
        var payload={
            "mapSignal":selectedVehicle?.mapSignal,
            "option":value,
            "location":selectedVehicle?.location,
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };
    const options = [
      { text: "Location and Road type", handler: () => {handleClick("Provide short description of the location , road type and operating hours of toll "+selectedVehicle?.mapSignal+" in UK","Location and Road type")}, id: 2 },      
      { text: "Vehicle type and charges", handler: () => {handleClick("Provide short description of vehicle type and charges of toll "+selectedVehicle?.mapSignal+" in UK","Vehicle type and charges")}, id: 3 },
      { text: "Traffic and Speed limit", handler: () => {handleClick("Provide short description of traffic and speed limit at Clear Air Zone "+selectedVehicle?.mapSignal+" in UK","Traffic and Speed limit")}, id: 4 },
    ];
    const buttonsMarkup = options.map((option) => (
      <button key={option.id} onClick={option.handler} style={{ margin: "5px",
        borderRadius: "14px",
        paddingLeft: "6px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "3px",
        border: "1px solid #0288d1",
        //backgroundColor:"#90EE90",
        color:"#fff",
        backgroundColor:"#1976d2",
        //color:"#0288d1",
        //fontWeight: "normal",
        fontSize:"11px",
        fontStyle: "normal",
        fontWeight:"400",
        //background: "transparent",
        textAlign: "center"}}>
        {option.text}
      </button>
    ));
    return <div style={{ display: "flex",flexWrap: "wrap", marginTop: "-4px",marginBottom: "-5px"}}>{buttonsMarkup}</div>;
  };

  
  export const HighbridgeList = (props) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    const handleClick = (option,value) => {
       if(selectedVehicle!=null){
        var payload={
            "mapSignal":selectedVehicle?.mapSignal,
            "option":value,
            "location":selectedVehicle?.location,
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };
    const options = [
      { text: "Location and Road type", handler: () => {handleClick("Provide short description of the location , road type and operating hours of toll "+selectedVehicle?.mapSignal+" in UK","Location and Road type")}, id: 2 },      
      { text: "Height and Weight", handler: () => {handleClick("Provide short description of vehicle type and charges of toll "+selectedVehicle?.mapSignal+" in UK","Height and Weight")}, id: 3 },
      { text: "Traffic and Speed limit", handler: () => {handleClick("Provide short description of traffic and speed limit at Clear Air Zone "+selectedVehicle?.mapSignal+" in UK","Traffic and Speed limit")}, id: 4 },
    ];
    const buttonsMarkup = options.map((option) => (
      <button key={option.id} onClick={option.handler} style={{ margin: "5px",
        borderRadius: "14px",
        paddingLeft: "6px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "3px",
        border: "1px solid #0288d1",
        //backgroundColor:"#90EE90",
        color:"#fff",
        backgroundColor:"#1976d2",
        //color:"#0288d1",
        //fontWeight: "normal",
        fontSize:"11px",
        fontStyle: "normal",
        fontWeight:"400",
        //background: "transparent",
        textAlign: "center"}}>
        {option.text}
      </button>
    ));
    return <div style={{ display: "flex",flexWrap: "wrap", marginTop: "-4px",marginBottom: "-5px"}}>{buttonsMarkup}</div>;
  };
  export const LowbridgeList = (props) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    const handleClick = (option,value) => {
       if(selectedVehicle!=null){
        var payload={
            "mapSignal":selectedVehicle?.mapSignal,
            "option":value,
            "location":selectedVehicle?.location,
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };
    const options = [
      { text: "Location and Road type", handler: () => {handleClick("Provide short description of the location , road type and operating hours of toll "+selectedVehicle?.mapSignal+" in UK","Location and Road type")}, id: 2 },      
      { text: "Height and Weight", handler: () => {handleClick("Provide short description of vehicle type and charges of toll "+selectedVehicle?.mapSignal+" in UK","Height and Weight")}, id: 3 },
      { text: "Traffic and Speed limit", handler: () => {handleClick("Provide short description of traffic and speed limit at Clear Air Zone "+selectedVehicle?.mapSignal+" in UK","Traffic and Speed limit")}, id: 4 },
    ];
    const buttonsMarkup = options.map((option) => (
      <button key={option.id} onClick={option.handler} style={{ margin: "5px",
        borderRadius: "14px",
        paddingLeft: "6px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "3px",
        border: "1px solid #0288d1",
        //backgroundColor:"#90EE90",
        color:"#fff",
        backgroundColor:"#1976d2",
        //color:"#0288d1",
        //fontWeight: "normal",
        fontSize:"11px",
        fontStyle: "normal",
        fontWeight:"400",
        //background: "transparent",
        textAlign: "center"}}>
        {option.text}
      </button>
    ));
    return <div style={{ display: "flex",flexWrap: "wrap", marginTop: "-4px",marginBottom: "-5px"}}>{buttonsMarkup}</div>;
  };

  //****************Vehicle Summary ***************

  
  export const VehicleSummaryList = (props) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    console.log("selectedVehicle vehicleSummaryList =========>",selectedVehicle);
    const handleClick = (option,value) => {
       if(selectedVehicle!=null){
        var payload={
            "mapSignal":selectedVehicle?.mapSignal,
            "option":value,
            "vehicleNo":selectedVehicle.vehicleNo,
            "distanceTraveled":selectedVehicle.distanceTraveled,
            "fuelConsumed":selectedVehicle.fuelConsumed,
            "avgSpeed":selectedVehicle.avgSpeed,
            "harshAccelerationCount":selectedVehicle.harshAccelerationCount,
            "harshbrakeCount":selectedVehicle.harshbrakeCount,
            "overSpeedCount":selectedVehicle.overSpeedCount,
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };
    const options = [
      { text: "Vehicle Performance", handler: () => {handleClick("Provide very short suggestion about the driving performance of "+selectedVehicle?.vehicleNo+ " based distance "+selectedVehicle?.distanceTraveled+ " miles and fuel consumption "+selectedVehicle?.fuelConsumed+" litres"
         +" with max speed "+selectedVehicle?.maxSpeed+" and average speed "+ selectedVehicle?.avgSpeed +" with "+ selectedVehicle?.harshAccelerationCount +" harsh braking and "+  selectedVehicle?.overSpeedCount +" overspeed alert","Vehicle Performance")}, id: 2 },      
      { text: "CO2 Emissions", handler: () => {handleClick("Provide suggestion about the CO2 emission for "+selectedVehicle?.vehicleNo+ "  based on distance "+selectedVehicle?.distanceTraveled+ " miles and fuel consumption "+selectedVehicle?.fuelConsumed+" litres in short 2 lines","CO2 Emissions")}, id: 3 },
      { text: "EV Cost Savings", handler: () => {handleClick(" Please provide how much we would save the cost if converted to EV vehicle from "+selectedVehicle?.vehicleNo+ "  based on distance "+selectedVehicle?.distanceTraveled+ " miles and fuel consumption "+selectedVehicle?.fuelConsumed+" litres  same model in short 2 lines","EV Cost Savings")}, id: 4 },
    ];
    const buttonsMarkup = options.map((option) => (
      <button key={option.id} onClick={option.handler} style={{ margin: "5px",
        borderRadius: "14px",
        paddingLeft: "6px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "3px",
        border: "1px solid #0288d1",
        //backgroundColor:"#90EE90",
        color:"#fff",
        backgroundColor:"#1976d2",
        //color:"#0288d1",
        //fontWeight: "normal",
        fontSize:"11px",
        fontStyle: "normal",
        fontWeight:"400",
        //background: "transparent",
        textAlign: "center"}}>
        {option.text}
      </button>
    ));
    return <div style={{ display: "flex",flexWrap: "wrap", marginTop: "-4px",marginBottom: "-5px"}}>{buttonsMarkup}</div>;
  };
  //***************End*****************
  export const DateSelectList = (props) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    const handleClick = (option) => {
       if(selectedVehicle!=null){
        var payload={
            "vehicleId":selectedVehicle?.vehicleId,
            "vehicleNo":selectedVehicle?.vehicleNo,
            "option":option
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };
    const options = [
      { text: "Today", handler: () => {handleClick("Today")}, id: 1 },
      { text: "Yesterday", handler: () => {handleClick("Yesterday")}, id: 2 },
      { text: "Last 7days", handler: () => {handleClick("Last 7days")}, id: 3 },
      { text: "Current month", handler: () => {handleClick("Current month")}, id: 4 },
      { text: "Last month", handler: () => {handleClick("Last month")}, id: 5 }
    ];
    const buttonsMarkup = options.map((option) => (
      <button key={option.id} onClick={option.handler} style={{ margin: "5px",
        borderRadius: "22px",
        paddingLeft: "6px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "3px",
        fontSize:"11px",
        border: "1px solid #0288d1",
        //backgroundColor:"#90EE90",
        color:"#fff",
        backgroundColor:"#1976d2",
        fontWeight:"400",
        textAlign: "center"}}>
        {option.text}
      </button>
    ));
    return <div style={{ display: "flex",flexWrap: "wrap",marginTop: "-5px",marginBottom: "-6px"}}>{buttonsMarkup}</div>;
  };
  

