import { Alert, Box, Collapse, Divider, Fab, FormLabel, Grid, Icon, Pagination, Stack, Typography, useTheme ,  TextField, ListItemButton, ListItemText,} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SoftButton, StyledFormControl, StyledTimeline } from '../../theme/StyledEle';
import { useAssignedDriverMutation, useDriverListMutation, useDriverVehicleMapMutation, useVehicleListMutation } from '../../store/slice/ApiSlice';
import deviceServices from '../../services/deviceService';
import InnerTab from '../../Utils/InnerTab';
import { Autocomplete } from '@mui/material'; // Added import
import { useGetVehicleActivityMutation } from '../../store/slice/AdminSlice/AdminSlice';
import { MdOutlineCheck, MdAdd, MdClose } from "react-icons/md";
import moment from 'moment';
import { ScaleLoader } from 'react-spinners'; // Removed duplicate ScaleLoder
import {
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
} from "@mui/material";
import {   
    StyledTableTitle,
    StyledFormControlLabel,
    StyledTextField,
} from "../../theme/StyledEle";

function DriverAssignment({driverData}) {

    const theme = useTheme();
    const location = useLocation();
    const Navigate = useNavigate();
    let userData = JSON.parse(localStorage.getItem("userData"));
    let vehicleId = driverData.id;
    let vehicleNo = driverData.number;
    let userId = driverData.userId;
    
    const [getDriverVehicleMap, { data: driverMap, isLoading: driverMapLoading, isSuccess: driverMapSuccess }] = useDriverVehicleMapMutation();
    const [getAssignedDriver, { data: assignedDriver, isLoading: driverLoading, isSuccess: driverSuccess }] = useAssignedDriverMutation();
    const [getDriverList, { data: driverListmut, isLoading: driverListLoading, isSuccess: driverListSuccess }] = useDriverListMutation();

    const [successMessage, setSuccessMessage] = useState("");
    
    
    const [assignUser, setAssignUser] = useState([]);

    const assignOnChange = async (value) => {
        setAssignUser(value); 
    };
    useEffect(() => {
    const getAssignedDriverList = async () => {
    const getAssignedDrivers=  await getAssignedDriver({"id":vehicleId})
    setAssignUser(getAssignedDrivers.data.data[0]);
    }
    getAssignedDriverList();
    },[]);

    const [driverList, setDriverList] = useState(); 
    let payload = {
      "id":vehicleId
    };
    useEffect(() => {
    getDriverList(payload);
    },[]);

    useEffect(() => {


  
   if(driverListSuccess){
   const finalresult = driverListmut.data;

  setDriverList([]);
  finalresult?.length > 0 && finalresult?.forEach((key, index) => {
  let userObj = {
    userName:key.userName,
    userId:key.userId,
  }
 
  setDriverList(oldList => [...oldList, userObj])
  })

  }
  },[driverListmut]);
    

  const handleSubmit = async (assignUser) => {
          if (assignUser) {           
            
            await getDriverVehicleMap({
                "id": driverData.id,
                "userId": assignUser.userId,
                "userName":assignUser.userName
            });
         setSuccessMessage("Driver Assigned successfully!");
         setTimeout(() => {
         setSuccessMessage("");
      }, 1000); 
      const response=  await getAssignedDriver({"id":driverData.id})
      setAssignUser(response.data.data[0]);
        } else {
            
            setAssignUser("");    
            await getDriverVehicleMap({
                "id": driverData.id,
                "userId": "" ,
                "userName":""
            });
         setSuccessMessage("Driver UnAssigned successfully!");
         setTimeout(() => {
          setSuccessMessage("");
       }, 1000); 
        }
        setTimeout(() => {
        Navigate(location.pathname)
      }, 1050); 
  };
    return (
        

        <Box sx={{ height: '100%', maxHeight: '75vh', overflowY: 'auto' }}>
            <CardHeader
                title={
                    <StyledTableTitle sx={{ display: "block" }}>
                        Driver Assignment
                    </StyledTableTitle>
                }
            />

            <CardContent sx={{ py: 0 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StyledFormControl fullWidth>
                        <StyledFormControlLabel required>
                            Select Driver
                        </StyledFormControlLabel>



                        <Autocomplete
                      name="vehicle"
                      size="small"
                    //   multiple
                      disablePortal
                      id="combo-box-demo"
                      options={driverList !== undefined && driverList !== null && driverList}
                      value={assignUser !== undefined && assignUser !== "" ? assignUser : null}
                    //   defaultValue={driverList.find((option)=>option.vid===vehicleId)}
                      getOptionLabel={(option) => option.userName !== null && option.userName}
                      onChange={(event, value) => assignOnChange(value)}
                      defaultValue={assignUser !== undefined && assignUser !== "" ? assignUser : []}
                      renderInput={(params) => <TextField
                        className="MuiInputBase-sizeSmall"
                        placeholder="search vehicle"
                        {...params}
                      />}
                    />
                             
                    </StyledFormControl>
                </Grid>

                {successMessage && (
               <Grid item xs={12}>
                  <Alert severity="success">{successMessage}</Alert>
               </Grid>
               )}

                <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                <SoftButton
                className="success"
                color="success"
                onClick={() => handleSubmit(assignUser)}
                >
                  Assign
                </SoftButton>
                </Box>
                </Grid>
            </Grid>
            </CardContent>
        </Box> 
                            
    );
                            

                           
}

export default DriverAssignment;
