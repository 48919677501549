import React,{useEffect,useState} from 'react'
import { createClientMessage } from 'react-chatbot-kit';
import { useBotUserMessageMutation } from '../../store/slice/botSlice/BotSlice';
import Chip from '@mui/material/Chip';
import { chatBotSingleVehicle,chatBotSingleVehicleData } from "../../store/slice/ControlsSlice";
import { useSelector } from "react-redux";
import moment from "moment";
import { useVehicleChatBotMutation } from '../../store/slice/ApiSlice';

function ActionProvider({ createChatBotMessage, setState, children }) {
//  const [ getMessage, { data:getMessageData, isLoading, isSuccess } ] = useBotUserMessageMutation();
 const [ getMessage, { data:getMessageData, isLoading, isSuccess } ] = useVehicleChatBotMutation();
  const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
  const [tempVehicleNo,setTempVehicleNo]=useState();
    const handleHello = () => {
        const botMessage = createChatBotMessage('Hello. Nice to meet you.');
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      };
      
      const vehicleOptnsShow=(option) =>{
        //const userMessage = createClientMessage("",{widget: "dateSelectList"});
        const userMessage = createChatBotMessage("Choose below vehicle:",{widget: "reportList"});        
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, userMessage],
        }));          
        const removeSpaceFn=()=> {
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
            if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
              window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
            }
          }
        }
        const myTimeout = setTimeout(removeSpaceFn, 50);
    }
    const vehicleSummaryOptnsShow=(option) =>{
        //const userMessage = createClientMessage("",{widget: "dateSelectList"});
        const userMessage = createChatBotMessage("Choose below vehicle Summary :",{widget: "dateSelectList"});        
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, userMessage],
        }));          
        const removeSpaceFn=()=> {
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
            if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
              window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
            }
          }
        }
        const myTimeout = setTimeout(removeSpaceFn, 50);
    }
    const vehicleSummaryOptnsImp=(option) =>{
      const format = "YYYY-MM-DD";
      let startDate=new Date();
      let endDate=new Date();
      let days=1;
      if(option==="Today"){
        startDate = moment(startDate).format(format)+" 00:00:00";        
        endDate = moment(endDate).format(format)+" 23:59:59";
        days=1;
      }
      if(option==="Yesterday"){        
        startDate = moment(startDate).subtract(1, 'days').format(format)+" 00:00:00";        
        endDate = moment(endDate).subtract(1, 'days').format(format)+" 23:59:59";
        days=2;
      }
      if(option==="Last 7days"){
        startDate = moment(startDate).subtract(7, 'days').format(format)+" 00:00:00";        
        endDate = moment(endDate).subtract(1, 'days').format(format)+" 23:59:59";
        days=7;
      }
      if(option==="Current month"){ 
        startDate=moment().startOf('month').format(format)+" 00:00:00";
        endDate = moment(endDate).format(format)+" 23:59:59";
        days=8;
      }
      if(option==="Last month"){
        startDate = moment(startDate).subtract(7, 'days').format(format)+" 00:00:00";        
        endDate = moment(endDate).subtract(1, 'days').format(format)+" 23:59:59";
        days=9;
      }
      var vhNo=selectedVehicle?.vehicleNo.trim(); //HR 55 AR 2129
      const userMessage = createClientMessage("Vehicle summary "+vhNo+ " between "+startDate+" and "+endDate);
      //const userMessage = createClientMessage("vehicle summary "+vhNo+" between 2023-01-01 18:30:00 and 2023-01-01 18:30:00");
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, userMessage],
          }));          
        customMessage("vehicle summary "+vhNo+" for "+days);  
        //customMessage("vehicle summary "+vhNo+" between 2023-01-01 18:30:00 and 2023-01-01 18:30:00");  
      const removeSpaceFn=()=> {
        if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
            window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
          }
        }
      }
      const myTimeout = setTimeout(removeSpaceFn, 5);
    }

    const vehicleMain=(option)=>{
      switch (option) {
        case "Vehicle":
          vehicleOptnsShow(option);      
          break;            
        default:            
      } 
    }

    const batteryHealthImp=(option)=>{
        //const userMessage = createClientMessage("",{widget: "dateSelectList"});
        const userMessage = createChatBotMessage("Choose below Battery details:",{widget: "batteryList"});        
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, userMessage],
        }));          
        const removeSpaceFn=()=> {
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
            if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
              window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
            }
          }
        }
        const myTimeout = setTimeout(removeSpaceFn, 50);
    }


 const motorImp=(option)=>{
   const userMessage = createChatBotMessage("Choose below Motor details:",{widget: "motorList"});        
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, userMessage],
      }));          
      const removeSpaceFn=()=> {
        if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
            window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
          }
        }
      }
      const myTimeout = setTimeout(removeSpaceFn, 50);
    }

    const vehicleSummaryImp=(option)=>{
      switch (option) {
        case "Vehicle summary":
          vehicleSummaryOptnsShow(option);      
          break;
        case "Today":
        case 'Yesterday':
        case 'Last 7days':
        case 'Current month':
        case 'Last month':
          vehicleSummaryOptnsImp(option);      
          break;           
        default:            
      }        
      }

    const vehicleTripImp=(option)=>{
      const userMessage = createClientMessage("Vehicle latest trip of "+selectedVehicle?.vehicleNo);
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, userMessage],
      }));          
    //  customMessage("journey of my "+selectedVehicle?.vehicleNo); 
    customMessage("VehicleTrip"); 
    }

    useEffect(() => {
      var loadData=true;
      if(selectedVehicle!==null){
        if(tempVehicleNo===selectedVehicle?.vehicleNo){
          loadData=false;         
        }else{
          setTempVehicleNo(selectedVehicle?.vehicleNo);
        }
        if(selectedVehicle!==null){
          window.document.getElementsByClassName('react-chatbot-kit-chat-input-form')[0].style.visibility = 'hidden';
        }else{
          window.document.getElementsByClassName('react-chatbot-kit-chat-input-form')[0].style.visibility = 'visiable';
        }
      if(selectedVehicle?.option!==undefined){
        
      switch (selectedVehicle?.option) {
        case "Vehicle":
          vehicleMain(selectedVehicle?.option);  
          break;
          case "Vehicle summary":
            vehicleSummaryOptnsShow(selectedVehicle?.option);      
            break;
          case 'Vehicle detail':
            const userMsg = createClientMessage(selectedVehicle?.option+" "+selectedVehicle?.vehicleNo);
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, userMsg],
            }));          
           // customMessage("details of my vehicle "+(selectedVehicle?.vehicleNo).trim());  
           customMessage("GetVehicleDetail"); 
            break;             
          case 'Last trip details':
            vehicleTripImp(selectedVehicle?.option);      
            break;
          case "Vehicle summary":
            vehicleSummaryOptnsShow(selectedVehicle?.option);      
          break; 
          case "Today":
          case 'Yesterday':
          case 'Last 7days':
          case 'Current month':
          case 'Last month':
            vehicleSummaryOptnsImp(selectedVehicle?.option);      
            break;    
        case "Battery":
            batteryHealthImp(selectedVehicle?.option);
          break;
        case 'Motor':
             motorImp(selectedVehicle?.option);
          break;
          case 'Battery status':             
            const userMsgBtryStatus = createClientMessage(selectedVehicle?.option+" "+selectedVehicle?.vehicleNo);
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, userMsgBtryStatus],
            }));          
            customMessage("BatteryHealth");
          break;  
        case 'Battery health':             
            const userMsgBtry = createClientMessage(selectedVehicle?.option+" "+selectedVehicle?.vehicleNo);
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, userMsgBtry],
            }));          
            customMessage("battery degradation of my "+(selectedVehicle?.vehicleNo).trim());
            break;  
          case 'Battery detail':             
            const userMsgBtrydtl = createClientMessage(selectedVehicle?.option+" "+selectedVehicle?.vehicleNo);
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, userMsgBtrydtl],
            }));          
            customMessage("details of my vehicle "+(selectedVehicle?.vehicleNo).trim());
          break;  
          case 'Motor detail':             
            const userMsgMotordtl = createClientMessage(selectedVehicle?.option+" "+selectedVehicle?.vehicleNo);
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, userMsgMotordtl],
            }));          
            customMessage("details of my vehicle "+(selectedVehicle?.vehicleNo).trim());
          break;
          case 'Motor health':             
            const userMsgMotorHealth = createClientMessage(selectedVehicle?.option+" "+selectedVehicle?.vehicleNo);
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, userMsgMotorHealth],
            }));          
            customMessage("details of my vehicle "+(selectedVehicle?.vehicleNo).trim());
          break;
        case 'Faults':
            const userMsgCurrentFault = createClientMessage(selectedVehicle?.option+" "+selectedVehicle?.vehicleNo);
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, userMsgCurrentFault],
            }));          
            customMessage("faults in "+(selectedVehicle?.vehicleNo).trim());
            break;
       case 'Alerts':
            const userMsgCurrentAlert = createClientMessage(selectedVehicle?.option+" "+selectedVehicle?.vehicleNo);
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, userMsgCurrentAlert],
            }));          
            customMessage("CurrentAlert");
            break;
       default:
            const userMessage = createClientMessage(selectedVehicle?.option+" "+selectedVehicle?.vehicleNo);
          setState((prev) => ({
            ...prev,
            messages: [...prev.messages, userMessage],
          }));          
          customMessage(selectedVehicle?.option+" "+selectedVehicle?.vehicleNo);  
        }  
        }else{
          if(loadData===true){
            setState((prev) => ({
              ...prev,
              messages: [],
              }));
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages,  createChatBotMessage(""+selectedVehicle?.vehicleNo)],
              })); 

            const userMsg = createClientMessage("Vehicle detail"+" "+selectedVehicle?.vehicleNo);
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, userMsg],
            }));          
            customMessage("GetVehicleDetail","Vehicle detail");  
            loadData=false;
          }else{
            setState((prev) => ({
              ...prev,
              messages: [],
              }));
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages,  createChatBotMessage(""+selectedVehicle?.vehicleNo,{widget: "reportList"})],
              })); 
          }          
        
        }
      }
  },[selectedVehicle])

  const handleClick = (option) => {
    if(selectedVehicle!==null){
        const userMessage = createClientMessage(option+" "+selectedVehicle?.vehicleNo);
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, userMessage],
        }));
        customMessage(option+" "+selectedVehicle?.vehicleNo);      
    }  
  };

  const introOpt = () => {
    const botMessage = createChatBotMessage('Options', {
      widget:'IntroOptions',
      loading:true,
    })
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const customMes = (message) => {
    const userMessage = createClientMessage(message);
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, userMessage],
    }));
    customMessage(message)
  }

  //get vehicle number from bot
  const getVehicleNumber = () => {
    const botMessage = createChatBotMessage('Enter the vehicle number', {
      delay:500
    });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  //get getReport
  const getReportData = (number) => {
    const botMessage = createChatBotMessage('Detail here', {
       widget:'reportData',
       delay:1000,
       loading:true,
       payload:{
        "projectId": "chatbotnesh",
        "text": number,
        "sessionId": "22222",
        "languageCode": "en-US",
       }
    });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const botMessage = (message) => {
      const botMessage = createChatBotMessage(message);
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
  }
 
  const displayValFn = (option)=>{
       switch(option){
          case "Vehicle detail":
            return "Vehicle details below";
            break;
          case "Battery status":
            return "Battery status details below";
            break;
          case "Battery health":
            return "Battery health details below";
            break;
          case "Battery detail":
            return "Battery details below";
            break;
          case "Last trip details":
            return "Last trip details below";
            break;
          case "Faults":
            return "Current Fault details below";  
            break;
          case "Alerts":
            return "Current Alert details below";
            break;
          default:
              return "Vehicle details below";
            break;
       }
  }

  const displayRootValFn = (option)=>{
    switch(option){
       case "Vehicle detail":
         return "reportListDetail";
         break;
       case "Battery health":
         return "batteryHealth";
         break;
       case "Battery detail":
          return "batteryDetail";
          break;
       case "Last trip details":
         return "vehicleTrip";
         break;
       case "Faults":
         return "currentFault";  
         break;
       case "Alerts":
         return "currentAlert";
         break; 
      case "Motor detail":
        return "motorDetail";
        break;
      case "Motor health":
        return "motorHealth";
        break;
      case "Battery status":
        return "batteryHealth";
        break;
      default:
        return "reportData";
        break;
    }
}

  
  const customMessage = async (message,secOption) => {
    let payload = {
        // "projectId": "chatbot-437905",
        // "text": message,
        // "sessionId": "22222",
        // "languageCode": "en-US",
        "text": message,
        "vehicleNumber":selectedVehicle.vehicleNo,
    }
    const responceData = await (getMessage(payload));
   
    if(responceData.data !== undefined && responceData.data !== null){
       const botMessage = createChatBotMessage( displayValFn(selectedVehicle?.option!==undefined?selectedVehicle?.option:secOption!==undefined?secOption:selectedVehicle?.option),{
        loading:isLoading,
        widget:displayRootValFn(selectedVehicle?.option!==undefined?selectedVehicle?.option:secOption!==undefined?secOption:selectedVehicle?.option),
      //  data:responceData.data?.result !== null ? responceData.data?.result : undefined,
      data:responceData.data?.data !== null ? responceData.data?.data : undefined,
      
      });
     
      let obj = {
        mesId:botMessage.id,
       // data:responceData.data?.result !== null ? responceData.data?.result : undefined
       data:responceData.data?.data !== null ? responceData.data?.data : undefined,
      }
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
       //gridData:responceData?.data?.result !== null ? responceData?.data?.result : undefined
       //gridData:responceData?.data?.data !== null ? responceData?.data?.data : undefined
       gridData:responceData?.data !== null ? responceData?.data: undefined
      }));    
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages,  selectedVehicle!==null?createChatBotMessage("",{widget: "reportList"}):""],
        // gridData:responceData?.data?.result !== null ? responceData?.data?.result : undefined
        // gridData:responceData?.data?.data !== null ? responceData?.data?.data : undefined
        gridData:responceData?.data !== null ? responceData?.data : undefined
        }));            
       
         const removeDivFn=()=> {
          if(window.document.getElementsByClassName("react-chatbot-kit-chat-bot-message-container").length>0){
            if(window.document.getElementsByClassName("react-chatbot-kit-chat-bot-message-container")[window.document.getElementsByClassName("react-chatbot-kit-chat-bot-message-container").length-1].innerHTML===""){
              window.document.getElementsByClassName("react-chatbot-kit-chat-bot-message-container")[window.document.getElementsByClassName("react-chatbot-kit-chat-bot-message-container").length-1].style.display="none";
            }
          }
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
            if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
              window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
            }
          }
        }
        const myTimeout = setTimeout(removeDivFn, 100);
       
    }else if(responceData.error !== undefined){
      botMessage(responceData.error.data);
    }else{
      botMessage('No action');
    }    
  }
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            customMessage,
          },
        });
      })} 
    </div>
  )
}


export default ActionProvider